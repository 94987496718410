import { APIImpresoraTickets } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";
import { RepeatOneSharp } from "@mui/icons-material";
import { DateTime } from "luxon";

export const imprimirTicket = async (values) => {
  let url = APIImpresoraTickets + "ticket";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reimprimirTicket = async (values) => {
  let url = APIImpresoraTickets + "reimprimirTicket";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
