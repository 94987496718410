import React from "react";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { toast } from "react-toastify";
import { getAllEmpleados } from "../../API/ApiEmpleados";
import {
  agregarQuitarVendedorDeOrdenDeServicio,
  obtenerEmpleadosPorOrdenDeServicio,
  quitarVendedorDeOrdenDeServicio,
} from "../../API/APiComisionOrdenesServicio";

import MenuItem from "@mui/material/MenuItem";

import { getServiciosOrdenesServicioDetalle } from "../../API/ApiServiciosOrdenesServicio";

const validationSchema = yup.object({
  folio: yup.string("Ingresa Folio").required("Folio Necesario"),
  idempleado: yup.string("Ingresa Empleado").required("Empleado Necesario"),
  observaciones: yup
    .string("Ingresa Observaciones")
    .required("Observaciones Necesarias"),
  montoComision: yup
    .string("Ingresa Monto Comisióm")
    .required("Monto Necesario"),
});

export const EditarEmpleadosOrdenDeServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [contador, setContador] = React.useState(0);
  const [empleadosParaSeleccionar, setEmpleadosParaSeleccionar] =
    React.useState([]);

  const [finalData, setFinalData] = React.useState([]);
  const [val, setVal] = React.useState({});

  const [totalComisiones, setTotalComisiones] = React.useState(0.0);

  const [finalDataServiciosContratados, setFinalDataServiciosContratados] =
    React.useState([]);

  React.useEffect(() => {
    getAllEmpleados(user).then((resultado) => {
      if (resultado.status == 200) {
        setEmpleadosParaSeleccionar(resultado.data);
      }
    });

    obtenerEmpleadosPorOrdenDeServicio(user, props.data.finalData.folio).then(
      (resultado) => {
        if (resultado.status == 200) {
          setFinalData(resultado.data);
          let total = 0.0;
          for (const empleado of resultado.data) {
            total = total + parseFloat(empleado.montoComision);
          }

          setTotalComisiones(total);
        }
      }
    );
  }, [props.data.finalData]);

  React.useEffect(() => {
    getAllEmpleados(user).then((resultado) => {
      if (resultado.status == 200) {
        setEmpleadosParaSeleccionar(resultado.data);
      }
    });

    getServiciosOrdenesServicioDetalle(user, props.data.finalData.folio).then(
      (resultado) => {
        if (resultado.status === 200) {
          setFinalDataServiciosContratados(resultado.data);
        } else {
          setFinalDataServiciosContratados([]);
        }
      }
    );

    obtenerEmpleadosPorOrdenDeServicio(user, props.data.finalData.folio).then(
      (resultado) => {
        if (resultado.status == 200) {
          setFinalData(resultado.data);
          let total = 0.0;
          for (const empleado of resultado.data) {
            total = total + parseFloat(empleado.montoComision);
          }

          setTotalComisiones(total);
        }
      }
    );
  }, [contador, props.data.finalData]);

  const formik = useFormik({
    initialValues: {
      folio: props.data.finalData.folio,
      idempleado: "",
      observaciones: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      agregarQuitarVendedorDeOrdenDeServicio(user, values).then((resultado) => {
        if (resultado.status == 200) {
          let contadorponer = contador + 1;

          setContador(contadorponer);

          setIsDisabled(false);
        }
      });
    },
  });

  return (
    <div className="mt-2 container-fluid">
      <div className="row">
        <div className="col-sm-3">
          <form className="card mb-3" onSubmit={formik.handleSubmit}>
            <div className="card-body">
              <Autocomplete
                disablePortal
                val={val}
                id="combo-box-demo"
                className="mb-2"
                onChange={(event, newValue) => {
                  formik.setFieldValue("idempleado", newValue.id);
                  toast.success(
                    "Agregarás a:" +
                      " " +
                      newValue.nombre +
                      " " +
                      newValue.apaterno +
                      " " +
                      newValue.amaterno
                  );
                }}
                options={empleadosParaSeleccionar}
                getOptionLabel={(option) =>
                  option.id +
                  "-" +
                  option.nombre +
                  " " +
                  option.apaterno +
                  " " +
                  option.amaterno
                }
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Buscar empleado" />
                )}
              />

              <TextField
                size="small"
                fullWidth
                id="observaciones"
                name="observaciones"
                label="Observaciones"
                type={"text"}
                multiline
                className="mb-3"
                value={formik.values.observaciones}
                onChange={formik.handleChange}
                variant="standard"
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  native: true,
                }}
                error={
                  formik.touched.observaciones &&
                  Boolean(formik.errors.observaciones)
                }
                helperText={
                  formik.touched.observaciones && formik.errors.observaciones
                }
                disabled={isDisabled}
              >
                <option value={""}>Selecciona Servicio</option>
                {finalDataServiciosContratados.map((servicioContratado) => (
                  <option
                    key={servicioContratado.id}
                    value={servicioContratado.nombreServicio}
                  >
                    {servicioContratado.nombreServicio}
                  </option>
                ))}
              </TextField>
              <TextField
                size="small"
                fullWidth
                id="montoComision"
                name="montoComision"
                label="Monto Comisión"
                type={"number"}
                className="mb-3"
                value={formik.values.montoComision}
                InputProps={{ inputProps: { min: 0, step: "any" } }}
                onChange={formik.handleChange}
                variant="standard"
                error={
                  formik.touched.montoComision &&
                  Boolean(formik.errors.montoComision)
                }
                helperText={
                  formik.touched.montoComision && formik.errors.montoComision
                }
                disabled={isDisabled}
              />

              {props.data.agregarComisionistaAOrdenServicio == true ? (
                <>
                  <button
                    className="btn btn-sm w-100 btn-success"
                    type="submit"
                  >
                    Agregar
                  </button>
                </>
              ) : null}
            </div>
          </form>
        </div>
        <div className="col-sm-9">
          <div className="col">
            <div className="card mb-3">
              <div className="card-body">
                <strong>Total Comisiones: $ </strong>{" "}
                {parseFloat(totalComisiones)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card mb-3">
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Empleado</th>
                      <th scope="col">Servicio</th>
                      <th scope="col">Comisión</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalData.map((servicio, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <th scope="row">{index}</th>
                            <td>
                              {" "}
                              {servicio.idempleado} - {servicio.nombre}{" "}
                              {servicio.apaterno}{" "}
                            </td>
                            <td> {servicio.observaciones}</td>
                            <td>
                              {"$ "}
                              {parseFloat(servicio.montoComision)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-success w-100"
                                onClick={() => {
                                  quitarVendedorDeOrdenDeServicio(user, {
                                    folio: props.data.finalData.folio,
                                    id: servicio.id,
                                    observaciones: "",
                                  }).then((resultado) => {
                                    if (resultado.status == 200) {
                                      let contadorponer = contador + 1;

                                      setContador(contadorponer);
                                      toast.success("Actualizando vendedores");
                                      setIsDisabled(false);
                                    }
                                  });
                                }}
                              >
                                Quitar de lista
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {finalData.map((personal, index) => (
            <div key={index} className="col d-none">
              <div className="card mb-3">
                <div className="card-body">
                  {personal.idempleado} - {personal.nombre} {personal.apaterno}{" "}
                  {personal.amaterno}
                  <br /> $
                  {parseFloat(personal.montoComision)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  <br />
                  {personal.observaciones}
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
