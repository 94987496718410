import React from "react";
import { getPreciosMantenimientoAPI } from "../../API/ApiMantenimientos";
import { AuthContext } from "../../auth/authContext";
import { AddPrecioMantenimientoComponent } from "../AddPrecioMantenimientoComponent/AddPrecioMantenimientoComponent";
import { EditPrecioMantenimientoComponent } from "../EditPrecioMantenimientoComponent/EditPrecioMantenimientoComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "./../../API/ApiPermisos";
let guidVista = "f1424bf4-c8fa-40ec-b833-8e16fc0100e3";
export const PreciosDeMantenimientoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [finalData, setFinalData] = React.useState({});

  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "122efdc1-0203-4ac9-9c77-41b9d041dbb9" &&
              permiso.allowed == 1
            ) {
              setPermisoParaEditar(true);
            }
          }
        }
      }
    );

    getPreciosMantenimientoAPI(user).then((resultado) => {
      if (resultado.status == 200) {
       
        var hola = resultado.data.reduce((acc, datas) => {
          if (!acc[datas.espacio]) {
            acc[datas.espacio] = [];
          }
          acc[datas.espacio].push(datas);
          return acc;
        }, {});

        console.log(hola);

        setFinalData(hola);
        props.setCatalogoPreciosFinalData(resultado.data);
      }
    });
  }, [crudAction]);

  return (
    <>
      <div className="w-100 text-center">
        <strong>Precios de mantenimiento</strong>
      </div>
      <div className="w-100 text-end">
        {permisoParaEditar == true ? (
          <div
            className="btn btn-sm btn-success w-100 mb-3 mt-3"
            onClick={() => {
              setCrudAction({ action: "add", data: null });
            }}
          >
            Agregar Precio
          </div>
        ) : null}
      </div>
      {/* Capillas */}
      <div className="container mb-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header p-3">
                {finalData?.Capilla ? (
                  <>
                    <h5 className="m-0">Capillas</h5>
                  </>
                ) : null}
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between fw-bold">
                    <span>Año</span>
                    <span>Precio</span>
                    <span className="d-print-none">Acciones</span>
                  </li>
                  {finalData?.Capilla?.map((precio, index) => (
                    <React.Fragment key={precio.id}>
                      <li className="list-group-item d-flex justify-content-between">
                        <span> {precio.ano} </span>
                        <span>
                          {" "}
                          {"$"} {precio.precio}
                        </span>

                        {permisoParaEditar == true ? (
                          <span>
                            <div
                              className="btn btn-sm btn-warning d-print-none"
                              onClick={() => {
                                setCrudAction({ action: "edit", data: precio });
                              }}
                            >
                              Editar
                            </div>
                          </span>
                        ) : (
                          <div className="btn btn-sm btn-warning d-print-none">
                            Sin edición
                          </div>
                        )}
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

     {/* Duplex */}
      <div className="container mb-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header p-3">
                {finalData?.Duplex ? (
                  <>
                    <h5 className="m-0">Duplex</h5>
                  </>
                ) : null}
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between fw-bold">
                    <span>Año</span>
                    <span>Precio</span>
                    <span className="d-print-none">Acciones</span>
                  </li>
                  {finalData?.Duplex?.map((precio, index) => (
                    <React.Fragment key={precio.id}>
                      <li className="list-group-item d-flex justify-content-between">
                        <span> {precio.ano} </span>
                        <span>
                          {" "}
                          {"$"} {precio.precio}
                        </span>

                        {permisoParaEditar == true ? (
                          <span>
                            <div
                              className="btn btn-sm btn-warning d-print-none"
                              onClick={() => {
                                setCrudAction({ action: "edit", data: precio });
                              }}
                            >
                              Editar
                            </div>
                          </span>
                        ) : (
                          <div className="btn btn-sm btn-warning d-print-none">
                            Sin edición
                          </div>
                        )}
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

     {/* Maousoleo */}
      <div className="container mb-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header p-3">
                {finalData?.Mausoleo ? (
                  <>
                    <h5 className="m-0">Mausoleo</h5>
                  </>
                ) : null}
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between fw-bold">
                    <span>Año</span>
                    <span>Precio</span>
                    <span className="d-print-none">Acciones</span>
                  </li>
                  {finalData?.Mausoleo?.map((precio, index) => (
                    <React.Fragment key={precio.id}>
                      <li className="list-group-item d-flex justify-content-between">
                        <span> {precio.ano} </span>
                        <span>
                          {" "}
                          {"$"} {precio.precio}
                        </span>

                        {permisoParaEditar == true ? (
                          <span>
                            <div
                              className="btn btn-sm btn-warning d-print-none"
                              onClick={() => {
                                setCrudAction({ action: "edit", data: precio });
                              }}
                            >
                              Editar
                            </div>
                          </span>
                        ) : (
                          <div className="btn btn-sm btn-warning d-print-none">
                            Sin edición
                          </div>
                        )}
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

     {/* Murales */}
      <div className="container mb-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header p-3">
                {finalData?.Murales ? (
                  <>
                    <h5 className="m-0">Murales</h5>
                  </>
                ) : null}
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between fw-bold">
                    <span>Año</span>
                    <span>Precio</span>
                    <span className="d-print-none">Acciones</span>
                  </li>
                  {finalData?.Murales?.map((precio, index) => (
                    <React.Fragment key={precio.id}>
                      <li className="list-group-item d-flex justify-content-between">
                        <span> {precio.ano} </span>
                        <span>
                          {" "}
                          {"$"} {precio.precio}
                        </span>

                        {permisoParaEditar == true ? (
                          <span>
                            <div
                              className="btn btn-sm btn-warning d-print-none"
                              onClick={() => {
                                setCrudAction({ action: "edit", data: precio });
                              }}
                            >
                              Editar
                            </div>
                          </span>
                        ) : (
                          <div className="btn btn-sm btn-warning d-print-none">
                            Sin edición
                          </div>
                        )}
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

     {/* Uniplex */}
      <div className="container mb-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header p-3">
                {finalData?.Uniplex ? (
                  <>
                    <h5 className="m-0">Uniplex</h5>
                  </>
                ) : null}
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between fw-bold">
                    <span>Año</span>
                    <span>Precio</span>
                    <span className="d-print-none">Acciones</span>
                  </li>
                  {finalData?.Uniplex?.map((precio, index) => (
                    <React.Fragment key={precio.id}>
                      <li className="list-group-item d-flex justify-content-between">
                        <span> {precio.ano} </span>
                        <span>
                          {" "}
                          {"$"} {precio.precio}
                        </span>

                        {permisoParaEditar == true ? (
                          <span>
                            <div
                              className="btn btn-sm btn-warning d-print-none"
                              onClick={() => {
                                setCrudAction({ action: "edit", data: precio });
                              }}
                            >
                              Editar
                            </div>
                          </span>
                        ) : (
                          <div className="btn btn-sm btn-warning d-print-none">
                            Sin edición
                          </div>
                        )}
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      

      {crudAction.action == "add" ? (
        <AddPrecioMantenimientoComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
        />
      ) : null}

      {crudAction.action == "edit" ? (
        <EditPrecioMantenimientoComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
        />
      ) : null}
    </>
  );
};
