import { API, APICOBRANZA } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getCatalogosContratos = async (user, params) => {
  let url = APICOBRANZA + "captura/catalogosContratos";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
      params: params,
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const postCreateCatalogosContratos = async (user, values) => {
  let url = APICOBRANZA + "captura/nuevaSerieCobranza";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const putEditCatalogosContratosSerieCobranza = async (
  user,
  id,
  values
) => {
  let url = APICOBRANZA + "captura/editarSerieCobranza/" + id;
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const putDeleteCatalogosContratosSerieCobranza = async (
  user,
  id,
  values
) => {
  let url = APICOBRANZA + "captura/eliminarSerieCobranza/" + id;
  try {
    const response = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
