import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import {
  agregarServicioAOrdenDeServicio,
  getServiciosOrdenesServicio,
} from "../../API/ApiServiciosOrdenesServicio";
import { nuevoPagoOrdenServicio } from "../../API/ApiPagosOrdenes";
import { agregarJardinAPanteon } from "../../API/ApiJardines";
import { AddEspaciosAPanteon, AddPanteon } from "../../API/ApiPanteones";
import { getAllEspacios } from "../../API/ApiEspacios";

const validationSchema = yup.object({
  posicionInicial: yup.number("Ingresa").required("Requerido"),
  posicionFinal: yup
    .number("Ingresa")
    .required("Requerido")
    .moreThan(
      yup.ref("posicionInicial"),
      "La posición final debe ser mayor que la inicial"
    ),
  letra: yup.string("Ingresa").notRequired(),
  idpanteon: yup.string("Ingresa").required("Requerido"),
  idtipoEspacio: yup.string("Ingresa").required("Requerido"),
});

export const AgregarEspaciosAPanteonComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [finalDataServicios, setFinalDataServicios] = React.useState([]);

  const [finalData, setFinalData] = React.useState([]);

  const [municipios, setMunicipios] = React.useState([]);

  React.useEffect(() => {
    let listo = false;

    getAllEspacios(user).then((resultado) => {
      if (resultado.status === 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });

    return () => {
      listo = true;
    };
  }, []);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      posicionInicial: "",
      posicionFinal: "",
      letra: "",
      idpanteon: props.crudAction.data.id
        ? props.crudAction.data.id
        : props.crudAction.data,
      idtipoEspacio: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      
      setIsDisabled(true);
      AddEspaciosAPanteon(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Espacios Agregados correctamente");
          props.setCrudAction({ action: null, data: null });
        }
      });
    },
  });

  return (
    <>
      <div className="crudDivComponent col-md-4 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Agregar Espacios a ubicación</h6>
                  <div className="alert alert-info me-2" role="alert">
                    <small className="text-warning-emphasis">
                      Ingresa los datos para agregar espacios a la ubicación.{" "}
                      <br /> <strong>Esta opción es irreversible</strong>,
                      cualquier cambio será mostrado inmediatamente.
                    </small>
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row row-cols-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="posicionInicial"
                    name="posicionInicial"
                    label="Posición Inicial"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.posicionInicial}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.posicionInicial &&
                      Boolean(formik.errors.posicionInicial)
                    }
                    helperText={
                      formik.touched.posicionInicial &&
                      formik.errors.posicionInicial
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="posicionFinal"
                    name="posicionFinal"
                    label="Posición Final"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.posicionFinal}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.posicionFinal &&
                      Boolean(formik.errors.posicionFinal)
                    }
                    helperText={
                      formik.touched.posicionFinal &&
                      formik.errors.posicionFinal
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="letra"
                    name="letra"
                    label="Letra"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.letra}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={formik.touched.letra && Boolean(formik.errors.letra)}
                    helperText={formik.touched.letra && formik.errors.letra}
                    disabled={isDisabled}
                  />
                </div>

                <div className="col mt-3">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="idtipoEspacio"
                      shrink={true}
                    >
                      Tipo Espacio
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.idtipoEspacio}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      defaultValue={30}
                      inputProps={{
                        name: "idtipoEspacio",
                        id: "idtipoEspacio",
                      }}
                    >
                      <option aria-label="Seleccionar tipo de espacio">
                        Seleccionar tipo de espacio
                      </option>

                      {finalData.map((espacio, index) => (
                        <option key={index} value={espacio.id}>
                          {espacio.espacio}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>

                <div className="col mt-3">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success w-100"
                  >
                    Agregar Espacios
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
