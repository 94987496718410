import React from "react";
import { AuthContext } from "./../../auth/authContext";
import { checkPermisosPorUsuarioPorGuidPantalla } from "./../../API/ApiPermisos";
import { SinPermisoComponent } from "./../../Components/SinPermisoComponent/SinPermisoComponent";
import TextField from "@mui/material/TextField";
const guidVista = "ef433e95-e3b0-4caa-a533-3b599add112e";

export const ReporteDeIngresosScreenComponent = () => {
  const todayInicio = new Date();
  const todayFin = new Date();
  const numberOfDaysToAdd = 15;
  const dateInicio = todayInicio.setDate(
    todayInicio.getDate() - numberOfDaysToAdd
  );
  const dateFin = todayFin.setDate(todayFin.getDate());
  const defaultValueInicio = new Date(dateInicio).toISOString().split("T")[0]; // yyyy-mm-dd
  const defaultValueFin = new Date(dateFin).toISOString().split("T")[0]; // yyyy-mm-dd
  const { user, dispatch } = React.useContext(AuthContext);
  const [reporteFechaInicial, setReporteFechaInicial] =
    React.useState(defaultValueInicio);
  const [reporteFechaFin, setReporteFechaFin] = React.useState(defaultValueFin);
  const [finalData, setFinalData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoReporte, setPermisoReporte] = React.useState(false);

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "8f157d70-8d87-4d17-8341-afed9c53b833" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
            if (
              permiso.guidFuncion == "8f157d70-8d87-4d17-8341-afed9c53b833" &&
              permiso.allowed == 1
            ) {
              setPermisoReporte(true);
            }
          }
        }
      }
    );
  }, []);

  return (
    <>
      {permisoParaVisualizar == true && permisoReporte == true ? (
        <React.Fragment>
          <div className="container-fluid mb-3 mt-2">
            <div className="row row-cols-md-3 d-flex align-items-center">
              <div className="col">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Inicio"
                  type="date"
                  variant="standard"
                  value={reporteFechaInicial}
                  onChange={(e) => {
                    setReporteFechaInicial(e.target.value);
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Fin"
                  type="date"
                  variant="standard"
                  value={reporteFechaFin}
                  onChange={(e) => {
                    setReporteFechaFin(e.target.value);
                  }}
                />
              </div>
              <div className="col d-print-none">
                <button
                  className="btn btn-sm btn-success w-100"
                  onClick={() => {

                  let fechaInicial = reporteFechaInicial;
                  let fechaFinal = reporteFechaFin;
                  



                    
                    setLoading(true);
                    /*   reporteCobranzaSerie(
                      user,
                      seriesSeleccionadas,
                      reporteFechaInicial,
                      reporteFechaFin
                    ).then((resultado) => {
                      if (resultado.status == 200) {
                        setFinalData(resultado.data);
                      }
                      setLoading(false);
                    }); */
                  }}
                >
                  {" "}
                  Buscar{" "}
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
