import React from "react";
import styles from "./SeleccionarTipoPagoComponent.module.css";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { toast } from "react-toastify";
const SeleccionarTipoPagoComponent = ({
  finalDataInicio,
  setPagoSeleccionado,
}) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {finalDataInicio.map((menu) => (
            <React.Fragment key={menu.id}>
              <div
                className="col"
                onClick={() => {
                  toast.success("Ingresa el pago de " + menu.nombre);
                  setPagoSeleccionado(menu.id);
                }}
              >
                <div className="card">
                  <div className={`${styles.cardPago} card-body text-center`}>
                    <MonetizationOnIcon
                      fontSize="large"
                      style={{ color: "green" }}
                    />
                    <br />
                    <br />
                    <h5>Pagar</h5>
                    <h4>{menu.nombre} </h4>
                    <p>{menu.descripcion}</p>
                    <br />
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default SeleccionarTipoPagoComponent;
