import React from "react";
import styles from "./LoadingComponent.module.css";
import imageCargando from "../../Images/progress.gif";
export const LoadingComponent = () => {
  return (
    <>
      <div className={styles.loadingDiv + " d-print-none"}>
        <div className={styles.contentDiv}>
          <img src={imageCargando} height="50" alt="Cargando" />
          <br />
          Espera un momento, <br /> <small> procesando solicitud</small>
        </div>
      </div>
    </>
  );
};
