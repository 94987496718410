import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import {
  agregarServicioAOrdenDeServicio,
  getServiciosOrdenesServicio,
} from "../../API/ApiServiciosOrdenesServicio";
import { nuevoPagoOrdenServicio } from "../../API/ApiPagosOrdenes";
import { agregarJardinAPanteon } from "../../API/ApiJardines";
import { AddPanteon } from "../../API/ApiPanteones";
import {
  AgregarUniplexByIdJardin,
  AsignarPersonaAUniplexByIdEspacio,
} from "../../API/ApiUniplex";
import { LoadingComponent } from "../LoadingComponent/LoadingComponent";
import {
  AgregarEspaciosACapillaByIdCapilla,
  AsignarPersonaAEspacioCapillaByIdEspacio,
} from "../../API/ApiCapillas";
import { AsignarPersonaADuplexByIdEspacio } from "../../API/ApiDuplex";
import { AsignarPersonaAMuralesByIdEspacio } from "../../API/ApiMurales";

const validationSchema = yup.object({
  persona: yup.string("Persona"),
  fechaEx: yup.string("Fecha Ex"),
  fechaIn: yup.string("Fecha In"),
});

export const AsignarFallecidosAEspacioMuralesComponent = (props) => {
 
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      persona:
        props.crudAction.data.persona == null
          ? ""
          : props.crudAction.data.persona,
      fechaIn:
        props.crudAction.data.fechaIn == null
          ? ""
          : props.crudAction.data.fechaIn,
      fechaEx:
        props.crudAction.data.fechaEx == null
          ? ""
          : props.crudAction.data.fechaEx,
      id: props.crudAction.data.id,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);
      AsignarPersonaAMuralesByIdEspacio(user, values).then((resultado) => {
        if (resultado.status == 200) {
          props.setCrudAction({ action: null, data: null });
          toast.success("Nombre asignado Correctamente");
        } else {
          toast.error("Ha habido un error, consulte la consola");
        }
        setIsDisabled(false);
      });
    },
  });

  return (
    <>
      <div className="crudDivComponent col-md-3 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Asignar fallecido a Espacio Murales </h6>
                  <small className="text-muted">
                    Ingresa los datos para agregar a mural
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>

                  <LoadingComponent />
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row row-cols-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="persona"
                    name="persona"
                    label="Persona"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.persona}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.persona && Boolean(formik.errors.persona)
                    }
                    helperText={formik.touched.persona && formik.errors.persona}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="fechaIn"
                    name="fechaIn"
                    label="fechaIn"
                    type={"date"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.fechaIn}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.fechaIn && Boolean(formik.errors.fechaIn)
                    }
                    helperText={formik.touched.fechaIn && formik.errors.fechaIn}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="fechaEx"
                    name="fechaEx"
                    label="fechaEx"
                    type={"date"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.fechaEx}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.fechaEx && Boolean(formik.errors.fechaEx)
                    }
                    helperText={formik.touched.fechaEx && formik.errors.fechaEx}
                    disabled={isDisabled}
                  />
                </div>
              
                <div className="col mt-3">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success w-100"
                  >
                    Editar Fallecido
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
