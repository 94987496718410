import React from "react";
import { getCatalogosContratos } from "../../API/ApiSeriesCobranza";
import { AuthContext } from "../../auth/authContext";
import { Button } from "@mui/material";
import CrudSeriesDeCobranzaComponent from "../../Components/CrudSeriesDeCobranzaComponent/CrudSeriesDeCobranzaComponent";

const SeriesDeCobranzaScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [finalData, setFinalData] = React.useState([]);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  React.useEffect(() => {
    getCatalogosContratos(user, { cat: "sCobranza" }).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });
  }, [crudAction]);

  return (
    <React.Fragment>
      <div className="container-fluid mt-4">
        <div className="row">
          <div
            className=" col-12 text-right right-text"
            style={{ textAlign: "right" }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setCrudAction({ action: "Crear", data: null });
              }}
            >
              Agregar
            </Button>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-4">
        <div className="row">
          {finalData.map((serie) => {
            return (
              <React.Fragment key={serie.id}>
                <div className=" col-md-3 mb-2">
                  <div className="card">
                    <div className="card-body">
                      {serie.urlImagen == null ? (
                        <strong style={{ color: "red" }}> Sin Imagen </strong>
                      ) : (
                        <img
                          src={serie.urlImagen}
                          alt="imagen"
                          style={{ width: "20%" }}
                        />
                      )}
                      <br />
                      <strong>{serie.serie}</strong>
                    </div>
                    <div className="card-footer">
                      <Button
                        variant="contained"
                        color="warning"
                        size={"small"}
                        onClick={() => {
                          setCrudAction({ action: "Editar", data: serie });
                        }}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        className="ms-2"
                        size={"small"}
                        onClick={() => {
                          setCrudAction({ action: "Eliminar", data: serie });
                        }}
                      >
                        Eliminar
                      </Button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {crudAction.action == "Crear" ||
      crudAction.action == "Editar" ||
      crudAction.action == "Eliminar" ? (
        <>
          <CrudSeriesDeCobranzaComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
          />
        </>
      ) : null}
    </React.Fragment>
  );
};

export default SeriesDeCobranzaScreenComponent;
