import React from "react";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import styles from "./LoginScreenComponent.module.css";
import { apiLoginUser, loginIntranetUser } from "../../API/ApiLoginUsers";

import jwt_decode from "jwt-decode";
import { AuthContext } from "../../auth/authContext";
import { types } from "./../../types/types";
import { useNavigate } from "react-router-dom";
import vitaNovaLogo from "../../Images/logoVitanova.jpg";
import { toast } from "react-toastify";
import InputAdornment from "@mui/material/InputAdornment";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const secretKey = "tribunal_de_justicia_administrativa_gto";

const validationSchema = yup.object({
  email: yup.string("Ingresa correo electrónico").required("Correo requerido"),
  password: yup.string("Ingresa contraseña").required("Ingresa contraseña"),
});

export const LoginScreenComponent = () => {
  const { dispatch } = React.useContext(AuthContext);

  const [cargando, setCargando] = React.useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setCargando(true);
      apiLoginUser(values).then((resultado) => {
        if (resultado.status == 200) {
          dispatch({
            type: types.login,
            payload: resultado.data,
          });
          window.location.href = "/";
          navigate("/", {
            replace: true,
          });
        } else {
          setCargando(false);
        }
      });
    },
  });

  return (
    <>
      <div className={styles.fondoLogin}></div>
      <div className="container animate__animated animate__fadeIn">
        <div className="row">
          <div className="col-12 col-sm-10 col-lg-5 mx-auto">
            <div className="card cardLogin" style={{ marginTop: "15vh" }}>
              <div className="card-body text-center">
                <img
                  src={vitaNovaLogo}
                  alt="Logotipo Inicio"
                  style={{ width: "200px", marginTop: "3vh" }}
                />
                <div className="my-4">
                  <h5>Bienvenido</h5>
                  <h6 className="fw-normal">Iniciar Sesión en sistema</h6>
                  <h6>{process.env.REACT_APP_CONNECTION_MODE} </h6>
                </div>
                <form onSubmit={formik.handleSubmit} className="px-5">
                  <TextField
                    margin="normal"
                    size="small"
                    fullWidth
                    id="email"
                    name="email"
                    disabled={cargando}
                    label="Correo"
                    value={formik.values.email}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          @funerariavitanova.com
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <TextField
                    margin="normal"
                    size="small"
                    fullWidth
                    id="password"
                    name="password"
                    type="password"
                    disabled={cargando}
                    label="Contraseña"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />

                  <div className="small py-3" style={{ textAlign: "justify" }}>
                    <small className="text-muted">
                      Para poder acceder a este sistema ingresa tu usuario y
                      contraseña, recuerda proteger en todo momento tu usuario y
                      contraseña.
                    </small>
                    <br />
                    <small className="text-muted">
                      Sitio creado por Vita Nova &copy;{" "}
                      {new Date().getFullYear()}
                    </small>
                  </div>

                  <button
                    className="my-4 btn btn-primary w-100 text-white"
                    type="submit"
                    disabled={cargando}
                  >
                    Iniciar Sesión
                  </button>
                </form>
              </div>
            </div>
            <div className="d-flex justify-content-between px-1 pt-2 text-muted small">
              <small style={{ color: "white" }}>Vita Nova Funeraria</small>
              <small>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="/restorepassword"
                >
                  Recuperar Contraseña
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
      {cargando === true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </>
  );
};
