import React from "react";
import { getServiciosOrdenesServicioDetalle } from "../../API/ApiServiciosOrdenesServicio";
import { AuthContext } from "./../../auth/authContext";
import AddIcon from "@mui/icons-material/Add";
import { AddServicioAOrdenDeServicioComponent } from "../AddServicioAOrdenDeServicioComponent/AddServicioAOrdenDeServicioComponent";
import { EditServicioAOrdenDeServicioComponent } from "../EditServicioAOrdenDeServicioComponent/EditServicioAOrdenDeServicioComponent";
import { DeleteServicioAOrdenDeServicioComponent } from "../DeleteServicioAOrdenDeServicioComponent/DeleteServicioAOrdenDeServicioComponent";
import { toast } from "react-toastify";
export const EditarServiciosOrdenServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [finalData, setFinalData] = React.useState([]);
  const [totalCuenta, setTotalCuenta] = React.useState();

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    getServiciosOrdenesServicioDetalle(user, props.finalData.folio).then(
      (resultado) => {
        if (resultado.status == 200) {
          setFinalData(resultado.data);

          let total = 0.0;

          for (const productosAgregados of resultado.data) {
            total = total + parseFloat(productosAgregados.precio);
          }

          props.setTotalServicios(total);

          setTotalCuenta(total);
        }
      }
    );
  }, []);
  React.useEffect(() => {
    getServiciosOrdenesServicioDetalle(user, props.finalData.folio).then(
      (resultado) => {
        if (resultado.status == 200) {
          setFinalData(resultado.data);

          let total = 0.0;

          for (const productosAgregados of resultado.data) {
            total = total + parseFloat(productosAgregados.precio);
          }
          props.setTotalServicios(total);
        //  toast.success("Actualizando total en Pagos");
          setTotalCuenta(total);
        }
      }
    );
  }, [crudAction]);

  return (
    <>
      {props.agregarServicioAOrdenDeServicio == true ? (
        <>
          <div className="container-fluid">
            <div className="row row-cols-1">
              <div className="col text-end mt-3">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    setCrudAction({
                      action: "add",
                      data: null,
                    });
                  }}
                >
                  <AddIcon fontSize="small" /> Agregar Servicio
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-sm-10">
            <div className="container-fluid">
              <div className="row row-cols-md-4">
                {finalData.map((serviciosProductosAgregados, index) => (
                  <div className="col" key={index}>
                    <div className="card mb-2">
                      <div className="card-body">
                        <strong>
                          {serviciosProductosAgregados.nombreServicio}
                        </strong>
                        <br />
                        <small>
                          {serviciosProductosAgregados.comentarios  ? serviciosProductosAgregados.comentarios : 'Sin Comentarios'  }
                        </small>
                        <br />$ {  parseFloat(serviciosProductosAgregados.precio).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
}
                      </div>
                      <div className="card-footer">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          {/*<button
                            className="btn btn-sm btn-warning"
                            type="button"
                            onClick={() => {
                              setCrudAction({
                                action: "edit",
                                data: serviciosProductosAgregados,
                              });
                            }}
                          >
                            Editar
                          </button>*/}
                          {props.editarServicioAOrdenDeServicio == true ? (
                            <>
                              <button
                                className="btn btn-sm btn-danger"
                                type="button"
                                onClick={() => {
                                  setCrudAction({
                                    action: "delete",
                                    data: serviciosProductosAgregados,
                                  });
                                }}
                              >
                                Eliminar
                              </button>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="container-fluid">
              <div className="row row-cols-1">
                <h6>
                  Total Cuenta: $
                  {parseFloat(totalCuenta)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {crudAction.action == "add" ? (
        <AddServicioAOrdenDeServicioComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
          folio={props.finalData.folio}
        />
      ) : null}
      {crudAction.action == "edit" ? (
        <EditServicioAOrdenDeServicioComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
          folio={props.finalData.folio}
        />
      ) : null}
      {crudAction.action == "delete" ? (
        <DeleteServicioAOrdenDeServicioComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
          folio={props.finalData.folio}
        />
      ) : null}
    </>
  );
};
