import React from "react";
import { getCatalogosContratos } from "../../API/ApiSeriesCobranza";
import { AuthContext } from "../../auth/authContext";
import { Button, Chip } from "@mui/material";
import CrudSeriesDeCobranzaComponent from "../../Components/CrudSeriesDeCobranzaComponent/CrudSeriesDeCobranzaComponent";
import { getAllEmpleados } from "../../API/ApiEmpleados";
import { getObtenerCobradores } from "../../API/ApiCobranza";
import CrudAsociarSeriesCobradoresComponent from "../../Components/CrudAsociarSeriesCobradoresComponent/CrudAsociarSeriesCobradoresComponent";

const SeriesDeCobranzaCobradoresScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [finalDataSeries, setFinalDataSeries] = React.useState([]);
  const [finalDataUsuarios, setFinalDataUsuarios] = React.useState([]);
  const [finalDataCobradores, setFinalDataCobradores] = React.useState([]);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  React.useEffect(() => {
    getCatalogosContratos(user, { cat: "sCobranza" }).then((resultado) => {
      if (resultado.status == 200) {
        setFinalDataSeries(resultado.data);
      } else {
        setFinalDataSeries([]);
      }
    });

    getAllEmpleados(user).then((resultado) => {
      if (resultado.status == 200) {

        
        setFinalDataUsuarios(resultado.data);
      } else {
        setFinalDataUsuarios([]);
      }
    });

    getObtenerCobradores(user).then((resultado) => {
      if (resultado.status == 200) {
        
        setFinalDataCobradores(resultado.data);
      } else {
        setFinalDataCobradores([]);
      }
    });
  }, [crudAction]);

  return (
    <React.Fragment>
      <div className="container-fluid mt-4">
        <div className="row">
          {finalDataCobradores.map((cobrador) => {
            return (
              <React.Fragment key={cobrador.id}>
                <div className="col-12 mb-3">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between d-flex align-items-center">
                      <span>
                        <strong>
                          {cobrador.nombre} {cobrador.apaterno}{" "}
                          {cobrador.amaterno}{" "}
                        </strong>{" "}
                        -{" "}
                        <small style={{ opacity: 0.4 }}>
                          {cobrador.rfc == "null"
                            ? "XAXX010101000"
                            : cobrador.rfc}
                        </small>
                      </span>

                      <Button
                        color="success"
                        variant="contained"
                        onClick={() => {
                          setCrudAction({
                            action: "Administrar",
                            data: cobrador,
                          });
                        }}
                      >
                        Administrar
                      </Button>
                    </div>
                    <div className="card-body">
                      {cobrador.empleadoseriecobranzas.length == 0 ? (
                        <>
                          <i style={{ color: "red" }}>Sin series asignadas</i>
                        </>
                      ) : (
                        <>
                          {cobrador.empleadoseriecobranzas.map((serie) => {
                            return (
                              <React.Fragment key={serie.id}>
                                <Chip
                                  label={serie.cseriecobranza.serie}
                                  color="success"
                                />
                              </React.Fragment>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {crudAction.action == "Administrar" ? (
        <>
          <CrudAsociarSeriesCobradoresComponent
            finalDataSeries={finalDataSeries}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
          />
        </>
      ) : null}
    </React.Fragment>
  );
};

export default SeriesDeCobranzaCobradoresScreenComponent;
