import React from "react";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { AuthContext } from "../../auth/authContext";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import TextField from "@mui/material/TextField";
import { reporteAuditoria } from "../../API/ApiAuditoria";
import { ButtonDownloadToXlsComponent } from "../../Components/ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";

const guidVista = "81c9016f-89db-4be1-a11b-8d63384d7539";

export const AuditoriaScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const todayInicio = new Date();
  const todayFin = new Date();
  const numberOfDaysToAdd = 1;
  const dateInicio = todayInicio.setDate(
    todayInicio.getDate() - numberOfDaysToAdd
  );
  const dateFin = todayFin.setDate(todayFin.getDate() + numberOfDaysToAdd);

  const defaultValueInicio = new Date(dateInicio).toISOString().split("T")[0]; // yyyy-mm-dd

  const defaultValueFin = new Date(dateFin).toISOString().split("T")[0]; // yyyy-mm-dd

  const [reporteFechaInicial, setReporteFechaInicial] =
    React.useState(defaultValueInicio);
  const [reporteFechaFin, setReporteFechaFin] = React.useState(defaultValueFin);

  const [finalData, setFinalData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "f80b493a-cf51-4498-932c-f4763b83192c" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
          }
        }
      }
    );
  }, []);

  return (
    <>
      {permisoParaVisualizar == true ? (
        <React.Fragment>
          {loading == false ? (
            <>
              <div className="container-fluid mb-3 mt-2">
                <div className="row row-cols-md-3 d-flex align-items-center">
                  <div className="col">
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      id="outlined-basic"
                      label="Fecha Inicio"
                      type="date"
                      variant="standard"
                      value={reporteFechaInicial}
                      onChange={(e) => {
                        setReporteFechaInicial(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      id="outlined-basic"
                      label="Fecha Fin"
                      type="date"
                      variant="standard"
                      value={reporteFechaFin}
                      onChange={(e) => {
                        setReporteFechaFin(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col d-print-none">
                    <button
                      className="btn btn-sm btn-success w-100"
                      onClick={() => {
                        setLoading(true);
                        reporteAuditoria(user, {
                          fechaInicio: reporteFechaInicial,
                          fechaFin: reporteFechaFin,
                        }).then((resultado) => {
                          if (resultado.status == 200) {
                            setFinalData(resultado.data);
                          }
                          setLoading(false);
                        });
                      }}
                    >
                      {" "}
                      Buscar{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 text-end">
                    <ButtonDownloadToXlsComponent
                      data={finalData}
                      fileName="Reporteauditoria"
                    />
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-2">
                          <strong>Fecha</strong>
                        </div>
                        <div className="col-2">
                          <strong>Usuario</strong>
                        </div>
                        <div className="col-2">
                          <strong>Tipo Acción</strong>
                        </div>
                        <div className="col-2">
                          <strong>Tabla Afectada</strong>
                        </div>
                        <div className="col-4">
                          <strong>Datos Insertados</strong>
                        </div>
                      </div>
                    </div>

                    <div className="container-fluid">
                      {finalData.map((auditoria, index) => (
                        <React.Fragment key={index}>
                          <div className="row">
                            <div className="col-2">{auditoria.createdAt}</div>
                            <div className="col-2">{auditoria.usuario}</div>
                            <div className="col-2">{auditoria.tipoAccion}</div>
                            <div className="col-2">
                              {auditoria.tablaAfectada}
                            </div>
                            <div
                              className="col-4"
                              style={{ wordWrap: "break-word" }}
                            >
                              {JSON.stringify(auditoria.datosInsertados)}
                            </div>
                          </div>
                          <hr />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : <LoadingComponent />}
        </React.Fragment>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
