import React from "react";
import imagenVita from "../../Images/logoVitanova.jpg";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";

const EstadoDeCuentaPagosMantenimientoComponent = (props) => {
  const [totalPagosFinal, setTotalPagosFinal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let listo = false;
    setLoading(true);
    let TotalPagos = 0;
    setLoading(false);
    for (const contrato of props.estadoDeCuenta) {
      TotalPagos = TotalPagos + parseFloat(contrato.monto);
    }
    setTotalPagosFinal(TotalPagos);

    setLoading(false);

    return () => {
      listo = true;
    };
  }, []);

  return (
    <>
      <div className="container-fluid d-print-none">
        <div className="row text-end">
          <div className="col-sm-12 mt-5">
            <button
              className="btn btn-sm btn-success"
              onClick={() => {
                window.print();
              }}
            >
              Imprimir
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid d-print-grid">
        <div className="row">
          {/* Encabezado */}
          <div className="col-12 mt-3">
            <div className="container-fluid d-print-grid">
              <div className="row">
                <div className="col-sm-2 d-print-grid">
                  <img
                    alt="logoVitanova"
                    className="mx-auto"
                    src={imagenVita}
                    width={100}
                  />
                </div>
                <div className="col-sm-10 text-end small">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-6">
                        {props.finalDataDetalle.etapaUno.map(
                          (etapaUno, index) => (
                            <React.Fragment key={index}>
                              <strong>Contrato:</strong> {etapaUno.serie} -{" "}
                              {etapaUno.noContrato}
                              <br />
                              <strong>Fecha:</strong>{" "}
                              {CambiarFormatoFechaHelper(
                                etapaUno.fechaContrato
                              )}
                              <br />
                              <strong>Status:</strong>{" "}
                              {etapaUno.status === 1 ? <>Activo</> : null}
                              {etapaUno.status === 2 ? <>Cancelado</> : null}
                              {etapaUno.status === 3 ? <>Inactivo</> : null}
                              {etapaUno.status === 4 ? <>Otorgado</> : null}
                              {etapaUno.status === 5 ? <>Pagado</> : null}
                              {etapaUno.status === 6 ? (
                                <>Restructurado</>
                              ) : null}
                              <br />
                              <strong>Nombre:</strong> {etapaUno.titular}
                              <br />
                              <strong>
                                Domicilio: {etapaUno.domicilioTitular1}
                              </strong>
                              <br />
                              <strong>Serie:</strong> {etapaUno.serie}
                              <br />
                            </React.Fragment>
                          )
                        )}
                      </div>
                      <div className="col-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Pagos */}
          <div className="col-12">
            <table className="table text-center">
              <thead>
                <tr>
                  <th scope="col">No. Pago</th>
                  <th scope="col">Fecha Pago</th>
                  <th scope="col">Concepto</th>
                  <th scope="col">Monto</th>
                </tr>
              </thead>
              <tbody>
                {props.estadoDeCuenta.map((pagos, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{pagos.fechaPago}</td>
                      <td>{pagos.conceptoPago}</td>

                      <td>
                        {"$ "}
                        {parseFloat(pagos.monto)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
                <tr>
                  <th>
                    <strong>Total: </strong>
                  </th>
                  <td></td>
                  <td></td>
                  <td>
                    {" "}
                    {"$ "}
                    {parseFloat(totalPagosFinal)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default EstadoDeCuentaPagosMantenimientoComponent;
