import React from "react";
import { getServiciosOrdenesServicio } from "../../API/ApiServiciosOrdenesServicio";
import { AuthContext } from "../../auth/authContext";
import { LoadingSkeletonComponent } from "../../Components/LoadingSkeletonComponent/LoadingSkeletonComponent";
import EditIcon from "@mui/icons-material/Edit";
import { AddServicioOrdenesServicioComponent } from "../../Components/AddServicioOrdenesServicioComponent/AddServicioOrdenesServicioComponent";
import { EditServicioOrdenesServicioComponent } from "../../Components/EditServicioOrdenesServicioComponent/EditServicioOrdenesServicioComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
const guidVista = "5e421c58-c48f-41ff-b746-c27c5d627c1a";

export const ServiciosOrdenesServicioScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [finalData, setFinalData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [permisoAgregar, setPermisoAgregar] = React.useState(false);

  const [permisoEditar, setPermisoEditar] = React.useState(false);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "e6054eee-f97f-4d50-9246-18e84ef7ff1f" &&
              permiso.allowed == 1
            ) {
              setPermisoAgregar(true);
            }
            if (
              permiso.guidFuncion == "3c668120-8df6-40b8-9ec5-4faee1908745" &&
              permiso.allowed == 1
            ) {
              setPermisoEditar(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    getServiciosOrdenesServicio(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
        setIsLoading(false);
      }
    });
  }, []);
  React.useEffect(() => {
    getServiciosOrdenesServicio(user).then((resultado) => {
      setIsLoading(true);
      if (resultado.status == 200) {
        setFinalData(resultado.data);
        setIsLoading(false);
      }
    });
  }, [crudAction]);

  return (
    <>
      {permisoAgregar == true ? (
        <div className="container-fluid mb-3">
          <div className="row">
            <div className="col-12 text-end">
              <button
                className="btn btn-sm btn-success"
                onClick={() => {
                  setCrudAction({
                    action: "add",
                    data: null,
                  });
                }}
              >
                Agregar Servicio
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {isLoading ? (
        <LoadingSkeletonComponent />
      ) : (
        <>
          <div className="container-fluid">
            <div className="row row-cols-md-3">
              {finalData.map((servicio, index) => (
                <div key={index} className="col">
                  <div className="card mb-3">
                    <div className="card-body">{servicio.nombreServicio}</div>
                    <div className="card-footer">
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {permisoEditar == true ? (
                          <>
                            <button
                              className="btn btn-sm btn-warning me-md-2"
                              type="button"
                              onClick={() => {
                                setCrudAction({
                                  action: "edit",
                                  data: servicio,
                                });
                              }}
                            >
                              <EditIcon fontSize="small" /> Editar
                            </button>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {crudAction.action === "add" ? (
        <AddServicioOrdenesServicioComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
          nombre="Agregar Producto"
        />
      ) : null}
      {crudAction.action === "edit" ? (
        <EditServicioOrdenesServicioComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
          nombre="Editar Producto"
        />
      ) : null}
    </>
  );
};
