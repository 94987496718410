import React from "react";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { newContractPayment } from "../../API/ApiPagosContratos";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { imprimirTicket } from "./../../API/ApiImpresoraTickets";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DateTime } from "luxon";

const getDateTime = () => {
  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd
  const currDate = defaultValue;
  return currDate;
};

export const AddPaymentMantenimientoComponentCrudSegundaVersion = (props) => {
  console.log(props);

  const validationSchema = yup.object({
    serie: yup.string("Ingresa serie").required("Serie requerido"),
    folio: yup.string("Ingresa serie").required("Serie requerido"),
    noContrato: yup
      .string("Ingresa Número de contrato")
      .required("Número de contrato requerido"),
    idcontrato: yup
      .string("Ingresa Número de contrato")
      .required("Número de contrato requerido"),
    monto: yup
      .number("Valor Númerico")
      .positive("Valor Negativo")
      .required("Monto requerido")
      .min(1, "Solo números positivos")
      .max(
        props.crudAction.data.montoPagado
          ? props.crudAction.data.precioLista -
              props.crudAction.data.montoPagado
          : props.crudAction.data.primerAno === true
          ? props.crudAction.data.precioPrimerAno
          : props.crudAction.data.precioLista,
        "La cantidad excede la liquidación del pago"
      ),
    fechaPago: yup.string("Ingresa fecha de pago").required("Fecha requerida"),
    justificacion: yup
      .string("Ingresa Justificación")
      .required("Justificación requerida"),
    idtipoPago: yup.string("Ingresa monto").required("Monto requerido"),
  });

  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      serie: "M",
      folio: "0",
      idano: props.crudAction.preciosMantenimiento.id,
      descuento: "0",
      idtipoPago: "1",
      justificacion: "",
      noContrato: props.crudAction.datosContrato.etapaUno[0].noContrato,
      idcontrato: props.crudAction.datosContrato.etapaUno[0].id,
      monto: props.crudAction.data.montoPagado
        ? props.crudAction.data.precioLista - props.crudAction.data.montoPagado
        : props.crudAction.data.primerAno === true
        ? props.crudAction.data.precioPrimerAno
        : props.crudAction.data.precioLista,
      fechaPago: getDateTime(),
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {


      let fechaParaGuardar = DateTime.fromISO(values.fechaPago);

      let fechaActual = DateTime.now();

      if (fechaParaGuardar <= fechaActual) {
        setIsDisabled(true);
      } else {
        toast.info("Verifica la fecha de pago");
        toast.error(
          "No puedes ingresar fechas de pago posteriores a la fecha actual"
        );

        return;
      }


      setIsDisabled(true);

      toast.info("Guardando información de pago");
      values.folio = values.folio.toString();
      values.noContrato = values.noContrato.toString();

      values.mantenimientoPago =
        props.crudAction.precioMantenimiento.espacio +
        " " +
        props.crudAction.precioMantenimiento.ano;

      newContractPayment(user, values).then((resultado) => {
        if (resultado.status == 200) {
          setIsDisabled(false);

          var datosImpresion = {
            tipoImagen: 2,
            razonSocial: values.serie.toString() === "SM" ? 2 : 1,
            datosRecibo: [
              {
                nombre: "Fecha Pago",
                valor: resultado.data.fechaPago,
              },
              {
                nombre: "Folio Recibo",
                valor: resultado.data.serie + " " + resultado.data.folio,
              },
              {
                nombre: "No. Contrato",
                valor:
                  resultado.data.serieContrato +
                  " " +
                  resultado.data.noContrato,
              },
              {
                nombre: "Nombre Titular",
                valor: resultado.data.titular,
              },
              {
                nombre: "Dirección",
                valor: resultado.data.domicilio,
              },
              {
                nombre: "Descripción",
                valor: values.mantenimientoPago,
              },
              {
                nombre: "Abono",
                valor:
                  "$ " +
                  parseFloat(resultado.data.abono)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },
              {
                nombre: "Método Pago",
                valor:
                  resultado.data.idtipoPago == 1
                    ? "Efectivo"
                    : resultado.data.idtipoPago == 2
                    ? "Tarjeta"
                    : "N/A",
              },
            ],
            datosCodigoBarra: "MANT-" + resultado.data.idcontrato,
            idpago: resultado.data.id,
          };
          imprimirTicket(datosImpresion).then((resultado) => {
            toast.success("Se ha agregado correctamernte el pago");
            props.setCrudAction({});
            setIsDisabled(false);
          });
        } else {
          toast.error("ha ocurrido un error");
          setIsDisabled(false);
        }
      });
    },
  });

  React.useEffect(() => {
    formik.setFieldValue("idano", props.crudAction.precioMantenimiento.id);
  }, []);

  return (
    <>
      <div className="crudDivComponent col-md-3 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Agregar Pago a espacio</h6>
                  <small className="text-muted">
                    Ingresa los datos del recibo de mantenimiento
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    className="mb-2"
                    id="noContrato"
                    name="noContrato"
                    label="Número de Contrato:"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.noContrato}
                    disabled={true}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.noContrato &&
                      Boolean(formik.errors.noContrato)
                    }
                    helperText={
                      formik.touched.noContrato && formik.errors.noContrato
                    }
                  />
                </div>

                <div className="col-sm-6">
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    className="mb-2"
                    id="fechaPago"
                    name="fechaPago"
                    type={"date"}
                    label="fechaPago: "
                    value={formik.values.fechaPago}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    disabled={isDisabled}
                    error={
                      formik.touched.fechaPago &&
                      Boolean(formik.errors.fechaPago)
                    }
                    helperText={
                      formik.touched.fechaPago && formik.errors.fechaPago
                    }
                  />
                </div>

                <div className="col-sm-6">
                  <FormControl fullWidth shrink>
                    <InputLabel variant="standard" htmlFor="idano" shrink>
                      Año Pago
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.idano}
                      shrink={true}
                      onChange={formik.handleChange}
                      disabled={true}
                      inputProps={{
                        name: "idano",
                        id: "idano",
                        shrink: true,
                      }}
                    >
                      {props.crudAction.preciosMantenimiento.map((precio) => (
                        <option key={precio.id} value={precio.id}>
                          {precio.ano} - {precio.espacio}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>

                <div className="col-md-12">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="serie"
                      shrink={true}
                    >
                      Serie
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.serie}
                      onChange={formik.handleChange}
                      defaultValue={"M"}
                      disabled={isDisabled}
                      inputProps={{
                        name: "serie",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value={"M"}>M</option>
                    </NativeSelect>
                  </FormControl>
                </div>

                <div className="col-md-12">
                  <br />
                  <TextField
                    size="small"
                    fullWidth
                    className="mb-3 d-none"
                    id="folio"
                    name="folio"
                    disabled={isDisabled}
                    type={"number"}
                    variant="standard"
                    label="Folio:"
                    value={formik.values.folio}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.folio && Boolean(formik.errors.folio)}
                    helperText={formik.touched.folio && formik.errors.folio}
                  />
                </div>

                <div className="col-md-12">
                  <TextField
                    size="small"
                    fullWidth
                    className="mb-2"
                    id="monto"
                    name="monto"
                    InputProps={{ inputProps: { min: 0, step: "any" } }}
                    variant="standard"
                    type={"number"}
                    label="Monto: $"
                    value={formik.values.monto}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    error={formik.touched.monto && Boolean(formik.errors.monto)}
                    helperText={formik.touched.monto && formik.errors.monto}
                  />
                </div>

                <div className="col-sm-12">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                    className="mb-3"
                    id="descuento"
                    name="descuento"
                    disabled={isDisabled}
                    type={"number"}
                    variant="standard"
                    hidden="true"
                    label="Descuento %:"
                    value={formik.values.descuento}
                    onChange={(e) => {
                      var hola = formik.values.monto;

                      var resultadoMonto =
                        parseFloat(hola) * parseFloat(e.target.value);

                      resultadoMonto = resultadoMonto / 100;

                      resultadoMonto = parseFloat(hola) - resultadoMonto;

                      formik.setFieldValue("descuento", e.target.value);
                      formik.setFieldValue("monto", resultadoMonto);
                    }}
                    error={
                      formik.touched.descuento &&
                      Boolean(formik.errors.descuento)
                    }
                    helperText={
                      formik.touched.descuento && formik.errors.descuento
                    }
                  />
                </div>

                <div className="col-md-12">
                  <TextField
                    size="small"
                    fullWidth
                    className="mb-3"
                    id="justificacion"
                    name="justificacion"
                    disabled={isDisabled}
                    type={"text"}
                    variant="standard"
                    label="Justificación:"
                    value={formik.values.justificacion}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.justificacion &&
                      Boolean(formik.errors.justificacion)
                    }
                    helperText={
                      formik.touched.justificacion &&
                      formik.errors.justificacion
                    }
                  />
                </div>

                <div className="col">
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="idtipoPago" shrink={true}>
                      Tipo de Pago
                    </InputLabel>
                    <Select
                      labelId="idtipoPago"
                      id="idtipoPago"
                      value={formik.values.idtipoPago}
                      label="Tipo de Pago"
                      onChange={(e) => {
                        formik.setFieldValue("idtipoPago", e.target.value);
                      }}
                    >
                      <MenuItem value={1}>Efectivo</MenuItem>
                      <MenuItem value={2}>Tarjeta</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="col mt-2">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success w-100"
                    disabled={isDisabled}
                  >
                    Agregar Pago
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
