import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import {
  agregarServicioAOrdenDeServicio,
  getServiciosOrdenesServicio,
} from "../../API/ApiServiciosOrdenesServicio";
import { nuevoPagoOrdenServicio } from "../../API/ApiPagosOrdenes";
import { agregarJardinAPanteon } from "../../API/ApiJardines";
import {
  AddEspaciosAPanteon,
  AddPanteon,
  PostAddPersonaAEspaciosAPI,
  PutEditPersonaAEspaciosAPI,
} from "../../API/ApiPanteones";
import { getAllEspacios } from "../../API/ApiEspacios";

const validationSchema = yup.object({
  id: yup.string("Ingresa").required("Requerido"),
  persona: yup.string("Ingresa").required("Requerido"),
  fechaInhumacion: yup.string("Ingresa").required("Requerido"),
  comentarios: yup.string("Ingresa").notRequired(),
  fechaExhumacion: yup.string("Ingresa").notRequired(),
});

export const EditCuerpoAEspacioComponent = (props) => {
  console.log(props);
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [finalDataServicios, setFinalDataServicios] = React.useState([]);

  const [finalData, setFinalData] = React.useState([]);

  const [municipios, setMunicipios] = React.useState([]);

  React.useEffect(() => {
    let listo = false;

    getAllEspacios(user).then((resultado) => {
      if (resultado.status === 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });

    return () => {
      listo = true;
    };
  }, []);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  
  const formik = useFormik({
    initialValues: {
      id: props.crudAction.data.id,
      persona: props.crudAction.data.persona,
      fechaInhumacion: props.crudAction.data.fechaInhumacion,
      fechaExhumacion:
        props.crudAction.data.fechaExhumacion == null
          ? ""
          : props.crudAction.data.fechaExhumacion,
      comentarios:
        props.crudAction.data.comentarios == null
          ? ""
          : props.crudAction.data.comentarios,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.persona = values.persona.toUpperCase();

      if (values.fechaExhumacion == "") {
        delete values.fechaExhumacion;
      }
      setIsDisabled(true);
      PutEditPersonaAEspaciosAPI(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Cuerpo Editado correctamente");
          props.setCrudAction({ action: null, data: null });
        }
      });
    },
  });

  return (
    <>
      <div className="crudDivComponent col-md-4 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Editar cuerpo</h6>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row row-cols-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="idespacio"
                    name="idespacio"
                    label="Identificador de espacio"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.idespacio}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.idespacio &&
                      Boolean(formik.errors.idespacio)
                    }
                    helperText={
                      formik.touched.idespacio && formik.errors.idespacio
                    }
                    disabled={true}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="persona"
                    name="persona"
                    label="Persona"
                    type={"text"}
                    autoFocus={true}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.persona}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.persona && Boolean(formik.errors.persona)
                    }
                    helperText={formik.touched.persona && formik.errors.persona}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="fechaInhumacion"
                    name="fechaInhumacion"
                    label="Fecha de Inhumación"
                    type={"date"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.fechaInhumacion}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.fechaInhumacion &&
                      Boolean(formik.errors.fechaInhumacion)
                    }
                    helperText={
                      formik.touched.fechaInhumacion &&
                      formik.errors.fechaInhumacion
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="fechaExhumacion"
                    name="fechaExhumacion"
                    label="Fecha de Exhumación"
                    type={"date"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.fechaExhumacion}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.fechaExhumacion &&
                      Boolean(formik.errors.fechaExhumacion)
                    }
                    helperText={
                      formik.touched.fechaExhumacion &&
                      formik.errors.fechaExhumacion
                    }
                    disabled={isDisabled}
                  />
                </div>

                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="comentarios"
                    name="comentarios"
                    label="Comentarios"
                    type={"text"}
                    autoFocus={true}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.comentarios}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.comentarios &&
                      Boolean(formik.errors.comentarios)
                    }
                    helperText={
                      formik.touched.comentarios && formik.errors.comentarios
                    }
                    disabled={isDisabled}
                  />
                </div>

                <div className="col mt-3">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success w-100"
                  >
                    Agregar Cuerpo
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
