import React from "react";
import { getReporteVentasCategoriaAnio } from "../../API/ApiReportes";
import { AuthContext } from "../../auth/authContext";
import { ButtonDownloadToXlsComponent } from "../ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";

export const ReportesDeContratoComponentPorStatus = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [finalData, setFinalData] = React.useState([]);
  const [statusReporte, setStatusReporte] = React.useState(1);

  React.useEffect(() => {
    let ready = false;

    getReporteVentasCategoriaAnio(user, statusReporte).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
      }
    });

    return () => {
      ready = true;
    };
  }, []);
  React.useEffect(() => {
    let ready = false;

    getReporteVentasCategoriaAnio(user, statusReporte).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
      }
    });

    return () => {
      ready = true;
    };
  }, [statusReporte]);

  return (
    <>
      <div className="btn-group w-100 cursor-pointer">
        <div
          className={`btn btn-primary cursor-pointer ${
            statusReporte == 1 ? "active" : null
          }`}
          onClick={() => {
            setStatusReporte(1);
          }}
          aria-current="page"
        >
          Activos
        </div>
        <div
          className={`btn btn-primary cursor-pointer ${
            statusReporte == 2 ? "active" : null
          }`}
          onClick={() => {
            setStatusReporte(2);
          }}
        >
          Cancelados
        </div>
        <div
          className={`btn btn-primary cursor-pointer ${
            statusReporte == 3 ? "active" : null
          }`}
          onClick={() => {
            setStatusReporte(3);
          }}
        >
          Inactivos
        </div>
        <div
          className={`btn btn-primary cursor-pointer ${
            statusReporte == 4 ? "active" : null
          }`}
          onClick={() => {
            setStatusReporte(4);
          }}
        >
          Otorgado
        </div>
        <div
          className={`btn btn-primary cursor-pointer ${
            statusReporte == 5 ? "active" : null
          }`}
          onClick={() => {
            setStatusReporte(5);
          }}
        >
          Pagado
        </div>
        <div
          className={`btn btn-primary cursor-pointer ${
            statusReporte == 6 ? "active" : null
          }`}
          onClick={() => {
            setStatusReporte(6);
          }}
        >
          Título
        </div>
      </div>
      <span className="mt-2">
        <br />
        <br />
        <ButtonDownloadToXlsComponent
          data={finalData}
          fileName="Reporte Total ventas por año"
        />
      </span>
      <ul className="list-group list-group-flush mt-3">
        {finalData.map((reporte, index) => (
          <li
            key={index}
            className="list-group-item d-flex justify-content-between align-items-center"
          >
            <span>
              <strong> {reporte.fechaContrato == null ? "Sin Fecha" : reporte.fechaContrato} </strong> - ${" "}
              {reporte.totalContratos}
            </span>
            <span className="badge bg-primary rounded-pill">
              Contratos: {reporte.cantidadContratos}
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};
