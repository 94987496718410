import React from "react";
import styles from "./SearchInAllWebComponent.module.css";
import TextField from "@mui/material/TextField";
import FindInPageIcon from "@mui/icons-material/FindInPage";

export const SearchInAllWebComponent = () => {
  const [textoaBuscar, setTextoABuscar] = React.useState("");

  return (
    <div className={` d-flex align-items-center bg-white p-2 br-2 rounded`}>
      <TextField
        id="standard-basic"
        label="Buscar"
        variant="outlined"
        size="small"
        value={textoaBuscar}
        onChange={(e) => {
          setTextoABuscar(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            window.find(textoaBuscar);
          }
        }}
      />

      <div
        className="btn btn-sm ms-2"
        onClick={() => {
          window.find(textoaBuscar);
        }}
      >
        <FindInPageIcon size="small" />
      </div>
    </div>
  );
};
