import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import {
  agregarServicioAOrdenDeServicio,
  getServiciosOrdenesServicio,
} from "../../API/ApiServiciosOrdenesServicio";
import { nuevoPagoOrdenServicio } from "../../API/ApiPagosOrdenes";
import { imprimirTicket } from "./../../API/ApiImpresoraTickets";
import jwt_decode from "jwt-decode";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const validationSchema = yup.object({
  noRecibo: yup
    .string("Ingresa Número Recibo")
    .required("Número Recibo requerido"),
  folio: yup.string("Ingresa Folio").required("Folio requerido"),
  fechaPago: yup.string("Ingresa Fecha Pago").required("Precio requerido"),
  abono1: yup
    .number("Valor Númerico")
    .positive("Valor Negativo")
    .required("Monto requerido")
    .min(1, "Solo números positivos"),
  abonoIva: yup
    .number("Valor Númerico")
    .positive("Valor Negativo")
    .min(0, "Solo números positivos"),
  recibio: yup.string("Ingresa Recibió").required("Recibió requerido"),
  idtipoPago: yup.string("Ingresa monto").required("Monto requerido"),
});

export const AddPagoOrdenServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  var decoded = jwt_decode(user.accessToken);

  const [finalDataServicios, setFinalDataServicios] = React.useState([]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    getServiciosOrdenesServicio(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalDataServicios(resultado.data);
      }
    });
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      noRecibo: "",
      folio: props.folio,
      fechaPago: DateTime.now().toISODate(),
      abono1: "",
      abonoIva: "0",
      letra: "C",
      idtipoPago: "1",
      recibio: decoded.nombre,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let fechaParaGuardar = DateTime.fromISO(values.fechaPago);

      let fechaActual = DateTime.now();

      if (fechaParaGuardar <= fechaActual) {
        setIsDisabled(true);
      } else {
        toast.info("Verifica la fecha de pago");
        toast.error(
          "No puedes ingresar fechas de pago posteriores a la fecha actual"
        );

        return;
      }

      setIsDisabled(true);

      nuevoPagoOrdenServicio(user, values).then((resultado) => {
        if (resultado.status == 200) {
          var datosImpresion = {
            tipoImagen: 1,
            razonSocial: values.noRecibo.includes("SM") ? 2 : 1,
            datosServicios: resultado.data.serviciosContratados,
            totalServicios: resultado.data.totalServicios,
            datosRecibo: [
              {
                nombre: "Fecha Pago",
                valor: resultado.data.fechaPago,
              },
              {
                nombre: "Folio Recibo",
                valor: resultado.data.noRecibo,
              },
              {
                nombre: "Folio Orden Servicio",
                valor: resultado.data.folio,
              },
              {
                nombre: "No. Contrato",
                valor:
                  resultado.data.detalleOrden[0]?.serie +
                  " " +
                  resultado.data.detalleOrden[0]?.noContrato,
              },
              {
                nombre: "Nombre Finado",
                valor: resultado.data.detalleOrden[0]?.nombreFinado,
              },
              {
                nombre: "Nombre Titular",
                valor: resultado.data.detalleOrden[0]?.nombreFinado,
              },
              {
                nombre: "Recibe",
                valor: resultado.data.recibio,
              },
              {
                nombre: "Saldo Anterior",
                valor:
                  "$ " +
                  parseFloat(resultado.data.saldoAnterior)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },
              {
                nombre: "Abono",
                valor:
                  "$ " +
                  parseFloat(resultado.data.abono1)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },
              {
                nombre: "Abono IVA",
                valor:
                  "$ " +
                  parseFloat(resultado.data.abonoIva)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },

              {
                nombre: "Saldo Actual",
                valor:
                  "$ " +
                  parseFloat(resultado.data.saldoActual)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },

              {
                nombre: "Método Pago",
                valor:
                  resultado.data.idtipoPago == 1
                    ? "Efectivo"
                    : resultado.data.idtipoPago == 2
                    ? "Tarjeta"
                    : "N/A",
              },
            ],
            datosCodigoBarra: "OS-" + resultado.data.folio,
            idpago: resultado.data.id,
          };

          imprimirTicket(datosImpresion).then((resultado) => {
            toast.success("Pago Agregado a la orden exitosamente");
            props.setCrudAction({ action: null, data: null });
            window.location.reload(true);
          });
        } else {
          setIsDisabled(false);
          toast.error("Ha ocurrido un error al agregar el pago");
        }
      });
    },
  });

  return (
    <>
      <div className="crudDivComponent col-md-3 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Agregar pago a orden</h6>
                  <small className="text-muted">
                    Ingresa los datos para agregar el pago
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row row-cols-1">
                <div className="col-6">
                  <TextField
                    size="small"
                    fullWidth
                    id="letra"
                    name="letra"
                    label="Letra"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.letra}
                    onChange={formik.handleChange}
                    variant="standard"
                    select
                    error={formik.touched.letra && Boolean(formik.errors.letra)}
                    helperText={formik.touched.letra && formik.errors.letra}
                    disabled={isDisabled}
                  >
                    <MenuItem value={"C"}>C</MenuItem>
                    <MenuItem value={"SM"}>SM</MenuItem>
                    <MenuItem value={"P"}>P</MenuItem>
                  </TextField>
                </div>
                <div className="col-6">
                  <TextField
                    size="small"
                    fullWidth
                    id="noRecibo"
                    autoFocus
                    name="noRecibo"
                    label="No. Recibo"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.noRecibo}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.noRecibo && Boolean(formik.errors.noRecibo)
                    }
                    helperText={
                      formik.touched.noRecibo && formik.errors.noRecibo
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="folio"
                    name="folio"
                    label="Folio"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.folio}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={formik.touched.folio && Boolean(formik.errors.folio)}
                    helperText={formik.touched.folio && formik.errors.folio}
                    disabled={true}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="fechaPago"
                    name="fechaPago"
                    label="Fecha Pago"
                    type={"date"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.fechaPago}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.fechaPago &&
                      Boolean(formik.errors.fechaPago)
                    }
                    helperText={
                      formik.touched.fechaPago && formik.errors.fechaPago
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="abono1"
                    name="abono1"
                    label="Abono"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.abono1}
                    onChange={formik.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputProps: { min: 0, step: "any" },
                    }}
                    variant="standard"
                    error={
                      formik.touched.abono1 && Boolean(formik.errors.abono1)
                    }
                    helperText={formik.touched.abono1 && formik.errors.abono1}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="abonoIva"
                    name="abonoIva"
                    label="Abono IVA"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.abonoIva}
                    onChange={formik.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputProps: { min: 0, step: "any" },
                    }}
                    variant="standard"
                    error={
                      formik.touched.abonoIva && Boolean(formik.errors.abonoIva)
                    }
                    helperText={
                      formik.touched.abonoIva && formik.errors.abonoIva
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="recibio"
                    name="recibio"
                    label="Recibió"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.recibio}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.recibio && Boolean(formik.errors.recibio)
                    }
                    helperText={formik.touched.recibio && formik.errors.recibio}
                    disabled={isDisabled}
                  />
                </div>

                <div className="col">
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="idtipoPago" shrink>
                      Tipo de Pago
                    </InputLabel>
                    <Select
                      labelId="idtipoPago"
                      id="idtipoPago"
                      disabled={isDisabled}
                      value={formik.values.idtipoPago}
                      label="Tipo de Pago"
                      onChange={(e) => {
                        formik.setFieldValue("idtipoPago", e.target.value);
                      }}
                    >
                      <MenuItem value={1}>Efectivo</MenuItem>
                      <MenuItem value={2}>Tarjeta</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <br />
                <br />
                <div className="col mt-2">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success w-100"
                    disabled={isDisabled}
                  >
                    Agregar Pago
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
