import React from "react";
import { BuscadorDeContratosParaPagoComponent } from "../BuscadorDeContratosParaPagoComponent/BuscadorDeContratosParaPagoComponent";

import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { getTopPagos, newContractPayment } from "../../API/ApiPagosContratos";
import { getDetailContract } from "../../API/ApiContratos";
import TicketPrevisionComponent from "./../TicketPrevisionComponent/TicketPrevisionComponent";
import ReactToPrint from "react-to-print";
import { imprimirTicket } from "./../../API/ApiImpresoraTickets";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DateTime } from "luxon";

const validationSchema = yup.object({
  serie: yup.string("Ingresa serie").required("Serie requerido"),
  folio: yup.string("Ingresa serie").required("Serie requerido"),
  noContrato: yup
    .string("Ingresa Número de contrato")
    .required("Número de contrato requerido"),
  monto: yup.string("Ingresa monto").required("Monto requerido"),
  idtipoPago: yup.string("Ingresa monto").required("Monto requerido"),
  fechaPago: yup.string("Ingresa fecha de pago").required("Fecha requerida"),
});

const getDateTime = () => {
  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd
  const currDate = defaultValue;
  return currDate;
};

export const AddPaymentsQuickComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const componentRef = React.useRef();

  const [numeroContratoEncontrado, setNumeroContratoEncontrado] =
    React.useState({});

  const [finalData, setFinalData] = React.useState([]);

  const [contadorPago, setContadorPago] = React.useState(0);

  const [isDisabled, setIsDisabled] = React.useState(false);

  const [finalDataDetalle, setFinalDataDetalle] = React.useState(0);

  const [dineroAAgregar, setDineroAAgregar] = React.useState(0);

  const [mostrarFolio, setMostrarFolio] = React.useState(false);

  React.useEffect(() => {
    getTopPagos(user).then((resultado) => {
      if (resultado.status === 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });
  }, []);

  React.useEffect(() => {
    //toast.info("Actualizando lista de pagos");
    getTopPagos(user).then((resultado) => {
      if (resultado.status === 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });
  }, [contadorPago]);
  React.useEffect(() => {
    // toast.info("Consultado saldo");

    getDetailContract(user, numeroContratoEncontrado.id).then((resultado) => {
      if (resultado.status == 200) {
        setFinalDataDetalle(resultado?.data?.etapaDos?.[0]?.saldo);
      } else {
        setFinalData(0);
      }
    });
  }, [numeroContratoEncontrado]);

  const formik = useFormik({
    initialValues: {
      serie: "A",
      folio: "0",
      noContrato: numeroContratoEncontrado.noContrato,
      monto: "",
      idtipoPago: "1",
      fechaPago: getDateTime(),
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      let fechaParaGuardar = DateTime.fromISO(values.fechaPago);

      let fechaActual = DateTime.now();

      if (fechaParaGuardar <= fechaActual) {
        setIsDisabled(true);
      } else {
        toast.info("Verifica la fecha de pago");
        toast.error(
          "No puedes ingresar fechas de pago posteriores a la fecha actual"
        );

        return;
      }

      
      setIsDisabled(true);

      toast.info("Guardando información de pago");
      values.folio = values.folio.toString();
      values.noContrato = values.noContrato.toString();

      newContractPayment(user, values).then((resultado) => {
        if (resultado.status == 200) {
          setIsDisabled(false);
          toast.success("Se ha agregado correctamernte el pago");

          formik.setFieldValue(
            "folio",
            (parseInt(values.folio) + 1).toString()
          );
          formik.setFieldValue("serie", values.serie.toString());
          formik.setFieldValue("fechaPago", values.fechaPago);

          var datosImpresion = {
            tipoImagen: 2,
            razonSocial: values.serie.toString() === "SM" ? 2 : 1,
            datosRecibo: [
              {
                nombre: "Restructura",
                valor: resultado.data.restructura === true ? "Si" : "No",
              },
              {
                nombre: "Fecha Pago",
                valor: resultado.data.fechaPago,
              },
              {
                nombre: "Folio Recibo",
                valor: resultado.data.serie + " " + resultado.data.folio,
              },
              {
                nombre: "No. Contrato",
                valor:
                  resultado.data.serieContrato +
                  " " +
                  resultado.data.noContrato,
              },
              {
                nombre: "Nombre Titular",
                valor: resultado.data.titular,
              },
              {
                nombre: "Dirección",
                valor: resultado.data.domicilio,
              },

              {
                nombre: "Saldo Anterior",
                valor:
                  "$ " +
                  parseFloat(resultado.data.saldoAnterior)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },

              {
                nombre: "Abono",
                valor:
                  "$ " +
                  parseFloat(resultado.data.abono)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },

              {
                nombre: "Saldo Actual",
                valor:
                  "$ " +
                  parseFloat(resultado.data.saldoActual)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },

              {
                nombre: "Método Pago",
                valor:
                  resultado.data.idtipoPago == 1
                    ? "Efectivo"
                    : resultado.data.idtipoPago == 2
                    ? "Tarjeta"
                    : "N/A",
              },

              resultado.data.atraso.result == true
                ? {
                    nombre: "Atraso",
                    valor:
                      "$ " +
                      parseFloat(resultado.data.atraso.cantidad)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                        .toString(),
                  }
                : null,
            ],
            datosCodigoBarra: "PREV-" + resultado.data.idcontrato,
            idpago: resultado.data.id,
          };

          imprimirTicket(datosImpresion).then((resultado) => {
            let valorSubir = contadorPago + 1;
            setContadorPago(valorSubir);
          });
        } else {
          toast.error("ha ocurrido un error");
          setIsDisabled(false);
        }
      });
    },
  });

  React.useEffect(() => {
    let ready = false;

    // toast.info("Agregando número de contrato a pago");

    formik.setFieldValue("idcontrato", numeroContratoEncontrado.id);
    formik.setFieldValue("noContrato", numeroContratoEncontrado.noContrato);

    return () => {
      ready = true;
    };
  }, [numeroContratoEncontrado]);

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className={props.ocultarPagos === true ? "col-sm-12" : "col-sm-4"}>
          <BuscadorDeContratosParaPagoComponent
            numeroContratoEncontrado={numeroContratoEncontrado}
            setNumeroContratoEncontrado={setNumeroContratoEncontrado}
          />
          <div className="container-fluid mt-3">
            <form className="row" onSubmit={formik.handleSubmit}>
              <div className="col-md-12 col-sm-12 bg-white mx-auto rounded-2 p-3">
                <div className="text-start p-2">
                  <h5 className="m-0 p-0">Agregar Pago</h5>
                  <hr className="p-0 m-0 mt-2 mb-2" />
                </div>
                <div className="contenido p-2">
                  {isDisabled == true ? (
                    <>
                      <Alert severity="info" className="my-3">
                        Estamos agregando el pago... espera un momento
                      </Alert>
                    </>
                  ) : null}
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          size="small"
                          fullWidth
                          variant="standard"
                          className="mb-2"
                          id="noContrato"
                          name="noContrato"
                          label="Número de Contrato:"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values.noContrato}
                          disabled={true}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.noContrato &&
                            Boolean(formik.errors.noContrato)
                          }
                          helperText={
                            formik.touched.noContrato &&
                            formik.errors.noContrato
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          size="small"
                          fullWidth
                          variant="standard"
                          className="mb-2"
                          id="fechaPago"
                          name="fechaPago"
                          type={"date"}
                          label="fechaPago: "
                          value={formik.values.fechaPago}
                          InputLabelProps={{ shrink: true }}
                          onChange={formik.handleChange}
                          disabled={isDisabled}
                          error={
                            formik.touched.fechaPago &&
                            Boolean(formik.errors.fechaPago)
                          }
                          helperText={
                            formik.touched.fechaPago && formik.errors.fechaPago
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            shrink={true}
                          >
                            Serie
                          </InputLabel>
                          <NativeSelect
                            value={formik.values.idcategoria}
                            onChange={(e) => {
                              let seleccionado = e.target.value;

                              if (
                                seleccionado === "A" ||
                                seleccionado === "C" ||
                                seleccionado === "P" ||
                                seleccionado === "M" ||
                                seleccionado === "SM"
                              ) {
                                setMostrarFolio(false);
                              } else {
                                setMostrarFolio(true);
                              }

                              formik.handleChange(e);
                            }}
                            defaultValue={"C"}
                            disabled={isDisabled}
                            inputProps={{
                              name: "serie",
                              id: "uncontrolled-native",
                            }}
                          >
                            <option value={"A"}>A</option>
                            <option value={"AA"}>AA</option>
                            <option value={"B"}>B</option>
                            <option value={"BB"}>BB</option>
                            <option value={"D"}>D</option>
                            <option value={"E"}>E</option>
                            <option value={"F"}>F</option>
                            <option value={"G"}>G</option>
                            <option value={"H"}>H</option>
                            <option value={"I"}>I</option>
                            <option value={"J"}>J</option>
                            <option value={"P"}>P</option>
                            <option value={"MA"}>MA</option>
                            <option value={"SM"}>SM</option>
                            <option value={"S"}>S</option>
                          </NativeSelect>
                        </FormControl>
                      </div>
                      <div className="col-md-6">
                        {mostrarFolio == true ? (
                          <TextField
                            size="small"
                            fullWidth
                            className={`mb-3 ${
                              mostrarFolio == true ? null : "d-none"
                            }`}
                            id="folio"
                            name="folio"
                            disabled={isDisabled}
                            type={"number"}
                            variant="standard"
                            label="Folio:"
                            value={formik.values.folio}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.folio &&
                              Boolean(formik.errors.folio)
                            }
                            helperText={
                              formik.touched.folio && formik.errors.folio
                            }
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          size="small"
                          fullWidth
                          className="mb-2"
                          id="monto"
                          name="monto"
                          disabled={isDisabled}
                          variant="standard"
                          type={"number"}
                          label="Monto: $"
                          value={formik.values.monto}
                          InputProps={{ inputProps: { min: 0, step: "any" } }}
                          onChange={(e) => {
                            var valor = e.target.value;

                            setDineroAAgregar(valor);
                            formik.handleChange(e);
                          }}
                          error={
                            formik.touched.monto && Boolean(formik.errors.monto)
                          }
                          helperText={
                            formik.touched.monto && formik.errors.monto
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 mt-1">
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="idtipoPago" shrink={true}>
                            Tipo de Pago
                          </InputLabel>
                          <Select
                            labelId="idtipoPago"
                            id="idtipoPago"
                            value={formik.values.idtipoPago}
                            label="Tipo de Pago"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "idtipoPago",
                                e.target.value
                              );
                            }}
                          >
                            <MenuItem value={1}>Efectivo</MenuItem>
                            <MenuItem value={2}>Tarjeta</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pie p-2">
                  <hr />
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button
                      className="btn btn-sm btn-outline-danger rounded-4"
                      type="button"
                      disabled={isDisabled}
                      onClick={() => {
                        props.setCrudAction({ action: null, data: null });
                      }}
                    >
                      Cerrar
                    </button>
                    <button
                      className="btn btn-sm btn-success rounded-4"
                      type="submit"
                      disabled={isDisabled}
                    >
                      Ingresar Pago
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-md-12 col-sm-12 bg-white mx-auto rounded-2 p-3">
                <h4>Informativo</h4>
                <strong> Saldo: $ {finalDataDetalle}</strong>
                <br />
                <strong>
                  Nuevo Saldo: $ {finalDataDetalle - dineroAAgregar}
                </strong>
                <br />
              </div>
            </div>
          </div>
        </div>

        {props.ocultarPagos === true ? null : (
          <>
            <div className="col-sm-8">
              <h5>Últimos pagos registrados</h5>
              <div className="container-fluid">
                <div className="row row-cols-md-3">
                  {finalData.map((pago, index) => (
                    <div className="col" key={index}>
                      <div className="card mb-2" key={index}>
                        <div className="card-body">
                          <strong>
                            {pago.serie} - {pago.folio}
                          </strong>
                          <br />

                          <strong>Contrato: {pago.noContrato}</strong>
                          <br />
                          <small>{pago.fechaPago}</small>
                          <br />
                          <span>$ {pago.monto}</span>
                          <br />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
