import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { searchContracts } from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";
import { toast } from "react-toastify";
import styles from "./BuscadorDeContratosParaPagoComponent.module.css";
import { useLocation } from "react-router-dom";
import { param } from "jquery";
export const BuscadorDeContratosParaPagoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);
  const [contratoInactivo, setContratoInactivo] = React.useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const parametro = queryParams.get("contrato");

  const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {
    if (parametro !== null) {
      setInputValue(parametro);

      searchContracts(user, parametro.slice(1)).then((resultado) => {
        if (resultado.status === 200) {
          setFinalData(resultado.data);
          toast.success("Selecciona el contrato en el buscador");
        } else {
          setFinalData([]);
        }
      });
    }
  }, []);

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={finalData}
        getOptionLabel={(option) =>
          `${option.serie} ${option.noContrato} -${option.titular}`
        }
        onInputChange={(event, option) => {
          if (option == "") {
            return;
          }

          searchContracts(user, event.target.value).then((resultado) => {
            if (resultado.status == 200) {
              setFinalData(resultado.data);
            } else {
              setFinalData([]);
            }
          });
        }}
        onChange={(event, option) => {
          if (option.status == "3") {
            toast.warning("Este contrato está inactivo.");
            setContratoInactivo(true);
          } else {
            setContratoInactivo(false);
          }

          props.setNumeroContratoEncontrado(option);
        }}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar Número de Contrato" />
        )}
      />

      {contratoInactivo == true ? (
        <>
          <div className={styles.buscadorInabilitados}>
            <div className={styles.buscadorInabilitadosCenter}>
              <h5>
                {" "}
                <strong>
                  {" "}
                  Este Contrato esta inhabilitado, <br /> Confirma la
                  información en Cobranza{" "}
                </strong>{" "}
              </h5>

              <br />
              <br />
              <div
                className="btn btn-success"
                onClick={() => {
                  setContratoInactivo(false);
                }}
              >
                Cerrar
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
