import React from "react";
import { BuscadorDeContratosParaPagoComponent } from "../BuscadorDeContratosParaPagoComponent/BuscadorDeContratosParaPagoComponent";

import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import {
  getTopPagos,
  getTopPagosMantenimiento,
  newContractPayment,
} from "../../API/ApiPagosContratos";
import { calcularMantenimientoContrato, getDetailContract } from "../../API/ApiContratos";

const validationSchema = yup.object({
  serie: yup.string("Ingresa serie").required("Serie requerido"),
  folio: yup.string("Ingresa serie").required("Serie requerido"),
  noContrato: yup
    .string("Ingresa Número de contrato")
    .required("Número de contrato requerido"),
  monto: yup
    .number("Valor Númerico")
    .positive("Valor Negativo")
    .required("Monto requerido")
    .min(1, "Solo números positivos"),
  fechaPago: yup.string("Ingresa fecha de pago").required("Fecha requerida"),
  justificacion: yup
    .string("Ingresa Justificación")
    .required("Justificación requerida"),
});

const getDateTime = () => {
  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd
  const currDate = defaultValue;
  return currDate;
};

export const AddPaymentsMantenimientoComponent = (props) => {
  console.log(props);
  const { user, dispatch } = React.useContext(AuthContext);

  const [numeroContratoEncontrado, setNumeroContratoEncontrado] =
    React.useState({});

  const [finalData, setFinalData] = React.useState([]);

  const [contadorPago, setContadorPago] = React.useState(0);

  const [isDisabled, setIsDisabled] = React.useState(false);

  const [finalDataDetalle, setFinalDataDetalle] = React.useState(0);

  const [dineroAAgregar, setDineroAAgregar] = React.useState(0);

  React.useEffect(() => {
    getTopPagos(user).then((resultado) => {
      if (resultado.status === 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });
  }, []);

  React.useEffect(() => {
    //toast.info("Actualizando lista de pagos");
    getTopPagosMantenimiento(user).then((resultado) => {
      if (resultado.status === 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });
  }, [contadorPago]);
  React.useEffect(() => {
    // toast.info("Consultado saldo");

    getDetailContract(user, numeroContratoEncontrado.id).then((resultado) => {
      if (resultado.status == 200) {
        setFinalDataDetalle(resultado.data.etapaDos[0].saldo);
      } else {
        setFinalData(0);
      }
    });
  }, [numeroContratoEncontrado]);

  const formik = useFormik({
    initialValues: {
      serie: "M",
      folio: "0",
      idano: props.catalogoPreciosFinalData[0]?.id,
      descuento: "0",
      justificacion: "",
      noContrato: numeroContratoEncontrado.noContrato,
      monto: "",
      fechaPago: getDateTime(),
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setIsDisabled(true);

      toast.info("Guardando información de pago");
      values.folio = values.folio.toString();
      values.noContrato = values.noContrato.toString();

      newContractPayment(user, values).then((resultado) => {
        if (resultado.status == 200) {
          setIsDisabled(false);
          toast.success("Se ha agregado correctamernte el pago");

          formik.setFieldValue(
            "folio",
            (parseInt(values.folio) + 1).toString()
          );
          formik.setFieldValue("serie", values.serie.toString());
          formik.setFieldValue("fechaPago", values.fechaPago);

          let valorSubir = contadorPago + 1;
          setContadorPago(valorSubir);
        } else {
          toast.error("ha ocurrido un error");
          setIsDisabled(false);
        }
      });
    },
  });

  React.useEffect(() => {
    let ready = false;

    // toast.info("Agregando número de contrato a pago");

    formik.setFieldValue("idcontrato", numeroContratoEncontrado.id);
    formik.setFieldValue("noContrato", numeroContratoEncontrado.noContrato);




    calcularMantenimientoContrato(user, numeroContratoEncontrado.id).then((resultado) => {
      if(resultado.status == 200)
      {
       
      }
    })


    return () => {
      ready = true;
    };
  }, [numeroContratoEncontrado]);

  React.useEffect(() => {
    //

    if (props.catalogoPreciosFinalData.length > 0) {
      formik.setFieldValue(
        "idano",
        props.catalogoPreciosFinalData[
          props.catalogoPreciosFinalData.length - 1
        ].id
      );
      formik.setFieldValue(
        "monto",
        props.catalogoPreciosFinalData[
          props.catalogoPreciosFinalData.length - 1
        ].precio
      );
    }
  }, [props.catalogoPreciosFinalData]);

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-sm-7">
          <BuscadorDeContratosParaPagoComponent
            numeroContratoEncontrado={numeroContratoEncontrado}
            setNumeroContratoEncontrado={setNumeroContratoEncontrado}
          />
          <div className="container-fluid mt-3">
            <form className="row" onSubmit={formik.handleSubmit}>
              <div className="col-md-12 col-sm-12 bg-white mx-auto rounded-2 p-3">
                <div className="text-start p-2">
                  <h5 className="m-0 p-0">Agregar Pago Mantenimiento</h5>
                  <hr className="p-0 m-0 mt-2 mb-2" />
                </div>
                <div className="contenido p-2">
                  {isDisabled == true ? (
                    <>
                      <Alert severity="info" className="my-3">
                        Estamos agregando el pago... espera un momento
                      </Alert>
                    </>
                  ) : null}
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          size="small"
                          fullWidth
                          variant="standard"
                          className="mb-2"
                          id="noContrato"
                          name="noContrato"
                          label="Número de Contrato:"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values.noContrato}
                          disabled={true}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.noContrato &&
                            Boolean(formik.errors.noContrato)
                          }
                          helperText={
                            formik.touched.noContrato &&
                            formik.errors.noContrato
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          size="small"
                          fullWidth
                          variant="standard"
                          className="mb-2"
                          id="fechaPago"
                          name="fechaPago"
                          type={"date"}
                          label="fechaPago: "
                          value={formik.values.fechaPago}
                          InputLabelProps={{ shrink: true }}
                          onChange={formik.handleChange}
                          disabled={isDisabled}
                          error={
                            formik.touched.fechaPago &&
                            Boolean(formik.errors.fechaPago)
                          }
                          helperText={
                            formik.touched.fechaPago && formik.errors.fechaPago
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl fullWidth shrink>
                          <InputLabel variant="standard" htmlFor="idano" shrink>
                            Año Pago
                          </InputLabel>
                          <NativeSelect
                            value={formik.values.idano}
                            shrink={true}
                            onChange={(e) => {
                              
                              var resultadoAnio = e.target.value;

                              var buscarTotal =
                                props.catalogoPreciosFinalData.find(
                                  (precio) => precio.id == resultadoAnio
                                );

                              formik.setFieldValue("monto", buscarTotal.precio);
                              formik.setFieldValue("idano", resultadoAnio);
                            }}
                            disabled={isDisabled}
                            inputProps={{
                              name: "idano",
                              id: "idano",
                              shrink: true,
                            }}
                          >
                            {props.catalogoPreciosFinalData.map((precio) => (
                              <option key={precio.id} value={precio.id}>
                                {precio.ano} - {precio.espacio}
                              </option>
                            ))}
                          </NativeSelect>
                        </FormControl>
                      </div>
                      <div className="col-md-6">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          fullWidth
                          className="mb-3"
                          id="descuento"
                          name="descuento"
                          disabled={isDisabled}
                          type={"number"}
                          variant="standard"
                          label="Descuento %:"
                          value={formik.values.descuento}
                          onChange={(e) => {
                            var hola = props.catalogoPreciosFinalData.find(
                              (precio) => precio.id == formik.values.idano
                            );

                            var resultadoMonto =
                              parseFloat(hola.precio) *
                              parseFloat(e.target.value);

                            resultadoMonto = resultadoMonto / 100;

                            resultadoMonto =
                              parseFloat(hola.precio) - resultadoMonto;

                            formik.setFieldValue("descuento", e.target.value);
                            formik.setFieldValue("monto", resultadoMonto);
                          }}
                          error={
                            formik.touched.descuento &&
                            Boolean(formik.errors.descuento)
                          }
                          helperText={
                            formik.touched.descuento && formik.errors.descuento
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            shrink={true}
                          >
                            Serie
                          </InputLabel>
                          <NativeSelect
                            value={formik.values.idcategoria}
                            onChange={formik.handleChange}
                            defaultValue={"M"}
                            disabled={isDisabled}
                            inputProps={{
                              name: "serie",
                              id: "uncontrolled-native",
                            }}
                          >
                            <option value={"M"}>M</option>
                          </NativeSelect>
                        </FormControl>
                      </div>
                      <div className="col-md-6">
                        <TextField
                          size="small"
                          fullWidth
                          className="mb-3"
                          id="folio"
                          name="folio"
                          disabled={isDisabled}
                          type={"number"}
                          variant="standard"
                          label="Folio:"
                          value={formik.values.folio}
                          InputProps={{ inputProps: { min: 0 } }}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.folio && Boolean(formik.errors.folio)
                          }
                          helperText={
                            formik.touched.folio && formik.errors.folio
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-12">
                          <TextField
                            size="small"
                            fullWidth
                            className="mb-3"
                            id="justificacion"
                            name="justificacion"
                            disabled={isDisabled}
                            type={"text"}
                            variant="standard"
                            label="Justificación:"
                            value={formik.values.justificacion}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.justificacion &&
                              Boolean(formik.errors.justificacion)
                            }
                            helperText={
                              formik.touched.justificacion &&
                              formik.errors.justificacion
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          size="small"
                          fullWidth
                          className="mb-2"
                          id="monto"
                          name="monto"
                          InputProps={{ inputProps: { min: 0, step: "any" } }}
                          
                          variant="standard"
                          type={"number"}
                          label="Monto: $"
                          value={formik.values.monto}
                          onChange={(e) => {
                            var valor = e.target.value;
                            setDineroAAgregar(valor);
                            formik.handleChange(e);
                          }}
                          error={
                            formik.touched.monto && Boolean(formik.errors.monto)
                          }
                          helperText={
                            formik.touched.monto && formik.errors.monto
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pie p-2">
                  <hr />
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button
                      className="btn btn-sm btn-outline-danger rounded-4"
                      type="button"
                      disabled={isDisabled}
                      onClick={() => {
                        props.setCrudAction({ action: null, data: null });
                      }}
                    >
                      Cerrar
                    </button>
                    <button
                      className="btn btn-sm btn-success rounded-4"
                      type="submit"
                      disabled={isDisabled}
                    >
                      Ingresar Pago
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-md-12 col-sm-12 bg-white mx-auto rounded-2 p-3">
                <h4>Informativo</h4>
                <strong> Saldo Contrato: $ {finalDataDetalle}</strong>

                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <h5>Últimos pagos registrados</h5>
          <div className="container-fluid">
            <div className="row row-cols-md-3">
              {finalData.map((pago, index) => (
                <div key={index} className="col">
                  <div className="card mb-2" key={index}>
                    <div className="card-body">
                      <strong>
                        {pago.serie} - {pago.folio}
                      </strong>
                      <br />

                      <strong>Contrato: {pago.noContrato}</strong>
                      <br />
                      <small>{pago.fechaPago}</small>
                      <br />
                      <span>$ {pago.monto}</span>
                      <br />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
