import React from "react";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";

export const ListadoHistoricoiContratoComponent = (props) => {
  return (
    <div className="container-fluid pt-3">
      <div className="row">
        <div className="col-12">
          <h5>Datos Históricos</h5>
        </div>
        <div className="col-12 pt-3">
          <div className="list-group">
            {props.finalData.etapaUno.map((informacion, index) => (
              <React.Fragment key={index}>
                <div
                  href="#"
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Fecha Orden de Servicio</small>
                    <p className="mb-1">
                      {CambiarFormatoFechaHelper(
                        informacion.hFechaOrdenServicio
                      )}
                    </p>
                  </div>
                </div>
                <div
                  href="#"
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Orden de servicio</small>
                    <p className="mb-1">{informacion.hOrdenServicio}</p>
                  </div>
                </div>
                <div
                  href="#"
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Población</small>
                    <p className="mb-1">{informacion.hPoblacion}</p>
                  </div>
                </div>
                <div
                  href="#"
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Servicios</small>
                    <p className="mb-1">{informacion.hServicios}</p>
                  </div>
                </div>
                <div
                  href="#"
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Status</small>
                    <p className="mb-1">{informacion.hstatus}</p>
                  </div>
                </div>
                <div
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Tipo Servicio</small>
                    <p className="mb-1">{informacion.htipoServicio}</p>
                  </div>
                </div>
              </React.Fragment>
            ))}
            {props.finalData.etapaDos.map((informacionDos, index) => (
              <React.Fragment key={index}>
                <div
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Vendedor</small>
                    <p className="mb-1">{informacionDos.hVendedor}</p>
                  </div>
                </div>
              </React.Fragment>
            ))}
            {props.finalData.etapaUno.map((informacion, index) => (
              <React.Fragment key={index}>
                <div
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Condición de pago</small>
                    <p className="mb-1">{informacion.hCondicionPago}</p>
                  </div>
                </div>
                <div
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Vet Cliente</small>
                    <p className="mb-1">{informacion.hCveTCliente}</p>
                  </div>
                </div>
                <div
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Fecha último pago</small>
                    <p className="mb-1">{informacion.hFechaUltimoPago}</p>
                  </div>
                </div>
                <div
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Restructura de pago</small>
                    <p className="mb-1">{informacion.hReestructura}</p>
                  </div>
                </div>

                <div
                  className="list-group-item list-group-item-action"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <small>Tipo de Pago</small>
                    <p className="mb-1">{informacion.htipoPago}</p>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
