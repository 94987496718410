import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

export const checkPermisosPorUsuarioPorGuidPantalla = async (
  user,
  guidvista
) => {
  var { id } = jwt_decode(user.accessToken);
  let url = API + `permission/checkPermisos/${id}/${guidvista}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const checkPermisosMenuPorUsuarioPorGuidPantalla = async (user) => {
  var { id } = jwt_decode(user.accessToken);
  let url = API + `permission/checkMenu/${id}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const checkPermisosAEditarPorUsuario = async (user, idusuario) => {
  var { id } = jwt_decode(user.accessToken);
  let url = API + `permission/getPermisosUsuario/${idusuario}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const setUnsetPermisoVistaUsuario = async (
  user,
  idUsuario,
  guidvista
) => {
  var { id } = jwt_decode(user.accessToken);
  let url = API + `permission/setPermisoVistaUsuario/${idUsuario}/${guidvista}`;

  try {
    const response = await axios.put(
      url,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
          maxBodyLength: Infinity,
        },
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const setUnsetPermisoFuncionUsuario = async (
  user,
  idUsuario,
  guidvista
) => {
  var { id } = jwt_decode(user.accessToken);
  let url =
    API + `permission/setPermisoFuncionUsuario/${idUsuario}/${guidvista}`;

  try {
    const response = await axios.put(
      url,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
          maxBodyLength: Infinity,
        },
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
