import { NuevaAPICobranza } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getDocumentosDeContrato = async (user, values) => {
  let url = NuevaAPICobranza + "prevision/obtenerDocumentosContrato/" + values;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const getDocumentosDeOrdenDeServicio = async (user, values) => {
  let url = NuevaAPICobranza + "orden/obtenerDocumentosOrden/" + values;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const postComentarioBitacoraDeContrato = async (user, values) => {
  let url = NuevaAPICobranza + "prevision/addRegistroBitacora";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const deleteComentarioBitacoraDeContrato = async (user, values) => {
  let url = NuevaAPICobranza + "prevision/eliminarRegistroComentario/" + values;
  try {
    const response = await axios.delete(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const postCargarDocumentosContrato = async (user, values) => {
  let url = NuevaAPICobranza + "prevision/cargarDocumentoContrato";
  try {
    const response = await axios.post(url, values, {
      headers: {
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const postCargarDocumentosOrdenDeServicio = async (user, values) => {
  let url = NuevaAPICobranza + "orden/cargarDocumentoOrden";

  
  try {
    const response = await axios.post(url, values, {
      headers: {
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const deleteEliminarDocumentoContrato = async (user, values) => {
  let url = NuevaAPICobranza + "prevision/eliminarDocumento/" + values;
  try {
    const response = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const deleteEliminarDocumentoOrdenDeServicio = async (user, values) => {
  let url = NuevaAPICobranza + "orden/eliminarDocumento/" + values;

  
  try {
    const response = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};
