import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import {
  agregarServicioAOrdenDeServicio,
  getServiciosOrdenesServicio,
} from "../../API/ApiServiciosOrdenesServicio";
import { nuevoPagoOrdenServicio } from "../../API/ApiPagosOrdenes";
import { agregarJardinAPanteon } from "../../API/ApiJardines";
import { AddPanteon } from "../../API/ApiPanteones";

const validationSchema = yup.object({
  nombre: yup.string("Nombre Nombre").required("Nombre Requerido"),
  idestado: yup.string("Nombre Estado").required("Estado Requerido"),
  idciudad: yup.string("Nombre Ciudad").required("Ciudad Requerida"),
  direccion: yup.string("Nombre Dirección").required("Dirección Requerida"),
});

export const AddPanteonComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [finalDataServicios, setFinalDataServicios] = React.useState([]);

  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);

  React.useEffect(() => {
    let listo = false;

    getEstados(user).then((resultado) => {
      if (resultado.status == 200) {
        setEstados(resultado.data);
      } else {
        setEstados([]);
      }
    });

    return () => {
      listo = true;
    };
  }, []);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      idestado: "",
      idciudad: "",
      direccion: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);
      AddPanteon(user, values).then((resultado) => {

        
        if (resultado.status == 200) {
          toast.success("Panteon Agregado");
          props.setCrudAction({ action: null, data: null });
        }
      });
    },
  });

  return (
    <>
      <div className="crudDivComponent col-md-3 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Agregar Ubicación</h6>
                  <small className="text-muted">
                    Ingresa los datos para agregar la ubicación
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row row-cols-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.nombre && Boolean(formik.errors.nombre)
                    }
                    helperText={formik.touched.nombre && formik.errors.nombre}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="direccion"
                    name="direccion"
                    label="Dirección"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.direccion}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.direccion &&
                      Boolean(formik.errors.direccion)
                    }
                    helperText={
                      formik.touched.direccion && formik.errors.direccion
                    }
                    disabled={isDisabled}
                  />
                </div>

                <div className="col">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      shrink={true}
                    >
                      Estado
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.idestado}
                      disabled={isDisabled}
                      defaultValue={30}
                      inputProps={{
                        name: "idestado",
                        id: "uncontrolled-native",
                      }}
                      onChange={(e) => {
                        formik.handleChange(e);
                        getMunicipios(user, e.target.value).then(
                          (resultado) => {
                            if ((resultado.status = 200)) {
                              setMunicipios(resultado.data);
                            } else {
                              setMunicipios([]);
                            }
                          }
                        );
                      }}
                    >
                      <option aria-label="Seleccionar Estado">
                        Seleccionar Estado
                      </option>

                      {estados.map((estado, index) => (
                        <option key={index} value={estado.idestado}>
                          {estado.nombreEstado}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>

                <div className="col mt-3">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      shrink={true}
                    >
                      Municipio
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.idciudad}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      defaultValue={30}
                      inputProps={{
                        name: "idciudad",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option aria-label="Seleccionar Ciudad">
                        Seleccionar Ciudad
                      </option>

                      {municipios.map((ciudad, index) => (
                        <option key={index} value={ciudad.idciudad}>
                          {ciudad.nombreCiudad}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>

                <div className="col mt-3">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success w-100"
                  >
                    Agregar Ubicación
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
