import React from "react";
import { Chart } from "react-google-charts";
import { AuthContext } from "../../auth/authContext";
import { getReporteVentasAnio } from "./../../API/ApiReportes";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { ButtonDownloadToXlsComponent } from "../ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";

export const ReportesDeContratoComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  const [finalData, setFinalData] = React.useState({ datos: [] });

  React.useEffect(() => {
    var listo = false;

    getReporteVentasAnio(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });

    return () => {
      listo = true;
    };
  }, []);

  return (
    <>
      <ButtonDownloadToXlsComponent
        data={finalData.datos}
        fileName="Reporte Total ventas por año"
      />
      <ul className="list-group list-group-flush">
        {finalData.datos.map((reporte, index) => (
          <li
            key={index}
            className="list-group-item d-flex justify-content-between align-items-center"
          >
            <span>
              <strong>
                {" "}
                {reporte.fechaContrato == null
                  ? "Sin Fecha"
                  : reporte.fechaContrato}{" "}
              </strong>{" "}
              - $ {reporte.totalContratos}
            </span>
            <span className="badge bg-primary rounded-pill">
              Contratos: {reporte.cantidadContratos}
            </span>

            <a
              href={`/reportes/reportetotalventaprevisionporfechas/${reporte.fechaContrato}-01-01/${reporte.fechaContrato}-12-31/1`}
              target="_self"
              rel="noreferrer"
              className="badge bg-success rounded-pill"
            >
              Abrir Reporte
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};
