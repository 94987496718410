import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getServiciosContrato = async (user) => {
    let url = API + "services/getServicios";
    try {
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
maxBodyLength: Infinity,
        },
      });
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  };