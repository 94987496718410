import { NuevaAPICobranza } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getObtenerNotificacionesUsuario = async (user, params) => {
  let url = NuevaAPICobranza + "notificaciones/obtenerNotificacionesToken";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
      params: params,
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const putDescartarNotificacion = async (user) => {
  let url =
    NuevaAPICobranza + "notificaciones/descartarNotificacion/" + user;
  try {
    const response = await axios.put(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};
