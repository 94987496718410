import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { DateTime } from "luxon";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { updateComisionOrdenesServicio } from "../../API/APiComisionOrdenesServicio";
//porcentajeComision

const validationSchema = yup.object({
  porcentajeComision: yup
    .string("Ingresa Porcentaje Comisión")
    .required("Comisión requerido"),
  montoComision: yup
    .string("Ingresa Monto Comisión")
    .required("Monto requerido"),
});

export const EditarComisionOrdenDeServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [montoComisionFinal, setMontoComisionFinal] = React.useState(0.0);

  const formik = useFormik({
    initialValues: {
      porcentajeComision: props.data.finalData.porcentajeComision,
      montoComision: props.data.finalData.montoComision,
      folio: props.data.finalData.folio,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      updateComisionOrdenesServicio(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Comisión actualizada correctamente");
        }

        setIsDisabled(false);
      });
    },
  });



  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <form className="container-fluid" onSubmit={formik.handleSubmit}>
        <div className="row row-cols-1">
          <div className="col">
            <TextField
              size="small"
              fullWidth
              id="porcentajeComision"
              name="porcentajeComision"
              label="Porcentaje Comisión"
              type={"number"}
              className="mb-3"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              value={formik.values.porcentajeComision}
              onChange={formik.handleChange}
              variant="standard"
              error={
                formik.touched.porcentajeComision &&
                Boolean(formik.errors.porcentajeComision)
              }
              helperText={
                formik.touched.porcentajeComision &&
                formik.errors.porcentajeComision
              }
              disabled={isDisabled}
            />
          </div>
          <div className="col">
            <TextField
              size="small"
              fullWidth
              id="montoComision"
              name="montoComision"
              label="Monto Comisión"
              type={"number"}
              className="mb-3"
              InputLabelProps={{ shrink: true }}
              value={formik.values.montoComision}
              onChange={formik.handleChange}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              error={
                formik.touched.montoComision &&
                Boolean(formik.errors.montoComision)
              }
              helperText={
                formik.touched.montoComision && formik.errors.montoComision
              }
              disabled={isDisabled}
            />
          </div>

          <div className="col">
            {props.data.editarComisionAOrdenServicio == true ? (
              <>
                <button
                  className="btn btn-sm btn-success w-100"
                  disabled={isDisabled}
                >
                  Actualizar Comisión
                </button>
              </>
            ) : null}
          </div>
        </div>
      </form>
    </>
  );
};
