import React from "react";
import { AuthContext } from "./../../auth/authContext";
import jwt_decode from "jwt-decode";
import { obtenerReporteZ } from "../../API/ApiPagosRegistrados";
import imagenVitaNova from "../../Images/LogotipoNegro.png";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getUsers } from "../../API/ApiUsers";
import Skeleton from "@mui/material/Skeleton";

import { DateTime } from "luxon";
import { reimprimirTicket } from "../../API/ApiImpresoraTickets";
import { toast } from "react-toastify";
const ReporteZScreenComponent = () => {
  const todayInicio = new Date();
  const defaultValueInicio = new Date(todayInicio).toISOString().split("T")[0]; // yyyy-mm-dd
  const defaultValueFin = new Date(todayInicio).toISOString().split("T")[0]; // yyyy-mm-dd
  const { user, dispatch } = React.useContext(AuthContext);
  var decoded = jwt_decode(user.accessToken);

  const [isloading, setIsLoading] = React.useState(true);
  const [fechaInicio, setFechaInicio] = React.useState(defaultValueInicio);
  const [fechaFin, setFechaFin] = React.useState(defaultValueFin);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = React.useState(
    decoded.id
  );

  const [usuarioSeleccionadoObject, setUsuarioSeleccionadoObject] =
    React.useState({});

  const [finalDataUsuarios, setFinalDataUsuarios] = React.useState([]);

  const [finalData, setFinalData] = React.useState({
    arregloUnificado: [],
    pagosOrdenes: [],
    pagoPrevision: [],
    totalPagosHoyOrdenes: 0,
    totalPagosHoyPevision: 0,
  });

  React.useEffect(() => {
    setIsLoading(true);
    obtenerReporteZ(user, fechaInicio, fechaFin, usuarioSeleccionado).then(
      (resultado) => {
        if (resultado.status == 200) {
          setFinalData(resultado.data);
          console.log(resultado.data);
          setIsLoading(false);
        }
      }
    );
  }, [fechaInicio, fechaFin, usuarioSeleccionado]);

  React.useEffect(() => {
    getUsers(user).then((resultado) => {
      if (resultado.status == 200) {
        let empleadoEncontrado = resultado.data.find(
          (empleado) => empleado.id === usuarioSeleccionado
        );
        setUsuarioSeleccionadoObject(empleadoEncontrado);
        setFinalDataUsuarios(resultado.data);
      } else {
        setFinalDataUsuarios([]);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div className="container-fluid mb-2 d-none d-print-block">
        <div className="row">
          <div className="col-12">
            <img src={imagenVitaNova} alt="Logo Vita Nova" height={30} />
          </div>
        </div>
      </div>
      <div className="container-fluid mb-2 d-none d-print-block">
        <div className="row">
          <div className="col-12">
            <img src={imagenVitaNova} alt="Logo Vita Nova" height={30} />
          </div>
        </div>
      </div>
      <div className="container-fluid mb-3 mt-2">
        <div className="row row-cols-md-3 d-flex align-items-center">
          <div className="col">
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              id="outlined-basic"
              label="Fecha Inicio"
              type="date"
              variant="standard"
              value={fechaInicio}
              onChange={(e) => {
                setFechaInicio(e.target.value);
              }}
            />
          </div>
          <div className="col">
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              id="outlined-basic"
              label="Fecha Fin"
              type="date"
              variant="standard"
              value={fechaFin}
              onChange={(e) => {
                setFechaFin(e.target.value);
              }}
            />
          </div>
          <div className="col d-print-none">
            <Autocomplete
              disablePortal
              id="seleccionarpersona"
              value={usuarioSeleccionadoObject}
              options={finalDataUsuarios}
              clearOnEscape={true}
              getOptionLabel={(option) => `${option.nombre}`}
              onInputChange={(event, option) => {}}
              onChange={(event, option) => {
                setUsuarioSeleccionadoObject(option);
                setUsuarioSeleccionado(option.id);
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus={true}
                  variant="standard"
                  size="small"
                  sx={{ fontSize: 12 }}
                  label="Buscar Persona"
                />
              )}
            />
          </div>
        </div>
      </div>
      {isloading === true ? (
        <>
          <div className="container-fluid mb-3 mt-3">
            <div className="row">
              <div className="col-sm-4">
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: "20%" }} />
                </Skeleton>
              </div>
              <div className="col-sm-4">
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: "20%" }} />
                </Skeleton>
              </div>
              <div className="col-sm-4">
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: "20%" }} />
                </Skeleton>
              </div>
            </div>
          </div>

          <div className="container-fluid mb-3 mt-3">
            <div className="row">
              <div className="col-sm-12">
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: "20%" }} />
                </Skeleton>
                <br />{" "}
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: "20%" }} />
                </Skeleton>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid mb-3 mt-3">
            <div className="row">
              <div className="col-sm-4">
                <div className="card">
                  <div className="card-body text-center">
                    <h5>Total pagado de Previsión y Mtto.</h5>
                    <h4>
                      ${" "}
                      {parseFloat(finalData.totalPagosHoyPevision)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="card">
                  <div className="card-body text-center">
                    <h5>Total pagado de órdenes</h5>
                    <h4>
                      ${" "}
                      {parseFloat(finalData.totalPagosHoyOrdenes)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="card">
                  <div className="card-body text-center">
                    <h5>Gran Total</h5>
                    <h4>
                      ${" "}
                      {parseFloat(
                        finalData.totalPagosHoyOrdenes +
                          finalData.totalPagosHoyPevision
                      )
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Id. Pago</th>
                      <th scope="col">Fecha Pago</th>
                      <th scope="col">Tipo Pago</th>
                      <th scope="col">Serie Pago</th>
                      <th scope="col">Folio</th>
                      <th scope="col">Monto</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalData.arregloUnificado.map((pago) => (
                      <React.Fragment key={pago.idpago}>
                        <tr>
                          <th>{pago.idpago}</th>
                          <th>
                            {" "}
                            {DateTime.fromISO(pago.createdAt).toFormat("f")}
                          </th>

                          <td>
                            {pago.idtipoPago === 1
                              ? "Efectivo"
                              : pago.idtipoPago === 2
                              ? "Tarjeta"
                              : "Desconocido"}
                          </td>
                          <td>{pago.seriePago}</td>
                          <td>{pago.folio}</td>
                          <td> $ {pago.monto}</td>
                          <td>
                            {pago.ticket == null ? (
                              <>
                                {" "}
                                <div
                                  className="btn btn-sm btn-danger"
                                  onClick={() => {
                                    toast.error(
                                      "Este ticket no fue generado en una terminal con la impresora configurada"
                                    );
                                  }}
                                >
                                  Ticket no disponible
                                </div>{" "}
                              </>
                            ) : (
                              <div
                                className="btn btn-sm btn-success"
                                onClick={() => {
                                  reimprimirTicket({
                                    idpago: pago.idpago,
                                  }).then((resultado) => {});
                                }}
                              >
                                Reimpresión de Ticket
                              </div>
                            )}
                          </td>
                        </tr>{" "}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ReporteZScreenComponent;
