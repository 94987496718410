import React from "react";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import styles from "./ComprobarLigaCambioContrasenaComponent.module.css";

import jwt_decode from "jwt-decode";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/types";

import vitaNovaLogo from "../../Images/logoVitanova.jpg";
import {
  apiGetVerificarString,
  apiPostRestablecerContrasena,
  apiRecoverPassword,
} from "../../API/ApiLoginUsers";
import { toast } from "react-toastify";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

const secretKey = "tribunal_de_justicia_administrativa_gto";

const validationSchema = yup.object({
  stringRecuperacion: yup.string("Requerido").required("Requerido"),
  password: yup
    .string()
    .min(8, "Mínimo 8 caracteres")
    .required("Contraseña Requerida"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir"),
});

export const ComprobarLigaCambioContrasenaComponent = () => {
  const [loading, setLoading] = React.useState(true);
  const [permisoParaCambiar, setPermisoParaCambiar] = React.useState(false);
  let { id } = useParams();

  React.useEffect(() => {
    apiGetVerificarString(id).then((resultado) => {
      if (resultado.status == 200) {
        setPermisoParaCambiar(true);
      } else {
        setPermisoParaCambiar(false);
      }

      setLoading(false);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      stringRecuperacion: id,
      password: "",
      passwordConfirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);

      delete values.passwordConfirm;
      
      apiPostRestablecerContrasena(values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success(
            "Contraseña modificada correctamente, ahora inicia sesión"
          );

          setTimeout(() => {
            window.open("/login", "_self");
          }, "2000");
        } else {
          toast.warning(
            "Ha ocurrido un problema al cambiar tu contraseña, informa al administrador"
          );
          setLoading(false);
        }
      });
    },
  });

  if (permisoParaCambiar == true) {
    return (
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className={styles.fondoLogin}></div>
        <div className="container animate__animated animate__fadeIn">
          <div className="row">
            <div className="col-12 col-sm-10 col-lg-5 mx-auto">
              <div className="card cardLogin" style={{ marginTop: "15vh" }}>
                <div className="card-body text-center">
                  <img
                    src={vitaNovaLogo}
                    alt="Logotipo Inicio"
                    style={{ width: "200px", marginTop: "3vh" }}
                  />
                  <div className="my-4">
                    <h5>Recuperar Contraseña</h5>
                    <h6 className="fw-normal">
                      {" "}
                      Ingresa tu correo de recuperación{" "}
                    </h6>
                  </div>
                  <form onSubmit={formik.handleSubmit} className="px-5">
                    <TextField
                      margin="normal"
                      size="small"
                      fullWidth
                      id="password"
                      type="password"
                      name="password"
                      label="Contraseña"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                    <TextField
                      margin="normal"
                      size="small"
                      fullWidth
                      id="passwordConfirm"
                      type="password"
                      name="passwordConfirm"
                      label="Confirmar Contraseña"
                      value={formik.values.passwordConfirm}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.passwordConfirm &&
                        Boolean(formik.errors.passwordConfirm)
                      }
                      helperText={
                        formik.touched.passwordConfirm &&
                        formik.errors.passwordConfirm
                      }
                    />

                    <div
                      className="small py-3"
                      style={{ textAlign: "justify" }}
                    >
                      <small className="text-muted">
                        Para recuperar tu contraseña te pedimos ingresar el
                        correo electrónico con el cuál fuiste dado de alta.
                      </small>
                    </div>

                    <button
                      className="my-4 btn btn-primary w-100 text-white"
                      type="submit"
                    >
                      Recuperar contraseña
                    </button>
                  </form>
                </div>
              </div>
              <div className="d-flex justify-content-between px-1 pt-2 text-muted small">
                <small style={{ color: "white" }}>Vita Nova Funeraria</small>
                <small>
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    href="/login"
                  >
                    Iniciar Sesión
                  </a>
                </small>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className={styles.fondoLogin}></div>
        <div className="container animate__animated animate__fadeIn">
          <div className="row">
            <div className="col-12 col-sm-10 col-lg-5 mx-auto">
              <div className="card cardLogin" style={{ marginTop: "15vh" }}>
                <div className="card-body text-center">
                  <img
                    src={vitaNovaLogo}
                    alt="Logotipo Inicio"
                    style={{ width: "200px", marginTop: "3vh" }}
                  />
                  <div className="my-4">
                    <h5>Recuperar Contraseña</h5>
                    <h6 className="fw-normal">
                      Problema con el link de recuperación
                    </h6>

                    <p className="mt-3 mb-3">
                      Ha habido un problema con la liga de recuperación, <br />
                      porfavor intenta generar una nueva.
                    </p>

                    <button
                      className="my-4 btn btn-primary w-100 text-white"
                      type="submit"
                      onClick={() => {
                        window.open("/restorepassword", "_self");
                      }}
                    >
                      Solicitar nuevo código
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between px-1 pt-2 text-muted small">
                <small style={{ color: "white" }}>Vita Nova Funeraria</small>
                <small>
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    href="/restorepassword"
                  >
                    Solicitar nuevo código
                  </a>
                </small>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
