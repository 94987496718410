import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { updateTitulares } from "../../API/ApiContratos";

const validationSchema = yup.object({
  titular1: yup.string("Ingresa Titular").required("Titular requerido"),
  domicilioTitular1: yup
    .string("Ingresa Domicilio")
    .required("Domicilio requerido"),
  telefonoTitular1: yup
    .string("Ingresa teléfono")
    .matches(/^[0-9]{5}[0-9]{5}$/, "Número Inválido")
    .required("Nombre requerido"),

  
});

export const TitularesDeContratoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      titular1: props.finalData.etapaUno[0].titular1,
      domicilioTitular1: props.finalData.etapaUno[0].domicilioTitular1,
      telefonoTitular1: props.finalData.etapaUno[0].telefonoTitular1,
      titular2: props.finalData.etapaUno[0].titular2,
      domicilioTitular2: props.finalData.etapaUno[0].domicilioTitular2,
      telefonoTitular2: props.finalData.etapaUno[0].telefonoTitular2,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      setIsDisabled(true);
      updateTitulares(user, values, props.finalData.etapaUno[0].id).then(
        (resultado) => {
          if (resultado.status == 200) {
            toast.success("Tus datos han sido actualizados correctamente");
          } else {
            toast.info("Ha habido un problema, intenta más tarde");
          }
        }
      );
      setIsDisabled(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="container-fluid mt-3">
      <div className="row">
        <div className="col-md-6">
          <div className="card mb-2">
            <div className="card-header">
              <strong>Titular 1</strong>
            </div>
            <div className="card-body">
              <TextField
                size="small"
                fullWidth
                id="titular1"
                name="titular1"
                label="Nombre Titular"
                type={"text"}
                className="mb-2"
                value={formik.values.titular1}
                onChange={formik.handleChange}
                variant="standard"
                error={
                  formik.touched.titular1 && Boolean(formik.errors.titular1)
                }
                helperText={formik.touched.titular1 && formik.errors.titular1}
                disabled={isDisabled}
              />
              <TextField
                size="small"
                fullWidth
                id="domicilioTitular1"
                name="domicilioTitular1"
                label="Domicilio"
                type={"text"}
                className="mb-2"
                value={formik.values.domicilioTitular1}
                onChange={formik.handleChange}
                variant="standard"
                error={
                  formik.touched.domicilioTitular1 &&
                  Boolean(formik.errors.domicilioTitular1)
                }
                helperText={
                  formik.touched.domicilioTitular1 &&
                  formik.errors.domicilioTitular1
                }
                disabled={isDisabled}
              />
              <TextField
                size="small"
                fullWidth
                id="telefonoTitular1"
                name="telefonoTitular1"
                label="Teléfono"
                type={"number"}
                className="mb-2"
                value={formik.values.telefonoTitular1}
                onChange={formik.handleChange}
                variant="standard"
                error={
                  formik.touched.telefonoTitular1 &&
                  Boolean(formik.errors.telefonoTitular1)
                }
                helperText={
                  formik.touched.telefonoTitular1 &&
                  formik.errors.telefonoTitular1
                }
                disabled={isDisabled}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <strong>Titular 2</strong>
            </div>
            <div className="card-body">
              <TextField
                size="small"
                fullWidth
                id="titular2"
                name="titular2"
                label="Nombre Titular 2"
                type={"text"}
                className="mb-2"
                value={formik.values.titular2}
                onChange={formik.handleChange}
                variant="standard"
                error={
                  formik.touched.titular2 && Boolean(formik.errors.titular2)
                }
                helperText={formik.touched.titular2 && formik.errors.titular2}
                disabled={isDisabled}
              />
              <TextField
                size="small"
                fullWidth
                id="domicilioTitular2"
                name="domicilioTitular2"
                label="Domicilio"
                type={"text"}
                className="mb-2"
                value={formik.values.domicilioTitular2}
                onChange={formik.handleChange}
                variant="standard"
                error={
                  formik.touched.domicilioTitular2 &&
                  Boolean(formik.errors.domicilioTitular2)
                }
                helperText={
                  formik.touched.domicilioTitular2 &&
                  formik.errors.domicilioTitular2
                }
                disabled={isDisabled}
              />
              <TextField
                size="small"
                fullWidth
                id="telefonoTitular2"
                name="telefonoTitular2"
                label="Teléfono"
                type={"number"}
                className="mb-2"
                value={formik.values.telefonoTitular2}
                onChange={formik.handleChange}
                variant="standard"
                error={
                  formik.touched.telefonoTitular2 &&
                  Boolean(formik.errors.telefonoTitular2)
                }
                helperText={
                  formik.touched.telefonoTitular2 &&
                  formik.errors.telefonoTitular2
                }
                disabled={isDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          {props.permisoParaEditar == true ? (
            <>
              <button
                className="my-4 btn btn-success w-100 text-white"
                type="submit"
                disabled={isDisabled}
              >
                Guardar cambios
              </button>
            </>
          ) : null}
        </div>
      </div>
    </form>
  );
};
