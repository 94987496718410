import React from "react";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
var listadoReportes = [
  {
    id: 1,
    nombreReporte: "Reporte ventas por año y tipo",
    url: "/reportes/reporteventas",
    active: true,
    tipo: "Previsión",
  },
  {
    id: 3,
    nombreReporte: "Reporte de Cartera",
    url: "/reportes/resumenserie",
    active: true,
    tipo: "Previsión",
  },
  {
    id: 4,
    nombreReporte: "Cobranza por fechas (Serie Gestor)",
    url: "/reportes/cobranzarangofecha",
    active: true,
    tipo: "Nómina",
  },
  {
    id: 5,
    nombreReporte: "Cubo comisiones servicio",
    url: "/reportes/cubocomisiones",
    active: false,
    tipo: "Nómina",
  },
  {
    id: 6,
    nombreReporte: "Cubo comisiones órdenes servicio",
    url: "/reportes/cubocomisionescontratos",
    active: false,
    tipo: "Nómina",
  },
  {
    id: 7,
    nombreReporte: "Nómina",
    url: "/reportes/reportenomina",
    active: false,
    tipo: "Nómina",
  },
  {
    id: 8,
    nombreReporte: "Reporte nómina quincenal (General, Particular)",
    url: "/reportes/reportenominasalario",
    active: true,
    tipo: "Nómina",
  },
  {
    id: 9,
    nombreReporte: "Comisiones de venta (previsión)",
    url: "/reportes/reportenominacomisionesprevision",
    active: true,
    tipo: "Previsión",
  },
  {
    id: 10,
    nombreReporte: "Comisiones de venta (órdenes de servicio)",
    url: "/reportes/reportenominacomisionesordenesservicio",
    active: true,
    tipo: "Órdenes de Servicio",
  },
  {
    id: 11,
    nombreReporte: "Órdenes de servicio (crédito - contado)",
    url: "/reportes/reporteordenesserviciocreditocontado",
    active: true,
    tipo: "Órdenes de Servicio",
  },
  {
    id: 12,
    nombreReporte: "Total venta prevision por fechas",
    url: "/reportes/reportetotalventaprevisionporfechas",
    active: true,
    tipo: "Previsión",
  },
  {
    id: 13,
    nombreReporte: "Total venta órdenes por fechas",
    url: "/reportes/reportetoralventaordenesporfechas",
    active: true,
    tipo: "Órdenes de Servicio",
  },
  {
    id: 14,
    nombreReporte: "Reporte de Ingresos",
    url: "/reportes/ingresos",
    active: false,
    tipo: "Generales",
  },
  {
    id: 15,
    nombreReporte: "Reporte de Pagos Mantenimiento",
    url: "/reportes/mantenimiento",
    active: true,
    tipo: "Espacios",
  },
  {
    id: 16,
    nombreReporte: "Reporte Concentrado de órdenes de servicio",
    url: "/reportes/concentradoordenesservicio",
    active: true,
    tipo: "Órdenes de Servicio",
  },
  {
    id: 17,
    nombreReporte: "Reporte de Pagos Órdenes de Servicio",
    url: "/reportes/pagosordenesservicio",
    active: true,
    tipo: "Órdenes de Servicio",
  },
  {
    id: 18,
    nombreReporte: "Reporte Z",
    url: "/reportes/reportez",
    active: true,
    tipo: "Generales",
  },
  {
    id: 19,
    nombreReporte: "Reporte Exhumaciones",
    url: "/reportes/reporteexhumaciones",
    active: true,
    tipo: "Espacios",
  },
];

export const ReportesAccesoScreenComponent = () => {
  const [tabsTextos, setTabsTextos] = React.useState([
    "Previsión",
    "Órdenes de Servicio",
    "Espacios",
    "Nómina",
    "Generales",
  ]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useState(() => {
    const tiposUnicos = [
      ...new Set(listadoReportes.map((report) => report.tipo)),
    ];
  }, []);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-3">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  {tabsTextos.map((tab, index) => (
                    <Tab label={tab} value={index} />
                  ))}
                </TabList>
              </Box>

              {tabsTextos.map((tab, index) => (
                <TabPanel value={index}>
                  <h5 className="m-0"> Reportes: {tab}</h5> <br />{" "}
                  {listadoReportes.map((reporte, index) => (
                    <React.Fragment key={reporte.id}>
                      {reporte.active === true && reporte.tipo === tab ? (
                        <>
                          <div className="col mb-2">
                            <div className="card">
                              <div className="card-body">
                                <ListAltIcon />
                                <h6 className="card-title">
                                  {reporte.nombreReporte}
                                </h6>

                                <a href={reporte.url} className="card-link">
                                  Ver Reporte
                                </a>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        false
                      )}
                    </React.Fragment>
                  ))}
                </TabPanel>
              ))}
            </TabContext>
          </div>
        </div>
      </div>
    </>
  );
};
