import React from "react";
import DataTable from "react-data-table-component";
import { AuthContext } from "../../auth/authContext";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
import {
  deleteEliminarDocumentoContrato,
  deleteEliminarDocumentoOrdenDeServicio,
} from "../../API/ApiDocumentosContrato";
import { toast } from "react-toastify";
import { LinearProgress } from "@mui/material";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";

const vistaOrdenes = "d5376ba2-cefd-4edd-8402-362d5bd4c75d";
const vistaContratos = "6ac593c2-4810-4081-bb29-e80830750a85";

const TablaDocumentosDeContratoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);

  const [
    permisoEliminarDocumentoContrato,
    setPermisoEliminarDocumentoContrato,
  ] = React.useState(0);

  const [permisoEliminarDocumentoOrden, setPermisoEliminarDocumentoOrden] =
    React.useState(0);

  const [
    permisoDescargarDocumentoContrato,
    setPermisoDescargarDocumentoContrato,
  ] = React.useState(0);

  const [permisoDescargarDocumentoOrden, setPermisoDescargarDocumentoOrden] =
    React.useState(0);

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, vistaOrdenes).then(
      (resultado) => {
        for (const permiso of resultado.data) {
          if (permiso.guidFuncion == "3918210e-90fd-4215-ac53-0de27866bc19") {
            setPermisoDescargarDocumentoOrden(permiso.allowed);
          }
          if (permiso.guidFuncion == "43772b0d-5406-4fb1-9c22-04b8c438b463") {
            setPermisoEliminarDocumentoOrden(permiso.allowed);
          }
        }
      }
    );

    checkPermisosPorUsuarioPorGuidPantalla(user, vistaContratos).then(
      (resultado) => {
        for (const permiso of resultado.data) {
          if (permiso.guidFuncion == "cf1bb018-40a8-456d-83c2-287e846be46b") {
            setPermisoDescargarDocumentoContrato(permiso.allowed);
          }
          if (permiso.guidFuncion == "3e8fb7cb-cbe6-4f15-82fe-0a30e95232a5") {
            setPermisoEliminarDocumentoContrato(permiso.allowed);
          }
        }
      }
    );
  }, []);

  const columns = [
    {
      name: "Tipo",
      selector: (row) => "Documento",
      sortable: true,
    },
    {
      name: "Tipo",
      selector: (row) => {


        return row.metadocumento.cdocumento.nombre
      } ,
      sortable: true,
    },
    {
      name: "Nombre Documento",
      selector: (row) => row.metadocumento.nombreArchivo,
      sortable: true,
    },
    {
      name: "Fecha",
      selector: (row) => CambiarFormatoFechaHelper(row.metadocumento.createdAt),
      sortable: true,
    },

    {
      name: "Descargar",
      selector: (row) => {
        if (
          (props.tipo == "Orden de Servicio" &&
            permisoDescargarDocumentoOrden == 1) ||
          (props.tipo == "Contrato" && permisoDescargarDocumentoContrato == 1)
        ) {
          return (
            <>
              <a
                className="btn btn-sm btn-success"
                href={
                  process.env.REACT_APP_API_URL_ENDPOINT_COBRANZA +
                  "prevision/descargarDocumento/" +
                  row.idmetaDocumento
                }
                target="_blank"
                rel="noreferrer"
              >
                Descargar
              </a>

              <div
                onClick={() => {
                  fetch(
                    process.env.REACT_APP_API_URL_ENDPOINT_COBRANZA +
                      "prevision/descargarDocumento/" +
                      row.idmetaDocumento
                  )
                    .then((response) => {
                      if (response.ok) return response.blob();
                      throw new Error("No se pudo descargar el archivo.");
                    })
                    .then((blob) => {
                      const url = window.URL.createObjectURL(blob);
                      // Intenta abrir en un popup
                      const popup = window.open(url, "Documento", "width=600,height=400");
                      if (!popup || popup.closed || typeof popup.closed == 'undefined') {
                        // El popup fue bloqueado o no se pudo abrir
                        alert("Popup bloqueado por el navegador. Por favor, permita popups para este sitio.");
                      }
                    })
                    .catch((error) => {
                      console.error("Error al descargar el archivo:", error);
                    });
                }}
                className="btn btn-sm btn-success ms-2"
                target="_blank"
                rel="noreferrer"
              >
                Ver Archivo
              </div>
            </>
          );
        } else {
          return null;
        }
      },
    },
    {
      name: "Eliminar",
      selector: (row) => {
        if (
          (props.tipo == "Orden de Servicio" &&
            permisoEliminarDocumentoOrden == 1) ||
          (props.tipo == "Contrato" && permisoEliminarDocumentoContrato == 1)
        ) {
          return (
            <>
              {props.tipo == "Orden de Servicio" ? (
                <>
                  <div
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      setLoading(true);
                      deleteEliminarDocumentoOrdenDeServicio(
                        user,
                        row.idmetaDocumento
                      ).then((resultado) => {
                        if (resultado.status == 200) {
                          toast.success("Documento eliminado");
                          props.setContador(props.contador + 1);
                          setLoading(false);
                        } else {
                          setLoading(false);
                          toast.error(
                            "Ocurrió un error al eliminar el documento"
                          );
                        }
                      });
                    }}
                  >
                    Eliminar
                  </div>
                </>
              ) : null}

              {props.tipo == "Contrato" ? (
                <>
                  <div
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      setLoading(true);
                      deleteEliminarDocumentoContrato(
                        user,
                        row.idmetaDocumento
                      ).then((resultado) => {
                        if (resultado.status == 200) {
                          toast.success("Documento eliminado");
                          props.setContador(props.contador + 1);
                          setLoading(false);
                        } else {
                          setLoading(false);
                          toast.error(
                            "Ocurrió un error al eliminar el documento"
                          );
                        }
                      });
                    }}
                  >
                    Eliminar
                  </div>
                </>
              ) : null}
            </>
          );
        } else {
          return null;
        }
      },
    },
  ];

  return (
    <div className="card">
      {loading == true ? <LinearProgress /> : null}
      <div className="card-body">
        <h6 className="m-0 p-0 mb-4">Listado de documentos</h6>
        <DataTable
          columns={columns}
          data={props.finalDocumentos}
          fixedHeader={true}
          fixedHeaderScrollHeight={"50vh"}
        />
      </div>
    </div>
  );
};

export default TablaDocumentosDeContratoComponent;
