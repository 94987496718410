import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { AuthContext } from "../../auth/authContext";
import {
  reporteCobranzaRangoFecha,
  reporteCuboComisionesContratos,
  reporteCuboComisionesOrdenes,
  reporteResumenSerie,
} from "../../API/ApiReportes";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import { BuscadorDeOrdenesDeServicioHookComponent } from "../../Components/BuscadorDeOrdenesDeServicioHookComponent/BuscadorDeOrdenesDeServicioHookComponent";
import { getAllEmpleadosParaSelect } from "../../API/ApiEmpleados";
import { BuscadorDeContratosParaPagoComponent } from "../../Components/BuscadorDeContratosParaPagoComponent/BuscadorDeContratosParaPagoComponent";
import { BuscadorDeContratosParaReporteComponent } from "../../Components/BuscadorDeContratosParaReporteComponent/BuscadorDeContratosParaReporteComponent";

const guidVista = "ef433e95-e3b0-4caa-a533-3b599add112e";

export const ReporteCuboComisionesContratosScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [reporteSerie, setReporteSerie] = React.useState("");
  const [reporteFechaInicial, setReporteFechaInicial] = React.useState("");
  const [reporteFechaFin, setReporteFechaFin] = React.useState("");
  const [numeroOrdenServicio, setNumeroOrdenServicio] = React.useState("");
  const [finalData, setFinalData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoReporte, setPermisoReporte] = React.useState(false);
  const [idempleadoFound, setIdempleadoFound] = React.useState("");
  const [granTotalComisionMostrar, setGranTotalComisionMostrar] =
    React.useState(0.0);

  const [granTotalTotalMostrar, setGranTotalTotalMostrar] = React.useState(0.0);

  const [listadoEmpleados, setListadoEmpleados] = React.useState([]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "1f61010e-02dd-41ba-87e4-3d52eefce087" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
            if (
              permiso.guidFuncion == "1f61010e-02dd-41ba-87e4-3d52eefce087" &&
              permiso.allowed == 1
            ) {
              setPermisoReporte(true);
            }
          }
        }
      }
    );

    let listo = false;

    let granTotalComision = 0.0;
    let granTotaltotal = 0.0;

    for (const serie of finalData) {
      if (parseFloat(serie.TOTAL) >= 0 && parseFloat(serie.TOTAL) <= 69000) {
        serie.porcentaje = 5;
        serie.TotalComision = parseFloat(serie.TOTAL * 5) / 100;
        granTotalComision = granTotalComision + serie.TotalComision;
        granTotaltotal = granTotaltotal + parseFloat(serie.TOTAL);
      }
      if (
        parseFloat(serie.TOTAL) >= 70000 &&
        parseFloat(serie.TOTAL) <= 99999
      ) {
        serie.porcentaje = 6;
        serie.TotalComision = parseFloat(serie.TOTAL * 6) / 100;
        granTotalComision = granTotalComision + serie.TotalComision;
        granTotaltotal = granTotaltotal + parseFloat(serie.TOTAL);
      }
      if (parseFloat(serie.TOTAL) >= 10000) {
        serie.porcentaje = 7;
        serie.TotalComision = parseFloat(serie.TOTAL * 7) / 100;
        granTotalComision = granTotalComision + serie.TotalComision;
        granTotaltotal = granTotaltotal + parseFloat(serie.TOTAL);
      }
    }
    setGranTotalTotalMostrar(granTotaltotal);
    setGranTotalComisionMostrar(granTotalComision);
    return () => {
      listo = true;
    };

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    let listo = false;

    let granTotalComision = 0.0;
    let granTotaltotal = 0.0;

    for (const serie of finalData) {
      granTotaltotal = granTotaltotal + parseFloat(serie.comisionImporte);
    }
    setGranTotalTotalMostrar(granTotaltotal);
    setGranTotalComisionMostrar(granTotalComision);
    return () => {
      listo = true;
    };
  }, [finalData]);

  React.useEffect(() => {
    let ready = false;

    getAllEmpleadosParaSelect(user).then((resultado) => {
      if (resultado.status == 200) {
        setListadoEmpleados(resultado.data);
      } else {
        setListadoEmpleados([]);
      }
    });

    return () => {
      ready = true;
    };
  }, []);

  return (
    <>
      {permisoParaVisualizar == true && permisoReporte == true ? (
        <>
          <div className="container-fluid mb-3 mt-2">
            <div className="row row-cols-md-3 d-flex align-items-center">
              <div className="col mb-2">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Inicio"
                  type="date"
                  variant="standard"
                  value={reporteFechaInicial}
                  onChange={(e) => {
                    setReporteFechaInicial(e.target.value);
                  }}
                />
              </div>
              <div className="col mb-2">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Fin"
                  type="date"
                  variant="standard"
                  value={reporteFechaFin}
                  onChange={(e) => {
                    setReporteFechaFin(e.target.value);
                  }}
                />
              </div>
              <div className="col mb-2">
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    htmlFor="uncontrolled-native"
                    InputLabelProps={{ shrink: true }}
                    shrink
                  >
                    Empleado
                  </InputLabel>
                  <NativeSelect
                    InputLabelProps={{ shrink: true }}
                    value={idempleadoFound}
                    shrink
                    onChange={(e) => {
                      setIdempleadoFound(e.target.value);
                    }}
                    inputProps={{
                      name: "age",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={""}>Todos</option>

                    {listadoEmpleados.map((empleado) => (
                      <option key={empleado.id} value={empleado.id}>
                        {empleado.nombreEmpleado}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </div>

              <div className="col mb-2">
                <BuscadorDeContratosParaReporteComponent
                  numeroOrdenServicio={numeroOrdenServicio}
                  setNumeroContratoEncontrado={setNumeroOrdenServicio}
                />
              </div>
              <div className="col mb-2">
                <button
                  className="btn btn-sm btn-success w-100"
                  onClick={() => {
                    setLoading(true);
                    reporteCuboComisionesContratos(user, {
                      noContrato: numeroOrdenServicio,
                      fechaInicial: reporteFechaInicial,
                      fechaFin: reporteFechaFin,
                      idEmpleado: idempleadoFound,
                    }).then((resultado) => {
                      if (resultado.status == 200) {
                        setFinalData(resultado.data);
                      }
                      setLoading(false);
                    });
                  }}
                >
                  {" "}
                  Buscar{" "}
                </button>
              </div>
            </div>
          </div>

          <button className="btn btn-sm btn-success" onClick={() => {
            window.print();
          }}>
            Imprimir
          </button>
          <div className="container-fluid">
            <div className="row row-cols-1">
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre Empleado</TableCell>
                      <TableCell>Contrato</TableCell>
                      <TableCell>Porcentaje</TableCell>
                      <TableCell>Observaciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {finalData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{row.nombreEmpleado}</TableCell>
                        <TableCell align="left">{row.noContrato}</TableCell>
                        <TableCell align="left">
                          {row.comisionPorcentaje + " %"}{" "}
                        </TableCell>

                        <TableCell align="left">
                          $
                          {parseFloat(row.comisionImporte)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>
                        <strong>Gran Total</strong>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <strong>
                          {" "}
                          {"$ "}{" "}
                          {granTotalTotalMostrar
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="container text-center d-none d-print-block">
                <br />
                <br />
                <br />
                <br />
                __________________________________________________________________
                <br />
                Firma
                <br />
                <small>Impreso por: {user.username}</small>
              </div>
            </div>
          </div>
          {loading == true ? <LoadingComponent /> : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
