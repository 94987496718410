import React from "react";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
import {
  deleteComentarioBitacoraDeContrato,
  deleteComentarioBitacoraDeOrdenDeServicio,
} from "../../API/ApiBitacora";
import { AuthContext } from "../../auth/authContext";
import { toast } from "react-toastify";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import CambiarFormatoFechaConHoraHelper from "../../Helpers/CambiarFormatoFechaConHoraHelper";
const guidVista = "6ac593c2-4810-4081-bb29-e80830750a85";

const guidVistaDos = "d5376ba2-cefd-4edd-8402-362d5bd4c75d";

const ListadoDeBitacoraComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const [checkPersmissionsDos, setCheckPermissionsDos] = React.useState([]);

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);
        } else {
          setCheckPermissions([]);
        }
      }
    );

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVistaDos).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissionsDos(resultado.data);
        } else {
          setCheckPermissionsDos([]);
        }
      }
    );
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              {props.listado.map((comentario) => {
                console.log(comentario);
                const text = comentario.comentario;

                // Expresión regular
                const pattern = /@\[(.*?)\]\(.*?\)/g;

                // Buscar todas las coincidencias

                let modifiedText = text;
                const matches = [];
                let match;
                while ((match = pattern.exec(text)) !== null) {
                  matches.push(match[1]);
                  modifiedText = modifiedText.replace(match[0], "");
                }

                return (
                  <>
                    <div
                      className="col-12 py-2 border-bottom"
                      style={
                        comentario.esCreador == true
                          ? { textAlign: "right" }
                          : null
                      }
                    >
                      <strong>
                        {" "}
                        <i> {comentario.usuariossistema.nombre}</i>
                      </strong>
                      <br />

                      {comentario.createdAt.includes("Z")
                        ? CambiarFormatoFechaConHoraHelper(comentario.createdAt)
                        : comentario.createdAt}

                      <br />
                      {modifiedText}
                      <div>
                        {matches.length > 0 ? (
                          <>
                            {" "}
                            <strong> Etiquetas: </strong>
                            <i>
                              {matches.map((personaEtiquetada, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {personaEtiquetada}
                                  </React.Fragment>
                                );
                              })}
                            </i>
                          </>
                        ) : (
                          <>
                            <strong> Sin Etiquetas </strong>
                          </>
                        )}
                      </div>

                      <div>
                        {(checkPersmissions?.find(
                          (valorBuscar) =>
                            valorBuscar.guidFuncion ==
                            "c4f46702-eac1-454d-a7a3-dfd781e67177"
                        )?.allowed === 1 &&
                          props.tipo == "Contrato") ||
                        (checkPersmissionsDos?.find(
                          (valorBuscar) =>
                            valorBuscar.guidFuncion ==
                            "3803b71f-c7be-43c3-a6d9-5115a80484dc"
                        )?.allowed === 1 &&
                          props.tipo == "Orden de Servicio") ? (
                          <>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                if (props.tipo == "Contrato") {
                                  deleteComentarioBitacoraDeContrato(
                                    user,
                                    comentario.id
                                  ).then((resultado) => {
                                    if (resultado.status == 200) {
                                      toast.success("Comentario eliminado");
                                      props.setContador(props.contador + 1);
                                    } else {
                                      toast.error(
                                        "Ha ocurrido un error al eliminar el comentario, intenta nuevamente."
                                      );
                                    }
                                  });
                                }
                                if (props.tipo == "Orden de Servicio") {
                                  deleteComentarioBitacoraDeOrdenDeServicio(
                                    user,
                                    comentario.id
                                  ).then((resultado) => {
                                    if (resultado.status == 200) {
                                      toast.success("Comentario eliminado");
                                      props.setContador(props.contador + 1);
                                    } else {
                                      toast.error(
                                        "Ha ocurrido un error al eliminar el comentario, intenta nuevamente."
                                      );
                                    }
                                  });
                                }
                              }}
                            >
                              Borrar
                            </button>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListadoDeBitacoraComponent;
