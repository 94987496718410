import React from "react";
import { BusquedaDePagosPorContratoComponent } from "../../Components/BusquedaDePagosPorContratoComponent/BusquedaDePagosPorContratoComponent";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { ButtonDownloadToXlsComponent } from "../../Components/ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";
import DataTable from "react-data-table-component";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
import { toast } from "react-toastify";

const columns = [
  {
    name: "Recibo",
    selector: (row) => row.serie + " - " + row.folio,
    sortable: true,
  },
  {
    name: "Contrato",
    selector: (row) => (
      <>
        <a href={`contrato/${row.idcontrato}/false`}>
          Contrato: {row.noContrato}
        </a>
      </>
    ),
    sortable: true,
  },
  {
    name: "Fecha",
    selector: (row) => CambiarFormatoFechaHelper(row.fechaPago),
    sortable: true,
  },
  {
    name: "Folio",
    selector: (row) => row.folio,
    sortable: true,
  },

  {
    name: "Monto",
    selector: (row) =>
      "$ " +
      parseFloat(row.monto)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
    sortable: true,
  },
];

export const BusquedaDePagosScreenComponent = () => {
  const [finalDataCompleto, setFinalDataCompleto] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);

  const [filtroSeleccionado, setFiltroSeleccionado] = React.useState("All");

  React.useEffect(() => {
    if (filtroSeleccionado !== "All") {
      let valoresFiltrados = finalDataCompleto.filter(
        (valor) => valor.serie == filtroSeleccionado
      );
      setFinalData(valoresFiltrados);
    } else {
      setFinalData(finalDataCompleto);
    }
  }, [filtroSeleccionado]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mt-3 mb-3">
            <BusquedaDePagosPorContratoComponent
              finalData={finalData}
              setFinalData={setFinalData}
              setFinalDataCompleto={setFinalDataCompleto}
              filtroSeleccionado={filtroSeleccionado}
              setFiltroSeleccionado={setFiltroSeleccionado}
            />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row row-cols-md-1">
          <div className="col" style={{ textAlign: "right" }}>
            <ButtonDownloadToXlsComponent
              data={finalData}
              fileName="Buscador de recibod"
            />
          </div>
        </div>
                  
      </div>

      {filtroSeleccionado == "All" ? (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 px-2">
                <DataTable columns={columns} data={finalData} />
              </div>
            </div>
          </div>

          <div className="container-fluid d-none">
            <div className="row">
              <div className="col-12 px-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Número de Recibo</th>
                      <th scope="col">Contrato</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalData.map((pago, index) => (
                      <tr key={index}>
                        <th scope="row">
                          {pago.serie} - {pago.folio}
                        </th>
                        <td>
                          <a href={`contrato/${pago.idcontrato}/false`}>
                            Contrato: {pago.noContrato}
                          </a>
                        </td>
                        <td>{pago.fechaPago}</td>
                        <td>
                          {" "}
                          {"$ "}
                          {parseFloat(pago.monto)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 px-2">
                <DataTable columns={columns} data={finalData} />
              </div>
            </div>
          </div>

          <div className="container-fluid d-none">
            <div className="row">
              <div className="col-12 px-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Número de Recibo</th>
                      <th scope="col">Contrato</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalData.map((pago, index) => (
                      <React.Fragment key={index}>
                        {pago.serie == filtroSeleccionado ? (
                          <React.Fragment>
                            <tr key={index}>
                              <th scope="row">
                                {pago.serie} - {pago.folio}
                              </th>
                              <td>
                                <a href={`contrato/${pago.idcontrato}/false`}>
                                  Contrato: {pago.noContrato}
                                </a>
                              </td>
                              <td>{pago.fechaPago}</td>
                              <td>
                                {" "}
                                {"$ "}
                                {parseFloat(pago.monto)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </td>
                            </tr>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};
