import React, { useRef } from "react";
import {
  getDetailContract,
  postGenerarEstadoDeCuentaAPI,
} from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";
import { BuscadorDeContratosParaReporteComponent } from "../../Components/BuscadorDeContratosParaReporteComponent/BuscadorDeContratosParaReporteComponent";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import imagenVita from "../../Images/logoVitanova.jpg";

import { useReactToPrint } from "react-to-print";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
import { BuscadorDeContratosParaReporteComponentEstadoDeCuenta } from "../../Components/BuscadorDeContratosParaReporteComponent/BuscadorDeContratosParaReporteComponentEstadoDeCuenta";
import imageVitanova from "../../Images/LogotipoNegro.png";
import imagePublicidadVitanova from "../../Images/informacionPublicidad.png";

import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { DateTime } from "luxon";
import SeleccionaUnContratoComponent from "../SeleccionaUnContratoComponent/SeleccionaUnContratoComponent";

// Colores para cada segmento
const COLORS = ["#156082", "#e97132"];

const renderCustomizedLabel = ({ percent }) => `${(percent * 100).toFixed(0)}%`;

export const EstadoDeCuentaContratosDosScreenComponent = () => {
  const [data, setData] = React.useState([
    { name: "Porcentaje pagado", value: 0 },
    { name: "Porcentaje por pagar", value: 100 },
  ]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { user, dispatch } = React.useContext(AuthContext);

  const [datosdeContrato, setDatosDeContrato] = React.useState({
    etapaUno: [],
    etapaDos: [],
  });

  const [numeroContratoEncontrado, setNumeroContratoEncontrado] =
    React.useState(0);

  const [finalData, setFinalData] = React.useState({
    etapaUno: [],
    etapaDos: [],
    espacios: [],
    comisiones: [],
    pagos: [],
  });

  const [totalPagosFinal, setTotalPagosFinal] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const [mostrarRecibo, setMostrarRecibo] = React.useState(false);

  React.useEffect(() => {
    let listo = false;
    setLoading(true);
    let TotalPagos = 0;

    if (numeroContratoEncontrado == 0) {
      setLoading(false);
      return;
    }

    setMostrarRecibo(true);
    postGenerarEstadoDeCuentaAPI(user, {
      idcontrato: numeroContratoEncontrado,
    }).then((resultado) => {
      if (resultado.status == 200) {
        setLoading(false);
        setMostrarRecibo(true);
        const porcentajePagado = parseFloat(
          resultado.data.porcentajePagado.replace("%", "")
        );

        const porcentajePorPagar = parseFloat(100 - porcentajePagado);

        debugger;

        setData([
          { name: "Porcentaje pagado", value: porcentajePagado },
          { name: "Porcentaje por pagar", value: porcentajePorPagar },
        ]);

        setFinalData(resultado.data);
      }
      setLoading(false);
    });

    return () => {
      listo = true;
    };
  }, [numeroContratoEncontrado]);

  return (
    <>
      {loading == true ? (
        <LoadingComponent />
      ) : (
        <>
          <div className="container-fluid d-print-none" ref={componentRef}>
            <div className="row">
              <div className="col-12 mt-3">
                <BuscadorDeContratosParaReporteComponentEstadoDeCuenta
                  numeroContratoEncontrado={numeroContratoEncontrado}
                  setNumeroContratoEncontrado={setNumeroContratoEncontrado}
                  datosdeContrato={datosdeContrato}
                  setDatosDeContrato={setDatosDeContrato}
                />
              </div>
            </div>
          </div>

          {mostrarRecibo == true ? (
            <>
              <div className="container-fluid d-print-none">
                <div className="row text-end">
                  <div className="col-sm-12 mt-5">
                    <button
                      className="btn btn-sm btn-success"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      Imprimir
                    </button>
                  </div>
                </div>
              </div>

              {/* Aqui comienza el estado de cuenta */}
              <div className="container-fluid mt-5">
                <div className="row">
                  <div className="col-12">
                    <div className=" mx-0">
                      <div className="card-body p-3 p-md-4">
                        <div>
                          <div className="row mb-4">
                            <div className="col-6 text-center">
                              <br />
                              <br />
                              <br />
                              <br />
                              <img
                                height={80}
                                src={imageVitanova}
                                alt="NombreVitanova"
                              />
                              <h2 className="h5 fw-bold mt-2">
                                ESTADO DE CUENTA
                              </h2>
                              <p className="mb-0">
                                Fecha:{" "}
                                {new Date().toLocaleDateString("es-ES", {
                                  day: "2-digit",
                                  month: "long",
                                  year: "numeric",
                                })}{" "}
                              </p>
                            </div>
                            <div className="col-1"></div>
                            <div className="col-4 text-center">
                              <br />
                              <br />
                              <br />
                              <table className="table" style={{ zoom: 0.97 }}>
                                <tbody>
                                  <tr className=" align-items-center">
                                    <td
                                      className=" text-end"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      NOMBRE:
                                    </td>
                                    <td className="text-start">
                                      {finalData?.titular}
                                    </td>
                                  </tr>
                                  <tr className=" align-items-center">
                                    <td
                                      className=" text-end"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      DOMICILIO:
                                    </td>
                                    <td className="text-start">
                                      {finalData?.domicilio}{" "}
                                      {finalData?.colonia}
                                      {", "}
                                      CP: {finalData?.cp}{" "}
                                    </td>
                                  </tr>
                                  <tr className=" align-items-center">
                                    <td
                                      className=" text-end"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      SERVICIO ADQUIRIDO:
                                    </td>
                                    <td className="text-start">
                                      {
                                        finalData?.detalleContrato?.servicio
                                          ?.nombre
                                      }
                                    </td>
                                  </tr>
                                  <tr className=" align-items-center">
                                    <td
                                      className=" text-end"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      CONTRATO:
                                    </td>
                                    <td className="text-start">
                                      {finalData?.serie}-{finalData?.noContrato}
                                    </td>
                                  </tr>
                                  <tr className=" align-items-center">
                                    <td
                                      className="text-end"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      SERIE GESTOR:
                                    </td>
                                    <td className="text-start">
                                      {finalData?.detalleContrato
                                        ?.detalleContrato == null
                                        ? "SIN SERIE"
                                        : finalData?.detalleContrato
                                            ?.detalleContrato?.serieGestor}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="col-1"></div>
                          </div>

                          <div className="row mb-4">
                            <div className="col-12">
                              <table class="table table-hover table-bordered table-sm">
                                <thead
                                  style={{
                                    background: "#d38831",
                                    color: "white",
                                  }}
                                >
                                  <tr>
                                    <th colspan="3" className=" text-center">
                                      DETALLES DE CUENTA
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className=" py-2">
                                      Fecha del contrato:{" "}
                                      {DateTime.fromISO(
                                        finalData?.fechaContrato
                                      ).toFormat("dd-MM-yyyy")}
                                    </td>
                                    <td className=" py-2">
                                      {" "}
                                      Financiamiento:{" "}
                                      {finalData?.detalleContrato?.plazo} meses
                                    </td>
                                    <td className=" py-2">
                                      {" "}
                                      Pagos realizados:{" "}
                                      {finalData?.pagos?.length}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className=" py-2">
                                      Costo previsión:{" "}
                                      {finalData?.importeContrato}
                                    </td>
                                    <td className=" py-2">
                                      {" "}
                                      Abono a capital: {finalData.pagadoHoy}
                                    </td>
                                    <td className=" py-2">
                                      {" "}
                                      Saldo pendiente: {finalData.porPagar}
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th
                                      colspan="3"
                                      className=" text-center"
                                      style={{
                                        color: "red",
                                        fontSize: "1.2em",
                                      }}
                                    >
                                      Atraso: {finalData?.montoAtraso}
                                    </th>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>

                          <div className="row hide-on-screen">
                            <div className="col-6">
                              <PieChart width={400} height={400}>
                                <Pie
                                  data={data}
                                  cx="50%"
                                  cy="50%"
                                  innerRadius={90}
                                  outerRadius={150}
                                  fill="#8884d8"
                                  dataKey="value"
                                  label={renderCustomizedLabel}
                                >
                                  {data.map((entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={COLORS[index % COLORS.length]}
                                    />
                                  ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                              </PieChart>
                            </div>
                            <div className="col-6">
                              <img
                                src={imagePublicidadVitanova}
                                alt="PublicidadVitanova"
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <table className="table table-bordered mt-5">
                                <thead
                                  style={{
                                    background: "#d38831",
                                    color: "white",
                                  }}
                                >
                                  <tr
                                    style={{
                                      background: "#d38831",
                                      color: "white",
                                    }}
                                  >
                                    <th className=" text-center">Recibo</th>
                                    <th className=" text-center">Abono</th>
                                    <th className=" text-center">
                                      Fecha de pago
                                    </th>
                                    <th className=" text-center">
                                      Saldo restante
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {finalData?.pagos?.map((pago, index) => {
                                    return (
                                      <tr
                                        className=" text-center align-items-center"
                                        key={index}
                                      >
                                        <td className=" text-center">
                                          {pago.serie} - {pago.folio}
                                        </td>
                                        <td className=" text-center">
                                          {pago.fmMontoPago}
                                        </td>
                                        <td className=" text-center">
                                          {DateTime.fromISO(
                                            pago.fechaPago
                                          ).toFormat("dd-MM-yyyy")}
                                        </td>
                                        <td className=" text-center">
                                          {pago.restante}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <SeleccionaUnContratoComponent />
            </>
          )}
        </>
      )}
    </>
  );
};
