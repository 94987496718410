import React from "react";
import imagen from "../../Images/LogotipoNegro.png";

export const NotFound404ScreenComponent = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 text-center" style={{ paddingTop: "10vh" }}>
            <img src={imagen} alt="imagen Vitanova" style={{ width: "30%" }} />
            <br />
            <br />
            <br />
            <h2>Error 404</h2>
            <p>El recurso al que deseas acceder no existe, favor de verificarlo</p>
          </div>
        </div>
      </div>
    </>
  );
};
