import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import {
  agregarServicioAOrdenDeServicio,
  getServiciosOrdenesServicio,
} from "../../API/ApiServiciosOrdenesServicio";
import { nuevoPagoOrdenServicio } from "../../API/ApiPagosOrdenes";
import { agregarJardinAPanteon } from "../../API/ApiJardines";
import { AddPanteon } from "../../API/ApiPanteones";
import {
  AgregarUniplexByIdJardin,
  AsignarContratoAUniplexByIdEspacio,
} from "../../API/ApiUniplex";
import { LoadingComponent } from "../LoadingComponent/LoadingComponent";
import {
  AgregarEspaciosACapillaByIdCapilla,
  AsignarContratoAEspacioCapillaByIdEspacio,
  AsignarPersonaAEspacioCapillaByIdEspacio,
} from "../../API/ApiCapillas";

import Autocomplete from "@mui/material/Autocomplete";
import { searchContracts } from "../../API/ApiContratos";
import { AsignarContratoADuplexByIdEspacio } from "../../API/ApiDuplex";
import { AsignarContratoAMuralesByIdEspacio } from "../../API/ApiMurales";

export const AsignarContratoAEspacioMuralesComponent = (props) => {
 
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [finalData, setFinalData] = React.useState([]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div className="crudDivComponent col-md-3 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Asignar Contrato a espacio Mural </h6>
                  <small className="text-muted">
                    Busca el contrato para asignarlo{" "}
                    <strong>Pulsa Enter para guardar</strong>
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="container-fluid">
              <div className="row row-cols-1">
                <div className="col">

                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={finalData}
                    getOptionLabel={(option) =>
                      `${option.serie} ${option.noContrato} -${option.titular}`
                    }
                    onInputChange={(event, option) => {
                      searchContracts(user, event.target.value).then(
                        (resultado) => {
                          if (resultado.status == 200) {
                            setFinalData(resultado.data);
                          } else {
                            setFinalData([]);
                          }
                        }
                      );
                    }}
                    onChange={(event, option) => {
                      setIsDisabled(true);
                      AsignarContratoAMuralesByIdEspacio(user, {
                        idcontrato: option.id,
                        id: props.crudAction.data.id,
                      }).then((resultado) => {
                        props.setCrudAction({ action: null, data: null });

                        toast.success(
                          "Contrato agregado a espacio exitosamente"
                        );

                        setIsDisabled(false);
                      });
                    }}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Buscar Número de Contrato"
                      />
                    )}
                  />


                 
                </div>
                <div className="col">
                  <hr />
                  <div
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      setIsDisabled(true);
                      AsignarContratoAMuralesByIdEspacio(user, {
                        idcontrato: 0,
                        id: props.crudAction.data.id,
                      }).then((resultado) => {
                        props.setCrudAction({ action: null, data: null });

                        toast.success(
                          "Contrato eliminado de espacio exitosamente"
                        );

                        setIsDisabled(false);
                      });
                    }}
                  >
                    Quitar de espacio
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
