import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import { useEffect } from "react";
import io from "socket.io-client";
import {
  getObtenerNotificacionesUsuario,
  putDescartarNotificacion,
} from "../../API/ApiNotificaciones";
import { AuthContext } from "../../auth/authContext";
import sonidoNotificacion from "../../audio/notificationVitanova.mp3";
import Popup from "reactjs-popup";
import { Chip } from "@mui/material";
import NoteIcon from "@mui/icons-material/Note";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { DateTime } from "luxon";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";
import CambiarFormatoFechaConHoraHelper from "../../Helpers/CambiarFormatoFechaConHoraHelper";

const audio = new Audio(sonidoNotificacion);

const NotificacionesDeUsuarioComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);

  const [params, setParams] = React.useState({ limit: 20, offset: 0 });

  const badgeRef = React.useRef(null);

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL_ENDPOINT_SOCKET_COBRANZA);

    socket.on("nuevaNotificacion", (data) => {
      getObtenerNotificacionesUsuario(user, params).then((response) => {
        let resultado = response.data.filter(
          (datosBuscar) => datosBuscar.atendida == false
        );

        if (resultado.length > 0) {
          toast.success("Te han mencionado, revisa las notificaciones", {
            position: "top-center",
          });
          handleNotificationClick();
        }

        setFinalData(response?.data);
        console.log(response);
      });
    });

    getObtenerNotificacionesUsuario(user, params).then((response) => {
      setFinalData(response.data);
      console.log(response);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // Función para manejar clic en el icono de notificaciones
  const handleNotificationClick = () => {
    const audio = new Audio(sonidoNotificacion);
    audio.play().catch((error) => console.error("Error playing sound:", error));
    // Aquí puedes actualizar el estado o manejar la respuesta de la API
  };

  return (
    <div className="ps-2 pe-2">
      <Popup
        contentStyle={{
          width: "400px",
          position: "fixed",
          top: "30px",
          right: "200px",
          maxHeight: "500px",
          overflow: "auto",
        }}
        trigger={
          <Badge
            badgeContent={
              finalData?.filter(
                (notificacion) => notificacion.atendida == false
              ).length
            }
            color="success"
            onClick={handleNotificationClick}
            style={{ cursor: "pointer" }}
            ref={badgeRef}
          >
            <NotificationsIcon />
          </Badge>
        }
        position="bottom right"
      >
        <ul
          className="list-group list-group-flush"
          style={{ fontSize: "13px" }}
        >
          {finalData.map((notificacion, index) => {
            return (
              <li
                className="list-group-item d-flex justify-content-between align-items-start "
                key={index}
                onClick={() => {
                  putDescartarNotificacion(notificacion.id).then(
                    (respuesta) => {
                      let urlContrato = `/contrato/${notificacion.accion}/false`;

                      let urlOrdenServicio = `/ordenservicio/${notificacion.accion}/false`;

                      window.open(
                        notificacion.idtipoNotificacion == 2
                          ? urlOrdenServicio
                          : urlContrato,
                        "_self"
                      );
                    }
                  );
                }}
              >
                <div class="ms-2 me-auto">
                  <div class="fw-bold">
                    {notificacion?.tipoNotificacion?.tipo == "Previsión" ? (
                      <Chip
                        label={<NoteIcon fontSize="inherit" color="success" />}
                        size="small"
                      />
                    ) : null}

                    {notificacion?.tipoNotificacion?.tipo ==
                    "Orden de servicio" ? (
                      <Chip
                        label={<MenuBookIcon fontSize="inherit" color="info" />}
                        size="small"
                      />
                    ) : null}
                    {notificacion.texto}
                  </div>

                  {CambiarFormatoFechaConHoraHelper(notificacion.createdAt)}
                </div>

                {notificacion.atendida == true ? null : (
                  <Chip
                    label={
                      <CheckIcon
                        fontSize="inherit"
                        color="success"
                        onClick={() => {
                          putDescartarNotificacion(notificacion.id).then(
                            (respuesta) => {}
                          );
                        }}
                      />
                    }
                    size="small"
                  />
                )}

                <Chip
                  sx={{ marginLeft: 1 }}
                  color="success"
                  label={
                    <ArrowForwardIcon
                      fontSize="inherit"
                      onClick={() => {
                        putDescartarNotificacion(notificacion.id).then(
                          (respuesta) => {
                            let urlContrato = `/contrato/${notificacion.accion}/false`;

                            let urlOrdenServicio = `/ordenservicio/${notificacion.accion}/false`;

                            window.open(
                              notificacion.idtipoNotificacion == 2
                                ? urlOrdenServicio
                                : urlContrato,
                              "_self"
                            );
                          }
                        );
                      }}
                    />
                  }
                  size="small"
                />
              </li>
            );
          })}
        </ul>
      </Popup>
    </div>
  );
};

export default NotificacionesDeUsuarioComponent;
