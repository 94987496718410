import React from "react";

const SeleccionaUnContratoComponent = () => {
  return (
    <div className=" container-fluid">
      <div className=" row">
        <div className="col-12">
          <div class="px-4 py-5 my-5 text-center">
            <img
              class="d-block mx-auto mb-4"
              src="https://www.gironafilmfestival.com/wp-content/uploads/2024/10/Diseno-sin-titulo-11.png"
              alt=""
              width="72"
            />
            <h1 class="display-5 fw-bold text-body-emphasis">
              Selecciona el contrato
            </h1>
            <div class="col-lg-6 mx-auto">
              <p class="lead mb-4">
                Busca el contrato sobre el cual deseas visualizar su estado de
                cuenta, no olvides imprimirlo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeleccionaUnContratoComponent;
