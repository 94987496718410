import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { searchContracts } from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";
import { searchServiceOrders } from "../../API/ApiOrdenesServicio";

export const BuscadorDeOrdenesDeServicioComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);
  const [numeroContrato, setNumeroContrato] = React.useState(0);

  const [numeroOrdenServicio, setNumeroOrdenServicioBoton] = React.useState(0);

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        className="mt-3"
        options={finalData}
        getOptionLabel={(option) =>
          option.folio +
          "-" +
          option.nombreSolicitante +
          " | " +
          option.nombreFinado
        }
        onInputChange={(e, value) => {
          searchServiceOrders(user, e.target.value).then((resultado) => {
            if (resultado.status == 200) {
              setFinalData(resultado.data);
            } else {
              setFinalData([]);
            }
          });
        }}
        onChange={(event, option) => {
          window.open("/ordenservicio/" + option.folio + "/false", "_self");
        }}
        fullWidth
        renderInput={(params) => (
          <TextField {...params} label="Ingresa orden de Servicio" />
        )}
      />
      <br />
    </>
  );
};
