import React, { useState } from "react";
import { Stage, Layer, Image, Rect, Circle } from "react-konva";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import PinDropIcon from "@mui/icons-material/PinDrop";
import {
  agregaImagenMapaAEspacio,
  getDetallePanteonImagenYMapa,
  updateCoordenadasEspacio,
} from "../../API/ApiEspacios";

export const MapaDeUbicacionComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [image, setImage] = useState(null);
  const [rectangles, setRectangles] = useState([]);
  const [circles, setCircles] = useState([]);

  const [finalData, setFinalData] = React.useState([]);

  const [capillas, setCapillas] = useState([]);
  const [duplex, setDuplex] = useState([]);
  const [uniplex, setUniplex] = useState([]);
  const [mausoleos, setMaousoleos] = useState([]);
  const [murales, setMurales] = useState([]);

  const [cargando, setCargando] = useState(false);

  const handleClose = () => {
    props.setCrudAction({ action: null, data: null });
  };

  //Inicia Capillas
  const handleAddCapillas = () => {
    const newRectangles = [...capillas, {}];
    setCapillas(newRectangles);
  };

  const handleCapillasDragEnd = (index, event, tipoEspacio) => {
    const newMausoleos = [...capillas];
    newMausoleos[index] = {
      x: event.target.x(),
      y: event.target.y(),
    };

    updateCoordenadasEspacio(user, {
      idtipoEspacio: tipoEspacio,
      idubicacion: props.crudAction.data,
      coordenadas: newMausoleos,
    }).then((resultado) => {
      if (resultado.status == 200) {
      } else {
        toast.error("No se puede guardar la ubicación");
      }
    });

    setCapillas(newMausoleos);
  };

  const handleCapillasEliminar = (index, event, tipoEspacio) => {
    const newCapillas = [...capillas];
    newCapillas.splice(index, 1); // Elimina el mausoleo en la posición 'index'

    updateCoordenadasEspacio(user, {
      idtipoEspacio: tipoEspacio,
      idubicacion: props.crudAction.data,
      coordenadas: newCapillas,
    }).then((resultado) => {
      if (resultado.status == 200) {
      } else {
        toast.error("No se puede guardar la ubicación");
      }
    });
    setCapillas(newCapillas); // Actualiza el estado
  };

  //Termina Capillas

  //Inicia Duplex
  const handleAddDuplex = () => {
    const newRectangles = [...duplex, {}];
    setDuplex(newRectangles);
  };

  const handleDuplexDragEnd = (index, event, tipoEspacio) => {
    const newMausoleos = [...duplex];
    newMausoleos[index] = {
      x: event.target.x(),
      y: event.target.y(),
    };

    updateCoordenadasEspacio(user, {
      idtipoEspacio: tipoEspacio,
      idubicacion: props.crudAction.data,
      coordenadas: newMausoleos,
    }).then((resultado) => {
      if (resultado.status == 200) {
      } else {
        toast.error("No se puede guardar la ubicación");
      }
    });

    setDuplex(newMausoleos);
  };

  const handleDuplexEliminar = (index, event, tipoEspacio) => {
    const newCapillas = [...duplex];
    newCapillas.splice(index, 1); // Elimina el mausoleo en la posición 'index'

    updateCoordenadasEspacio(user, {
      idtipoEspacio: tipoEspacio,
      idubicacion: props.crudAction.data,
      coordenadas: newCapillas,
    }).then((resultado) => {
      if (resultado.status == 200) {
      } else {
        toast.error("No se puede guardar la ubicación");
      }
    });
    setDuplex(newCapillas); // Actualiza el estado
  };

  // Termina Duplex

  //Inicia Uniplex
  const handleAddUniplex = () => {
    const newRectangles = [...uniplex, {}];
    setUniplex(newRectangles);
  };

  const handleUniplexDragEnd = (index, event, tipoEspacio) => {
    const newMausoleos = [...uniplex];
    newMausoleos[index] = {
      x: event.target.x(),
      y: event.target.y(),
    };

    updateCoordenadasEspacio(user, {
      idtipoEspacio: tipoEspacio,
      idubicacion: props.crudAction.data,
      coordenadas: newMausoleos,
    }).then((resultado) => {
      if (resultado.status == 200) {
      } else {
        toast.error("No se puede guardar la ubicación");
      }
    });

    setUniplex(newMausoleos);
  };

  const handleUniplexEliminar = (index, event, tipoEspacio) => {
    const newCapillas = [...uniplex];
    newCapillas.splice(index, 1); // Elimina el mausoleo en la posición 'index'

    updateCoordenadasEspacio(user, {
      idtipoEspacio: tipoEspacio,
      idubicacion: props.crudAction.data,
      coordenadas: newCapillas,
    }).then((resultado) => {
      if (resultado.status == 200) {
      } else {
        toast.error("No se puede guardar la ubicación");
      }
    });
    setUniplex(newCapillas); // Actualiza el estado
  };

  //Termina Uniplex

  //Inicia Mausoleo
  const handleAddMausoleo = () => {
    const newRectangles = [...mausoleos, {}];
    setMaousoleos(newRectangles);
  };

  const handleMausoleoDragEnd = (index, event, tipoEspacio) => {
    const newMausoleos = [...mausoleos];
    newMausoleos[index] = {
      x: event.target.x(),
      y: event.target.y(),
    };

    updateCoordenadasEspacio(user, {
      idtipoEspacio: tipoEspacio,
      idubicacion: props.crudAction.data,
      coordenadas: newMausoleos,
    }).then((resultado) => {
      if (resultado.status == 200) {
      } else {
        toast.error("No se puede guardar la ubicación");
      }
    });

    setMaousoleos(newMausoleos);
  };

  const handleMausoleosEliminar = (index, event, tipoEspacio) => {
    const newCapillas = [...mausoleos];
    newCapillas.splice(index, 1); // Elimina el mausoleo en la posición 'index'

    updateCoordenadasEspacio(user, {
      idtipoEspacio: tipoEspacio,
      idubicacion: props.crudAction.data,
      coordenadas: newCapillas,
    }).then((resultado) => {
      if (resultado.status == 200) {
      } else {
        toast.error("No se puede guardar la ubicación");
      }
    });
    setMaousoleos(newCapillas); // Actualiza el estado
  };

  //Termina Mausoleo

  //Inicia Murales
  const handleAddMurales = () => {
    const newRectangles = [...murales, {}];
    setMurales(newRectangles);
  };

  const handleMuralesDragEnd = (index, event, tipoEspacio) => {
    const newMausoleos = [...murales];
    newMausoleos[index] = {
      x: event.target.x(),
      y: event.target.y(),
    };
    setMurales(newMausoleos);
  };

  const handleMuralesEliminar = (index, event, tipoEspacio) => {
    const newCapillas = [...murales];
    newCapillas.splice(index, 1); // Elimina el mausoleo en la posición 'index'

    updateCoordenadasEspacio(user, {
      idtipoEspacio: tipoEspacio,
      idubicacion: props.crudAction.data,
      coordenadas: newCapillas,
    }).then((resultado) => {
      if (resultado.status == 200) {
      } else {
        toast.error("No se puede guardar la ubicación");
      }
    });
    setMurales(newCapillas); // Actualiza el estado
  };

  //Termina Murales

  React.useEffect(() => {
    const imageObj = new window.Image();

    imageObj.src = "https://sistema.funerariavitanova.com:3000/images/mapa.jpg";

    getDetallePanteonImagenYMapa(user, props.crudAction.data).then(
      (resultado) => {
        if (resultado.status === 200) {
          

          imageObj.src =
            resultado.data[0].id == 1
              ? "https://sistema.funerariavitanova.com:3000/images/mapa.jpg"
              : resultado.data[0]?.mapa;

          imageObj.onload = () => {
            setImage(imageObj);
          };

          setFinalData(resultado.data);
        } else {
          toast.error(
            "Ocurrió un error al obtener el detalle del panteon en el mapa"
          );
        }
      }
    );
  }, []);

  React.useEffect(() => {
    if (finalData.length > 0) {
      
      let resultadoCapillas = finalData[0]?.coordenadas?.find(
        (espacio) => espacio.idtipoEspacio == 1
      );
      let resultadoDuplex = finalData[0]?.coordenadas?.find(
        (espacio) => espacio.idtipoEspacio == 2
      );
      let resultadoUniplex = finalData[0]?.coordenadas?.find(
        (espacio) => espacio.idtipoEspacio == 3
      );
      let resultadoMausoleos = finalData[0]?.coordenadas?.find(
        (espacio) => espacio.idtipoEspacio == 4
      );
      let resultadoMurales = finalData[0]?.coordenadas?.find(
        (espacio) => espacio.idtipoEspacio == 5
      );

      

      setCapillas(
        typeof resultadoCapillas === "undefined"
          ? []
          : resultadoCapillas.coordenadas == null
          ? []
          : resultadoCapillas.coordenadas
      );
      setDuplex(
        typeof resultadoDuplex === "undefined"
          ? []
          : resultadoDuplex.coordenadas == null
          ? []
          : resultadoDuplex.coordenadas
      );
      setUniplex(
        typeof resultadoUniplex === "undefined"
          ? []
          : resultadoUniplex.coordenadas == null
          ? []
          : resultadoUniplex.coordenadas
      );
      setMaousoleos(
        typeof resultadoMausoleos === "undefined"
          ? []
          : resultadoMausoleos.coordenadas == null
          ? []
          : resultadoMausoleos.coordenadas
      );
      setMurales(
        typeof resultadoMurales === "undefined"
          ? []
          : resultadoMurales.coordenadas == null
          ? []
          : resultadoMurales.coordenadas
      );
    } else {
      return;
    }
  }, [finalData]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Señalización de Ubicaciones"}
        </DialogTitle>
        <DialogContent>
          <small>
            Utiliza doble click para eliminar la ubicación si lo necesitas
          </small>
          <div>
            <div className="mt-3 mb-2">
              {props.finalDataAnaliticaPanteon[0]?.capillas?.totalConstruidas >
              0 ? (
                <button
                  className="btn btn-sm"
                  style={{ background: "red" }}
                  onClick={handleAddCapillas}
                >
                  <PinDropIcon fontSize="inherit" /> Mapear Capilla
                </button>
              ) : null}

              {props.finalDataAnaliticaPanteon[1]?.duplex?.totalConstruidas >
              0 ? (
                <button
                  className="btn btn-sm"
                  style={{ background: "blue" }}
                  onClick={handleAddDuplex}
                >
                  <PinDropIcon fontSize="inherit" /> Mapear Duplex
                </button>
              ) : null}

              {props.finalDataAnaliticaPanteon[2]?.uniplex?.totalConstruidas >
              0 ? (
                <button
                  className="btn btn-sm"
                  style={{ background: "green" }}
                  onClick={handleAddUniplex}
                >
                  <PinDropIcon fontSize="inherit" /> Mapear Uniplex
                </button>
              ) : null}

              {props.finalDataAnaliticaPanteon[3]?.mausoleo?.totalConstruidas >
              0 ? (
                <button
                  className="btn btn-sm"
                  style={{ background: "purple" }}
                  onClick={handleAddMausoleo}
                >
                  <PinDropIcon fontSize="inherit" /> Mapear Mausoleo
                </button>
              ) : null}

              {props.finalDataAnaliticaPanteon[4]?.murales?.totalConstruidas >
              0 ? (
                <button
                  className="btn btn-sm"
                  style={{ background: "yellow" }}
                  onClick={handleAddMurales}
                >
                  <PinDropIcon fontSize="inherit" /> Mapear Murales
                </button>
              ) : null}
            </div>
            <Stage width={800} height={600}>
              <Layer>
                {image && <Image image={image} width={800} height={600} />}
                {capillas.map((_, index) => {
                  return (
                    <Rect
                      key={index}
                      x={_.x}
                      y={_.y}
                      width={20}
                      height={20}
                      stroke="white"
                      fill="red"
                      draggable
                      onDragEnd={(event) =>
                        handleCapillasDragEnd(index, event, 1)
                      }
                      onClick={() => {}}
                      onDblClick={(event) => {
                        handleCapillasEliminar(index, event, 1);
                      }}
                    />
                  );
                })}
                {duplex.map((_, index) => (
                  <Rect
                    key={index}
                    x={_.x}
                    y={_.y}
                    width={20}
                    height={20}
                    fill="blue"
                    stroke="white"
                    draggable
                    onDragEnd={(event) => handleDuplexDragEnd(index, event, 2)}
                    onClick={() => {}}
                    onDblClick={(event) => {
                      handleDuplexEliminar(index, event, 2);
                    }}
                  />
                ))}
                {uniplex.map((_, index) => (
                  <Rect
                    key={index}
                    x={_.x}
                    y={_.y}
                    width={20}
                    height={20}
                    fill="green"
                    stroke="white"
                    onDragEnd={(event) => handleUniplexDragEnd(index, event, 3)}
                    draggable
                    onClick={() => {}}
                    onDblClick={(event) => {
                      handleUniplexEliminar(index, event, 3);
                    }}
                  />
                ))}
                {mausoleos.map((_, index) => (
                  <Rect
                    key={index}
                    x={_.x}
                    y={_.y}
                    width={20}
                    height={20}
                    fill="purple"
                    stroke="white"
                    draggable
                    onClick={() => {}}
                    onDragEnd={(event) =>
                      handleMausoleoDragEnd(index, event, 4)
                    }
                    onDblClick={(event) => {
                      handleMausoleosEliminar(index, event, 4);
                    }}
                  />
                ))}
                {murales.map((_, index) => (
                  <Rect
                    key={index}
                    x={_.x}
                    y={_.y}
                    width={20}
                    height={20}
                    fill="yellow"
                    stroke="white"
                    draggable
                    onClick={() => {}}
                    onDragEnd={(event) => handleMuralesDragEnd(index, event, 5)}
                    onDblClick={(event) => {
                      handleMuralesEliminar(index, event, 5);
                    }}
                  />
                ))}
              </Layer>
            </Stage>

            <div className="mb-3 mt-3">
              <label for="formFile" className="form-label">
                Agregar Mapa
              </label>
              <input
                className="form-control"
                disabled={cargando}
                type="file"
                accept="image/jpeg"
                id="formFile"
                onChange={(event) => {
                  setCargando(true);
                  let archivoASubir = event.target.files[0];

                  const formData = new FormData();
                  formData.append("archivo", archivoASubir);

                  agregaImagenMapaAEspacio(
                    user,
                    props.crudAction.data,
                    formData
                  ).then((resultado) => {
                    if (resultado.status === 200) {
                      setCargando(false);
                      toast.success("Se ha cargado la imagen correctamente");
                    } else {
                      setCargando(false);
                      toast.error(
                        "Ha habido un error al cargar la imagen, revisa consola"
                      );
                    }
                  });
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
