import React from "react";
import { AuthContext } from "../../auth/authContext";
import { EditarComisionOrdenDeServicioComponent } from "../EditarComisionOrdenDeServicioComponent/EditarComisionOrdenDeServicioComponent";
import { EditarEmpleadosOrdenDeServicioComponent } from "../EditarEmpleadosOrdenDeServicioComponent/EditarEmpleadosOrdenDeServicioComponent";

export const PersonalServicioOrdenServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [finalData, setFinalData] = React.useState([]);
  const [totalCuenta, setTotalCuenta] = React.useState();

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <EditarEmpleadosOrdenDeServicioComponent data={props} />
          </div>
        </div>
      </div>
    </>
  );
};
