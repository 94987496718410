import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import {
  agregarServicioAOrdenDeServicio,
  getServiciosOrdenesServicio,
} from "../../API/ApiServiciosOrdenesServicio";
import { nuevoPagoOrdenServicio } from "../../API/ApiPagosOrdenes";
import { agregarJardinAPanteon } from "../../API/ApiJardines";
import { AddPanteon } from "../../API/ApiPanteones";
import { AgregarUniplexByIdJardin } from "../../API/ApiUniplex";
import { LoadingComponent } from "../LoadingComponent/LoadingComponent";
import { AgregarDuplexByIdJardin } from "../../API/ApiDuplex";
import { AgregarMuralesByIdJardin } from "../../API/ApiMurales";
import { AgregarLugaresAMausoleoByIdJardin, AgregarPersonaAEspacioAMausoleo } from "../../API/ApiMausoleo";

const validationSchema = yup.object({
  urna: yup.string("Ingresa Urna").required("Urna Requerida"),
  fechaEntrada: yup
    .string("Ingresa Fecha Entrada")
    .required("Fecha de Entrada Necesaria"),
});

export const AsignarFallecidoAMausoleoScreenComponent = (props) => {



  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      idmausoleo: props.crudAction.data.id,
      urna: "",
      fechaEntrada: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);
      AgregarPersonaAEspacioAMausoleo(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Se ha asignado la urna al espacio.");
          props.setCrudAction({ action: null, data: null });
        }

        setIsDisabled(false);
      });
    },
  });



  return (
    <>
      <div className="crudDivComponent col-md-3 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Asignar Urna A Mausoleo</h6>
                  <small className="text-muted">
                    Ingresa los datos para agregar Espacios a Mausoleo
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>

                  <LoadingComponent />
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row row-cols-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="urna"
                    name="urna"
                    label="Personas que van en la Urna"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.urna}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={formik.touched.urna && Boolean(formik.errors.urna)}
                    helperText={formik.touched.urna && formik.errors.urna}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="fechaEntrada"
                    name="fechaEntrada"
                    label="Fecha Entrada"
                    type={"date"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.fechaEntrada}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.fechaEntrada && Boolean(formik.errors.fechaEntrada)
                    }
                    helperText={formik.touched.fechaEntrada && formik.errors.fechaEntrada}
                    disabled={isDisabled}
                  />
                </div>
              

                <div className="col mt-3">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success w-100"
                  >
                    Asignar Urna a Mausoleo
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
