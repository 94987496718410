import React from "react";
import styles from "./AgregarPagoComponent.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { newContractPayment } from "../../API/ApiPagosContratos";
import { imprimirTicket } from "./../../API/ApiImpresoraTickets";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DateTime } from "luxon";

const validationSchema = yup.object({
  serie: yup.string("Ingresa serie").required("Serie requerido"),
  folio: yup.string("Ingresa serie").required("Serie requerido"),
  noContrato: yup
    .string("Ingresa Número de contrato")
    .required("Número de contrato requerido"),
  monto: yup.string("Ingresa monto").required("Monto requerido"),
  idtipoPago: yup.string("Ingresa monto").required("Monto requerido"),
  fechaPago: yup.string("Ingresa fecha de pago").required("Fecha requerida"),
});

const getDateTime = () => {
  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd
  const currDate = defaultValue;
  return currDate;
};

export const AgregarPagoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const searchInput = React.useRef(null);

  const [mostrarFolio, setMostrarFolio] = React.useState(false);

  const [isDisabled, setIsDisabled] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      serie: "A",
      folio: "0",
      noContrato: props.finalData.etapaUno[0]?.noContrato,
      monto: "",
      idtipoPago: "1",
      idcontrato: props.finalData.etapaUno[0]?.id,
      fechaPago: getDateTime(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let fechaParaGuardar = DateTime.fromISO(values.fechaPago);

      let fechaActual = DateTime.now();

      if (fechaParaGuardar <= fechaActual) {
        setIsDisabled(true);
      } else {
        toast.info("Verifica la fecha de pago");
        toast.error(
          "No puedes ingresar fechas de pago posteriores a la fecha actual"
        );

        return;
      }

      toast.info("Guardando información de pago");

      setIsDisabled(true);

      newContractPayment(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Se ha agregado correctamernte el pago");
          var datosImpresion = {
            tipoImagen: 2,
            razonSocial: values.serie.toString() === "SM" ? 2 : 1,
            datosRecibo: [
              {
                nombre: "Restructura",
                valor: resultado.data.restructura === true ? "Si" : "No",
              },
              {
                nombre: "Fecha Pago",
                valor: resultado.data.fechaPago,
              },
              {
                nombre: "Folio Recibo",
                valor: resultado.data.serie + " " + resultado.data.folio,
              },
              {
                nombre: "No. Contrato",
                valor:
                  resultado.data.serieContrato +
                  " " +
                  resultado.data.noContrato,
              },
              {
                nombre: "Nombre Titular",
                valor: resultado.data.titular,
              },
              {
                nombre: "Dirección",
                valor: resultado.data.domicilio,
              },

              {
                nombre: "Saldo Anterior",
                valor:
                  "$ " +
                  parseFloat(resultado.data.saldoAnterior)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },

              {
                nombre: "Abono",
                valor:
                  "$ " +
                  parseFloat(resultado.data.abono)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },

              {
                nombre: "Saldo Actual",
                valor:
                  "$ " +
                  parseFloat(resultado.data.saldoActual)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    .toString(),
              },
            ],
            datosCodigoBarra: "PREV-" + resultado.data.idcontrato,
            idpago: resultado.data.id,
          };
          imprimirTicket(datosImpresion).then((resultado) => {
            window.location.reload();
            let contadorActualizar = props.contadorActualizar + 1;
            props.setContadorActualizar(contadorActualizar);
            props.setCrudAction({ action: null, data: null });
            setIsDisabled(false);
          });
        } else {
          toast.error("ha ocurrido un error");
          setIsDisabled(false);
        }
      });
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27 && isDisabled == false) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };

    searchInput.current.focus();
  }, []);

  return (
    <>
      <div className={styles.modalAgregarPagoContainer}>
        <div className="container">
          <form className="row" onSubmit={formik.handleSubmit}>
            <div className="col-md-7 col-sm-12 mt-md-5 bg-white mx-auto rounded-2">
              <div className="text-start p-2">
                <h5 className="m-0 p-0">Agregar Pago</h5>
                <hr className="p-0 m-0 mt-2 mb-2" />
              </div>
              <div className="contenido p-2">
                {isDisabled == true ? (
                  <>
                    <Alert severity="info" className="my-3">
                      Estamos agregando el pago... espera un momento
                    </Alert>
                  </>
                ) : null}
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <TextField
                        size="small"
                        fullWidth
                        variant="standard"
                        className="mb-2"
                        id="noContrato"
                        name="noContrato"
                        label="Número de Contrato:"
                        value={formik.values.noContrato}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.noContrato &&
                          Boolean(formik.errors.noContrato)
                        }
                        helperText={
                          formik.touched.noContrato && formik.errors.noContrato
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        size="small"
                        ref={searchInput}
                        fullWidth
                        variant="standard"
                        className="mb-2"
                        id="fechaPago"
                        name="fechaPago"
                        type={"date"}
                        label="fechaPago: "
                        value={formik.values.fechaPago}
                        InputLabelProps={{ shrink: true }}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                        error={
                          formik.touched.fechaPago &&
                          Boolean(formik.errors.fechaPago)
                        }
                        helperText={
                          formik.touched.fechaPago && formik.errors.fechaPago
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <FormControl fullWidth>
                        <InputLabel
                          variant="standard"
                          htmlFor="uncontrolled-native"
                          shrink={true}
                        >
                          Serie
                        </InputLabel>
                        <NativeSelect
                          value={formik.values.idcategoria}
                          defaultValue={"A"}
                          onChange={(e) => {
                            let seleccionado = e.target.value;

                            if (
                              seleccionado === "A" ||
                              seleccionado === "C" ||
                              seleccionado === "P" ||
                              seleccionado === "M" ||
                              seleccionado === "SM"
                            ) {
                              setMostrarFolio(false);
                            } else {
                              setMostrarFolio(true);
                            }

                            formik.handleChange(e);
                          }}
                          disabled={isDisabled}
                          inputProps={{
                            name: "serie",
                            id: "uncontrolled-native",
                          }}
                        >
                          <option value={"A"}>A</option>
                          <option value={"AA"}>AA</option>
                          <option value={"B"}>B</option>
                          <option value={"BB"}>BB</option>
                          <option value={"D"}>D</option>
                          <option value={"E"}>E</option>
                          <option value={"F"}>F</option>
                          <option value={"G"}>G</option>
                          <option value={"H"}>H</option>
                          <option value={"I"}>I</option>
                          <option value={"J"}>J</option>
                          <option value={"P"}>P</option>
                          <option value={"MA"}>MA</option>
                          <option value={"SM"}>SM</option>
                          <option value={"S"}>S</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      {mostrarFolio == true ? (
                        <TextField
                          size="small"
                          fullWidth
                          className={`mb-3 ${
                            mostrarFolio == true ? null : "d-none"
                          }`}
                          id="folio"
                          name="folio"
                          disabled={isDisabled}
                          type={"number"}
                          variant="standard"
                          label="Folio:"
                          value={formik.values.folio}
                          InputProps={{ inputProps: { min: 0 } }}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.folio && Boolean(formik.errors.folio)
                          }
                          helperText={
                            formik.touched.folio && formik.errors.folio
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <TextField
                        size="small"
                        fullWidth
                        className="mb-2"
                        id="monto"
                        name="monto"
                        disabled={isDisabled}
                        variant="standard"
                        type={"number"}
                        label="Monto: $"
                        value={formik.values.monto}
                        InputProps={{ inputProps: { min: 0, step: "any" } }}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.monto && Boolean(formik.errors.monto)
                        }
                        helperText={formik.touched.monto && formik.errors.monto}
                      />
                    </div>
                  </div>
                </div>

                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 mt-1">
                      <TextField
                        id="idtipoPago"
                        fullWidth
                        name="idtipoPago"
                        select
                        label="Tipo de pago"
                        SelectProps={{
                          native: true,
                        }}
                        variant="standard"
                        error={
                          formik.touched.idtipoPago &&
                          Boolean(formik.errors.idtipoPago)
                        }
                        helperText={
                          formik.touched.idtipoPago && formik.errors.idtipoPago
                        }
                      >
                        <option value={1}>Efectivo</option>
                        <option value={2}>Tarjeta</option>
                      </TextField>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pie p-2">
                <hr />
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button
                    className="btn btn-sm btn-outline-danger rounded-4"
                    type="button"
                    disabled={isDisabled}
                    onClick={() => {
                      props.setCrudAction({ action: null, data: null });
                    }}
                  >
                    Cerrar
                  </button>
                  <button
                    className="btn btn-sm btn-success rounded-4"
                    type="submit"
                    disabled={isDisabled}
                  >
                    Ingresar Pago
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
