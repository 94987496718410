import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";

import Autocomplete from "@mui/material/Autocomplete";
import { searchContracts } from "../../API/ApiContratos";

const validationSchema = yup.object({
  titular: yup.string("Ingresa Titular").required("Titular requerido"),
  noContrato: yup
    .string("Ingresa número de contrato")
    .required("Número de contrato requerido"),
  fechaContrato: yup
    .string("Ingresa fecha contrato")
    .required("Fecha requerida"),
  domicilio: yup.string("Ingresa Domicilio").required("Domicilio requerido"),
  colonia: yup.string("Ingresa Colonia").required("Colonia requerida"),
  cp: yup
    .string("Ingresa Código Postal")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "5 dígitos para código postal")
    .max(5, "5 dígitos para código postal")
    .required("Código postal requerido"),
  hPoblacion: yup.string("Ingresa población").required("Población requerida"),
  entreCalle1: yup.string("Ingresa Calle").required("Calle requerida"),
  entreCalle2: yup.string("Ingresa Calle").required("Calle requerida"),
  telefono: yup
    .string("Ingresa teléfono")
    .matches(/^[0-9]{5}[0-9]{5}$/, "Número Inválido")
    .required("Nombre requerido"),
  domicilioTrabajo: yup.string("Ingresa domicilio trabajo"),
  coloniaTrabajo: yup.string("Ingresa colonia trabajo"),
  telefonoTrabajo: yup
    .string("Ingresa teléfono")
    .matches(/^[0-9]{5}[0-9]{5}$/, "Número Inválido"),
});

export const AddOrdenServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);

  const [finalData, setFinalData] = React.useState([]);

  React.useEffect(() => {
    let listo = false;

    getEstados(user).then((resultado) => {
      if (resultado.status == 200) {
        setEstados(resultado.data);
      } else {
        setEstados([]);
      }
    });

    return () => {
      listo = true;
    };
  }, []);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      letraFolio: "C",
      noContrato: "",
      folio: "",
      fecha: DateTime.now().toISODate(),
      nombreFinado: "",
      lugarNacimiento: "",
      fechaNacimiento: "",
      sexo: "M",
      nacionalidad: "",
      estadoCivil: "Soltero",
      domicilio: "",
      colonia: "",
      derechohabiente: "Si",
      ocupacion: "",
      nombrePadre: "",
      nombreMadre: "",
      nombreConyugue: "",
      lugarDefuncion: "",
      fechaDefuncion: "",
      horaDefuncion: "",
      atencionMedica: "",
      lugarVelacion: "",
      horaMisa: "",
      templo: "",
      horaSepelio: "",
      ubicacion: "",
      perpetuidad: "",
      nomAexhum: "",
      FDPAExhum: "",
      perpetuidadAExhum: "",
      nombreSolicitante: "",
      domicilioSolicitante: "",
      coloniaSolicitante: "",
      parentesco: "",
      telefonoSolicitante: "",
      emergencia: "",
      fechaLPago: "",
      montoComision: 0,
      nombrePanteon: "",
      observaciones: "",
      clasificacion: "",
    },

    onSubmit: (values) => {
      setIsDisabled(true);

      

      if (values.folio == "") {
        toast.error("Ingresa Folio");
        setIsDisabled(false);
      } else {
        //  values.fecha = DateTime.now().toISODate();

        const regex = /^[A-Za-z]\d+$/;

        values.fechaLPago = DateTime.now().toISODate();
        values.folio = values.letraFolio.split("")[0] + values.folio;
        values.montoComision = 0;

        if (!regex.test(values.folio)) {
          toast.error("Folio debe ser letra seguido de número");
          setIsDisabled(false);
          return;
        }


        createNewOrder(user, values).then((resultado) => {
          if (resultado.status == 200) {
            window.open(
              `/ordenservicio/${resultado.data.folio}/false`,
              "_self"
            );
            toast.success(
              "Se ha agregado la orden de servicio, consulta pestaña de detalle"
            );

            props.setCrudAction({
              action: null,
              data: null,
            });
          } else {
            toast.error(resultado?.response?.data?.message);
          }

          setIsDisabled(false);
        });
      }
    },
  });

  return (
    <>
      <div className="crudDivComponent col-md-9 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>{props.nombre}</h6>
                  <small className="text-muted">
                    Ingresa los datos para agregar la orden de servicio
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>
                </>
              ) : null}
              <hr />
            </div>
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row">
                <div className="col mb-3 fw-bold h5">Datos del finado</div>
              </div>

              <div className="row">
                <div className="col-12 mb-3">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={finalData}
                    getOptionLabel={(option) =>
                      `${option.serie} ${option.noContrato} -${option.titular}`
                    }
                    onInputChange={(event, option) => {
                      searchContracts(user, event.target.value).then(
                        (resultado) => {
                          if (resultado.status == 200) {
                            setFinalData(resultado.data);
                          } else {
                            setFinalData([]);
                          }
                        }
                      );
                    }}
                    onChange={(event, option) => {
                      formik.setFieldValue("noContrato", option.noContrato);
                      formik.setFieldValue("idcontrato", option.id);
                    }}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Buscar Número de Contrato"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="row row-cols-3">
                <div className="col">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      shrink
                      htmlFor="uncontrolled-native"
                    >
                      Letra Orden
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.letraFolio}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      inputProps={{
                        name: "letraFolio",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value={"C"}>C</option>
                      <option value={"S"}>S</option>
                      <option value={"F"}>F</option>
                      <option value={"P"}>P</option>
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="folio"
                    name="folio"
                    label="Folio"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.folio}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={formik.touched.folio && Boolean(formik.errors.folio)}
                    helperText={formik.touched.folio && formik.errors.folio}
                    disabled={isDisabled}
                  />
                </div>

                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="fecha"
                    name="fecha"
                    label="Fecha Orden de Servicio"
                    type={"date"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.fecha}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={formik.touched.fecha && Boolean(formik.errors.fecha)}
                    helperText={formik.touched.fecha && formik.errors.fecha}
                    disabled={isDisabled}
                  />
                </div>
              </div>
              <div className="row row-cols-3">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="fechaDefuncion"
                    name="fechaDefuncion"
                    label="Fecha Defunción"
                    type={"date"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.fechaDefuncion}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.fechaDefuncion &&
                      Boolean(formik.errors.fechaDefuncion)
                    }
                    helperText={
                      formik.touched.fechaDefuncion &&
                      formik.errors.fechaDefuncion
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="nombreFinado"
                    name="nombreFinado"
                    label="Nombre Finado"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.nombreFinado}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.nombreFinado &&
                      Boolean(formik.errors.nombreFinado)
                    }
                    helperText={
                      formik.touched.nombreFinado && formik.errors.nombreFinado
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="lugarNacimiento"
                    name="lugarNacimiento"
                    label="Lugar De Nacimiento"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.lugarNacimiento}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.lugarNacimiento &&
                      Boolean(formik.errors.lugarNacimiento)
                    }
                    helperText={
                      formik.touched.lugarNacimiento &&
                      formik.errors.lugarNacimiento
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="fechaNacimiento"
                    name="fechaNacimiento"
                    label="Fecha de Nacimiento"
                    type={"date"}
                    className="mb-3"
                    value={formik.values.fechaNacimiento}
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.fechaNacimiento &&
                      Boolean(formik.errors.fechaNacimiento)
                    }
                    helperText={
                      formik.touched.fechaNacimiento &&
                      formik.errors.fechaNacimiento
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      shrink
                    >
                      Sexo
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.sexo}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      defaultValue={"M"}
                      inputProps={{
                        name: "sexo",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value={"M"}>Masculino</option>
                      <option value={"F"}>Femenino</option>
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="nacionalidad"
                    name="nacionalidad"
                    label="Nacionalidad"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.nacionalidad}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.nacionalidad &&
                      Boolean(formik.errors.nacionalidad)
                    }
                    helperText={
                      formik.touched.nacionalidad && formik.errors.nacionalidad
                    }
                    disabled={isDisabled}
                  />
                </div>

                <div className="col">
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="estadoCivil" shrink>
                      Estado Civil
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.estadoCivil}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      defaultValue={"M"}
                      inputProps={{
                        name: "estadoCivil",
                        id: "estadoCivil",
                      }}
                    >
                      <option value={"Soltero"}>Soltero</option>
                      <option value={"Casado"}>Casado</option>
                      <option value={"Divorciado"}>Divorciado</option>
                      <option value={"Separación En Proceso"}>
                        Separación el Proceso
                      </option>
                      <option value={"Viudo"}>Viudo</option>
                      <option value={"Concubinato"}>Concubinato</option>
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="domicilio"
                    name="domicilio"
                    label="Domicilio"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.domicilio}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.domicilio &&
                      Boolean(formik.errors.domicilio)
                    }
                    helperText={
                      formik.touched.domicilio && formik.errors.domicilio
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="colonia"
                    name="colonia"
                    label="Colonia"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.colonia}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.colonia && Boolean(formik.errors.colonia)
                    }
                    helperText={formik.touched.colonia && formik.errors.colonia}
                    disabled={isDisabled}
                  />
                </div>

                <div className="col">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="derechohabiente"
                      shrink
                    >
                      DerechoHabiente
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.derechohabiente}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      defaultValue={"M"}
                      inputProps={{
                        name: "derechohabiente",
                        id: "derechohabiente",
                      }}
                    >
                      <option value={"Si"}>Si</option>
                      <option value={"No"}>No</option>
                    </NativeSelect>
                  </FormControl>
                </div>

                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="ocupacion"
                    name="ocupacion"
                    label="Ocupación"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.ocupacion}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.ocupacion &&
                      Boolean(formik.errors.ocupacion)
                    }
                    helperText={
                      formik.touched.ocupacion && formik.errors.ocupacion
                    }
                    disabled={isDisabled}
                  />
                </div>
              </div>

              <div className="row row-cols-1">
                <div className="col">
                  <button
                    type="submit"
                    disabled={isDisabled}
                    className="btn w-100 btn-sm btn-success"
                  >
                    Crear Orden de servicio
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
