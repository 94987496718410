import React from "react";
import PaidIcon from "@mui/icons-material/Paid";
import {
  getVendedoresCobranzaAPI,
  postProcesarComisionContrato,
} from "../../API/ApiComisionesDosPuntoCero";
import { AuthContext } from "../../auth/authContext";
import { toast } from "react-toastify";

//Aqui debería de agregar al vendedore

const NoHayComisionistasComponent = (props) => {
  const [finalData, setFinalData] = React.useState([]);
  const { user, dispatch } = React.useContext(AuthContext);

  const [vendedorSeleccionado, setVendedorSeleccionado] = React.useState(null);

  const [confirmaMovimiento, setConfirmaMovimiento] = React.useState(false);

  React.useEffect(() => {
    //Aqui hago la llamada de los vendedores
    getVendedoresCobranzaAPI(user).then((resultado) => {
      if (resultado.status === 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });
  }, [props.contadorActualizar]);

  return (
    <>
      <div class="container my-5">
        <div
          class="position-relative text-center bg-body border border-dashed rounded-5"
          style={{ paddingTop: "10vh", paddingBottom: "10vh" }}
        >
          <PaidIcon fontSize="large" />
          <h3 class=" text-danger">No existe vendedor</h3>
          <p class="col-lg-6 mx-auto mb-4">
            Te pedimos agregar al vendedor, recuerda que es un paso muy
            importante para poder registrar las comisones de una manera
            correcta.
          </p>

          <div className=" row">
            <div className="col-sm-6 mx-auto">
              <select
                className="form-select"
                vendedorSeleccionado={vendedorSeleccionado}
                onChange={(e) => {
                  setVendedorSeleccionado(e.target.value);
                }}
                aria-label="Selecciona un vendedor"
              >
                <option value="">Selecciona un vendedor</option>
                {finalData.map((vendedor) => (
                  <option key={vendedor.id} value={vendedor.id}>
                    {vendedor.empleado.nombre} {vendedor.empleado.apaterno}{" "}
                    {vendedor.empleado.amaterno}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Checkbox para confirmar el movimiento */}
          <div className="flex items-center justify-center mt-4">
            <input
              id="confirm-checkbox"
              type="checkbox"
              className="form-checkbox h-5 w-5 text-green-600"
              checked={confirmaMovimiento}
              onChange={(e) => setConfirmaMovimiento(e.target.checked)}
            />
            <label htmlFor="confirm-checkbox" className="ml-2 text-gray-700">
              Confirmo al vendedor asignado a este contrato, y entiendo las
              alteraciones que esto puede causar.
            </label>
          </div>

          {confirmaMovimiento == true ? (
            <button
              class="btn btn-success px-5 mb-5 mt-5"
              type="button"
              onClick={() => {
                console.log("Asignar vendedor");

                if (
                  vendedorSeleccionado == null ||
                  vendedorSeleccionado == ""
                ) {
                  toast.error("Selecciona un vendedor");
                  return;
                }

                postProcesarComisionContrato(user, {
                  idvendedor: vendedorSeleccionado,
                  idcontrato: props.contrato,
                }).then((resultado) => {
                  if (resultado.status === 200) {
                    toast.success("Vendedor asignado correctamente");
                    props.setLoading(false);
                  } else {
                    toast.error("Error al asignar vendedor");
                    props.setLoading(false);
                  }

                  props.setContadorActualizar(props.contadorActualizar + 1);
                });
              }}
            >
              Asignar vendedor
            </button>
          ) : (
            <button
              class="btn btn-warning px-5 mb-5 mt-5"
              type="button"
              onClick={() => {
                toast.warning("Debes confirmar el movimiento");
              }}
            >
              Confirmar Movimiento
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default NoHayComisionistasComponent;
