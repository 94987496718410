import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { AuthContext } from "../../auth/authContext";
import {
  reporteCobranzaRangoFecha,
  reporteCuboComisionesContratos,
  reporteCuboComisionesOrdenes,
  reporteNomina,
  reporteResumenSerie,
} from "../../API/ApiReportes";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import { BuscadorDeOrdenesDeServicioHookComponent } from "../../Components/BuscadorDeOrdenesDeServicioHookComponent/BuscadorDeOrdenesDeServicioHookComponent";
import { getAllEmpleadosParaSelect } from "../../API/ApiEmpleados";
import { BuscadorDeContratosParaPagoComponent } from "../../Components/BuscadorDeContratosParaPagoComponent/BuscadorDeContratosParaPagoComponent";
import { BuscadorDeContratosParaReporteComponent } from "../../Components/BuscadorDeContratosParaReporteComponent/BuscadorDeContratosParaReporteComponent";
import { toast } from "react-toastify";

const guidVista = "ef433e95-e3b0-4caa-a533-3b599add112e";

export const ReporteDeNominaMasComisionesScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [reporteSerie, setReporteSerie] = React.useState("");
  const [reporteFechaInicial, setReporteFechaInicial] = React.useState("");
  const [reporteFechaFin, setReporteFechaFin] = React.useState("");
  const [numeroOrdenServicio, setNumeroOrdenServicio] = React.useState("");
  const [finalData, setFinalData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoReporte, setPermisoReporte] = React.useState(false);
  const [idempleadoFound, setIdempleadoFound] = React.useState("");
  const [granTotalComisionMostrar, setGranTotalComisionMostrar] =
    React.useState(0.0);

  const [granTotalTotalMostrar, setGranTotalTotalMostrar] = React.useState(0.0);

  const [listadoEmpleados, setListadoEmpleados] = React.useState([]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "860d4e4a-8337-4fc3-93c3-fbd3e8436bdd" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
            if (
              permiso.guidFuncion == "860d4e4a-8337-4fc3-93c3-fbd3e8436bdd" &&
              permiso.allowed == 1
            ) {
              setPermisoReporte(true);
            }
          }
        }
      }
    );

    let listo = false;

    return () => {
      listo = true;
    };

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    let listo = false;

    let granTotalComision = 0.0;
    let granTotaltotal = 0.0;

    for (const serie of finalData) {
      granTotaltotal = granTotaltotal + parseFloat(serie.comisionImporte);
    }
    setGranTotalTotalMostrar(granTotaltotal);
    setGranTotalComisionMostrar(granTotalComision);
    return () => {
      listo = true;
    };
  }, [finalData]);

  React.useEffect(() => {
    let ready = false;

    getAllEmpleadosParaSelect(user).then((resultado) => {
      if (resultado.status == 200) {
        setListadoEmpleados(resultado.data);
      } else {
        setListadoEmpleados([]);
      }
    });

    return () => {
      ready = true;
    };
  }, []);

  return (
    <>
      {permisoParaVisualizar == true && permisoReporte == true ? (
        <>
          <div className="container-fluid mb-3 mt-2">
            <div className="row row-cols-md-3 d-flex align-items-center">
              <div className="col mb-2">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Inicio"
                  type="date"
                  variant="standard"
                  value={reporteFechaInicial}
                  onChange={(e) => {
                    setReporteFechaInicial(e.target.value);
                  }}
                />
              </div>
              <div className="col mb-2">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Fin"
                  type="date"
                  variant="standard"
                  value={reporteFechaFin}
                  onChange={(e) => {
                    setReporteFechaFin(e.target.value);
                  }}
                />
              </div>

              <div className="col mb-2">
                <button
                  className="btn btn-sm btn-success w-100"
                  onClick={() => {
                    if (reporteFechaInicial == "" || reporteFechaFin == "") {
                      toast.error("Debes seleccionar fecha inicio y fecha fin");
                    } else {
                      setLoading(true);
                      reporteNomina(user, {
                        fechaInicial: reporteFechaInicial,
                        fechaFin: reporteFechaFin,
                      }).then((resultado) => {
                        if (resultado.status == 200) {
                          setFinalData(resultado.data.empleados);
                        }
                        setLoading(false);
                      });
                    }
                  }}
                >
                  {" "}
                  Buscar{" "}
                </button>
              </div>
            </div>
          </div>
          <div
            className="btn btn-sm btn-success my-3"
            onClick={() => {
              window.print();
            }}
          >
            Imprimir
          </div>

          <div className="container-fluid">
            <div className="row row-cols-1">
              {finalData.map((empleado, index) => (
                <div key={empleado.id} className="col mb-2">
                  <div className="card">
                    <div className="card-body">
                      <strong>
                        {empleado.id} {empleado.nombreEmpleado}
                      </strong>
                      <br />

                      <br />
                      <strong>
                        Salario a pagar: {"$ "}
                        {parseFloat(empleado.salario)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </strong>
                      <br />
                      <strong>
                        Comisiones: {"$ "}
                        {(
                          parseFloat(empleado.totalPagar) -
                          parseFloat(empleado.salario)
                        )
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </strong>
                      <br />
                      <strong>
                        Salario a pagar: {"$ "}
                        {empleado.totalPagar
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </strong>
                      <hr />
                      <div className="container-fluid">
                        <div className="row row-cols-md-2">
                          <div className="col">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Folio comisión órdenes</th>
                                  <th scope="col">Monto</th>
                                  <th scope="col">Observaciones</th>
                                </tr>
                              </thead>
                              <tbody>
                                {empleado.comisionesOrdenes.map(
                                  (comisionordenes, index) => (
                                    <tr key={index}>
                                      <th scope="row">
                                        {" "}
                                        {comisionordenes.folio}
                                      </th>
                                      <td>
                                        {"$ "}
                                        {parseFloat(
                                          comisionordenes.montoComision
                                        )
                                          .toFixed(2)
                                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                      </td>
                                      <td> {comisionordenes.observaciones}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="col">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Folio comisión contratos</th>
                                  <th scope="col">Monto</th>
                                </tr>
                              </thead>
                              <tbody>
                                {empleado.comisionesPrevision.map(
                                  (comisionordenes, index) => (
                                    <tr key={index}>
                                      <th scope="row">
                                        {" "}
                                        {comisionordenes.noContrato}
                                      </th>
                                      <td>
                                        {"$ "}
                                        {parseFloat(
                                          comisionordenes.comisionImporte
                                        )
                                          .toFixed(2)
                                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {loading == true ? <LoadingComponent /> : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
