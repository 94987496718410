import React from "react";
import {
  Document,
  View,
  Page,
  Text,
  PDFDownloadLink,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import logoVitanova from "../../Images/LogotipoNegro.png";
import { reporteConcentradoComisionesPorFechaAPI } from "../../API/ApiReportes";
import { AuthContext } from "./../../auth/authContext";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: "30%",
    height: "auto",
    textAlign: "center",
    alignSelf: "center",
  },
  titulo: {
    fontSize: 12,
    marginTop: 30,
    textAlign: "center",
  },
  tituloNombre: {
    fontSize: 12,
    marginTop: 10,
    textAlign: "center",
  },
  firmaConformidad: {
    fontSize: 10,
    marginTop: 200,
    textAlign: "center",
  },
  textoFirmaConformidad: {
    fontSize: 10,
    textAlign: "center",
  },
});

export const ImprimirPdfComponentPrevisionGeneral = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const resultado = props.data.reduce((acumulador, registro) => {
    const vendedor = registro.vendedor;
    if (acumulador[vendedor]) {
      acumulador[vendedor] += parseFloat(registro.porPagar);
    } else {
      acumulador[vendedor] = parseFloat(registro.porPagar);
    }
    return acumulador;
  }, {});

  

  const generatePDF = () => {
    return (
      <>
        <Document>
          {props.data.map((recibo) => {
            return (
              <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                  <Image style={styles.image} src={logoVitanova} />
                  <Text style={styles.titulo}>
                    Recibo de comisiones previsión del día: {props.fechaInicio}{" "}
                    al {props.fechaFin}
                  </Text>
                  <Text style={styles.tituloNombre}>
                    Nombre: {recibo.vendedor}
                  </Text>

                  <Text style={styles.tituloNombre}>
                    Total de Comisiones: $
                    {parseFloat(recibo.porPagar)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </Text>

                  <Text style={styles.firmaConformidad}>
                    ________________________________________________________________________________
                  </Text>

                  <Text style={styles.textoFirmaConformidad}>
                    Recibí la cantidad de: ${" "}
                    {parseFloat(recibo.porPagar)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                    por concepto de:
                  </Text>
                  <Text style={styles.textoFirmaConformidad}>
                    comisiones de órdenes de servicio
                  </Text>
                  <Text style={styles.textoFirmaConformidad}>
                    del día: {props.fechaInicio} al {props.fechaFin}
                  </Text>
                </View>
              </Page>
            );
          })}
        </Document>
      </>
    );
  };

  return (
    <div>
      <PDFDownloadLink
        document={generatePDF()}
        fileName={`Comisiones__${props.fechaInicio}_${props.fechaFin}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Cargando documento..."
          ) : (
            <div className="btn btn-sm btn-danger mt-3">
              Descargar Consolidados
            </div>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};
