import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import {
  agregarServicioAOrdenDeServicio,
  getServiciosOrdenesServicio,
} from "../../API/ApiServiciosOrdenesServicio";
import { nuevoPagoOrdenServicio } from "../../API/ApiPagosOrdenes";
import { agregarJardinAPanteon } from "../../API/ApiJardines";
import { AddPanteon } from "../../API/ApiPanteones";
import { AgregarUniplexByIdJardin } from "../../API/ApiUniplex";
import { LoadingComponent } from "../LoadingComponent/LoadingComponent";
import { AgregarEspaciosACapillaByIdCapilla } from "../../API/ApiCapillas";

const validationSchema = yup.object({
  idcapilla: yup.string("Id capilla").required("Id Capilla Requerido"),
  posicionInicial: yup
    .string("Posición Inicial")
    .required("Posición Inicial Requerido"),
  posicionFinal: yup
    .string("Posición Final")
    .required("Posición Final Requerida"),
  numeroEspacios: yup
    .string("Níumero de espacios")
    .required("Número espacios Requerida"),
});

export const AddEspaciosACapillaComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      idcapilla: props.capilla.id,
      posicionInicial: "",
      posicionFinal: "",
      numeroEspacios: "4",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      
      AgregarEspaciosACapillaByIdCapilla(user, values).then((resultado) => {
        
        if (resultado.status == 200) {
          props.setCrudAction({ action: null, data: null });
          toast.success("Espacios agregados correctamente");
        } else {
          toast.error("Ha habido un error, consulte la consola");
        }

        setIsDisabled(false);
      });
    },
  });

  return (
    <>
      <div className="crudDivComponent col-md-3 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Agregar Espacios a {props.capilla.nombre} </h6>
                  <small className="text-muted">
                    Ingresa los datos para agregar Espacios
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>

                  <LoadingComponent />
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row row-cols-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="posicionInicial"
                    name="posicionInicial"
                    label="Posición Inicial"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.posicionInicial}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.posicionInicial &&
                      Boolean(formik.errors.posicionInicial)
                    }
                    helperText={
                      formik.touched.posicionInicial &&
                      formik.errors.posicionInicial
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="posicionFinal"
                    name="posicionFinal"
                    label="Posición Final"
                    type={"tnumberext"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.posicionFinal}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.posicionFinal &&
                      Boolean(formik.errors.posicionFinal)
                    }
                    helperText={
                      formik.touched.posicionFinal &&
                      formik.errors.posicionFinal
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="numeroEspacios"
                    name="numeroEspacios"
                    label="Espacios"
                    type={"tnumberext"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.numeroEspacios}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.numeroEspacios &&
                      Boolean(formik.errors.numeroEspacios)
                    }
                    helperText={
                      formik.touched.numeroEspacios &&
                      formik.errors.numeroEspacios
                    }
                    disabled={true}
                  />
                </div>

                <div className="col mt-3">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success w-100"
                  >
                    Agregar Espacios
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
