import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { AuthContext } from "../../auth/authContext";
import { desactivarUser } from "../../API/ApiUsers";
import {
  getAlertasCatalogo,
  getAlertasDeUnUsuario,
  putActivarDesactivarAlerta,
} from "../../API/ApiAlertas";
import DataTable from "react-data-table-component";
import Switch from "@mui/material/Switch";

const ConfiguracionAlertasContratoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [finalDataCatalogoAlertas, setFinalDataCatalogoAlertas] =
    React.useState([]);
  const [finalDataAlertas, setFinalDataAlertas] = React.useState([]);
  const [contador, setContador] = React.useState(0);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  React.useEffect(() => {
    getAlertasDeUnUsuario(user, props.crudAction.data.id).then((resultado) => {
      if (resultado.status == 200) {
        console.log(resultado.data);
        setFinalDataAlertas(resultado.data);
      } else {
        setFinalDataAlertas([]);
      }
    });
  }, [props.crudAction, contador]);

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.alerta.nombre,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => (row.activo == true ? <>Activa</> : <>Inactiva</>),
      sortable: true,
    },
    {
      name: "Acciones",
      selector: (row) => {
        return (
          <>
            <Switch
              checked={row.activo}
              onChange={(e) => {
                putActivarDesactivarAlerta(user, row.id).then((resultado) => {
                  if (resultado.status == 200) {
                    toast.success("Movimiento realizado con éxito");
                    setContador(contador + 1);
                  } else {
                    toast.error(
                      "No fue posible ralizar la actualización de la alerta"
                    );
                    setContador(contador + 1);
                  }
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </>
        );
      },
      sortable: true,
    },
  ];

  return (
    <div className="crudDivComponent col-md-6 px-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6>{props.nombre}</h6>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-4" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <DataTable
              columns={columns}
              data={finalDataAlertas}
              fixedHeader={true}
              fixedHeaderScrollHeight={"100vh"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfiguracionAlertasContratoComponent;
