import React from "react";
import { AuthContext } from "../../auth/authContext";
import jwt_decode from "jwt-decode";
import { obtenerReporteZ } from "../../API/ApiPagosRegistrados";
import imagenVitaNova from "../../Images/LogotipoNegro.png";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getUsers } from "../../API/ApiUsers";
import Skeleton from "@mui/material/Skeleton";
import { DateTime } from "luxon";
import { obtenerReportePorExhumar } from "../../API/ApiReportes";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import { ButtonDownloadToXlsComponent } from "../../Components/ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";

const guidVista = "ef433e95-e3b0-4caa-a533-3b599add112e";

const ReporteExhumacionesScreenComponent = () => {
  const todayInicio = new Date();
  const year = todayInicio.getFullYear();

  const defaultValueInicio = new Date(year, 0, 1).toISOString().split("T")[0]; // yyyy-mm-dd
  const defaultValueFin = new Date(year, 11, 31).toISOString().split("T")[0]; // yyyy-mm-dd
  const { user, dispatch } = React.useContext(AuthContext);
  var decoded = jwt_decode(user.accessToken);

  const [isloading, setIsLoading] = React.useState(true);
  const [fechaInicio, setFechaInicio] = React.useState(defaultValueInicio);
  const [fechaFin, setFechaFin] = React.useState(defaultValueFin);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = React.useState(
    decoded.id
  );

  const [usuarioSeleccionadoObject, setUsuarioSeleccionadoObject] =
    React.useState({});

  const [finalDataUsuarios, setFinalDataUsuarios] = React.useState([]);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const [finalData, setFinalData] = React.useState({
    arregloUnificado: [],
    pagosOrdenes: [],
    pagoPrevision: [],
    totalPagosHoyOrdenes: 0,
    totalPagosHoyPevision: 0,
  });
  const [finalDataToExcel, setFinalDataToExcel] = React.useState([]);

  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoReporte, setPermisoReporte] = React.useState(false);

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "8acd3d10-78fc-46c8-b341-318f1cfe333f" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
              setPermisoReporte(true);
            }
          }
        }
      }
    );
  }, []);

  return (
    <React.Fragment>
      {permisoParaVisualizar == true && permisoReporte == true ? (
        <React.Fragment>
          <div className="container-fluid mb-2 d-none d-print-block">
            <div className="row">
              <div className="col-12">
                <img src={imagenVitaNova} alt="Logo Vita Nova" height={30} />
              </div>
            </div>
          </div>
          <div className="container-fluid mb-3 mt-2">
            <div className="row row-cols-md-3 d-flex align-items-center">
              <div className="col">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Inicio"
                  type="date"
                  variant="standard"
                  value={fechaInicio}
                  onChange={(e) => {
                    setFechaInicio(e.target.value);
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Fin"
                  type="date"
                  variant="standard"
                  value={fechaFin}
                  onChange={(e) => {
                    setFechaFin(e.target.value);
                  }}
                />
              </div>
              <div className="col">
                <div
                  className="btn btn-sm btn-success w-100"
                  onClick={() => {
                    setIsLoading(true);
                    obtenerReportePorExhumar(user, fechaInicio, fechaFin).then(
                      (resultado) => {
                        if (resultado.status === 200) {
                          let finalDataToExcel = [];

                          for (const finado of resultado.data) {
                            var armandoDato = {
                              contrato:
                                finado.detalleContrato.serie +
                                finado.detalleContrato.noContrato,
                              titular: finado.detalleContrato.titular,
                              espacio: finado.detalleUbicacion.nombre,
                              ubicacion:
                                finado.detalleTipoUbicacion.espacio +
                                " " +
                                finado.detalleEspacio.letra +
                                finado.detalleEspacio.consecutivo,
                              finado: finado.detalleFinado.persona,
                              fechaInhumacion: CambiarFormatoFechaHelper(
                                finado.detalleFinado.fechaInhumacion
                              ),
                              fechaExhumacion: CambiarFormatoFechaHelper(
                                finado.detalleFinado.fechaExhumacion
                              ),
                            };

                            finalDataToExcel.push(armandoDato);
                          }

                          setFinalDataToExcel(finalDataToExcel);

                          setFinalData(resultado.data);
                          console.log(resultado.data);
                          setIsLoading(false);
                        }
                      }
                    );
                  }}
                >
                  Generar Reporte
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <ButtonDownloadToXlsComponent
                  data={finalDataToExcel}
                  fileName={`Exhumaciones ${fechaInicio} al ${fechaFin}`}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <SinPermisoComponent />
      )}

      {isloading === true ? (
        <>
          <div className="container-fluid mb-3 mt-3">
            <div className="row">
              <div className="col-sm-12">
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: "20%" }} />
                </Skeleton>
                <br />{" "}
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: "20%" }} />
                </Skeleton>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <table className="table align-top">
                  <thead>
                    <tr>
                      <th scope="col">Número</th>
                      <th scope="col">Contrato</th>
                      <th scope="col">Espacio</th>
                      <th scope="col">Finado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalData.map((finado, index) => (
                      <React.Fragment key={finado.idpago}>
                        <tr className=" align-items-center">
                          <th>{index + 1}</th>
                          <td>
                            {finado.detalleContrato.serie}-
                            {finado.detalleContrato.noContrato}
                            <br />
                            {finado.detalleContrato.titular}
                          </td>
                          <td>
                            {finado.detalleUbicacion.nombre}
                            <br />
                            {finado.detalleTipoUbicacion.espacio}{" "}
                            {finado.detalleEspacio.letra} -{" "}
                            {finado.detalleEspacio.consecutivo}
                          </td>
                          <td>
                            <strong> {finado.detalleFinado.persona}</strong>
                            <br />
                            Inhumación:{" "}
                            {CambiarFormatoFechaHelper(
                              finado.detalleFinado.fechaInhumacion
                            )}{" "}
                            <br />
                            Exhumación:{" "}
                            {CambiarFormatoFechaHelper(
                              finado.detalleFinado.fechaExhumacion
                            )}{" "}
                          </td>
                        </tr>{" "}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ReporteExhumacionesScreenComponent;
