import React from "react";
import { updateEtapaUno } from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";
import { toast } from "react-toastify";

export const CancelarContratoComponent = (props) => {
  const [valorCancelado, setValorCancelado] = React.useState("2");

  const [contratoCambiar, setContratoCambiar] = React.useState(props.contrato);

  const { user, dispatch } = React.useContext(AuthContext);

  const [finalData, setFinalData] = React.useState(props.finalData.etapaUno[0]);


  return (
    <>
      {finalData.status == 2 ? null : (
        <>
          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-12 text-end">
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    var antesModificar = finalData;

                    antesModificar.status = "2";

                    updateEtapaUno(
                      user,
                      antesModificar,
                      props.finalData.etapaUno[0].id
                    ).then((resultado) => {
                      if (resultado.status == 200) {
                        toast.warning("Contrato Cancelado");
                        window.location.reload();
                      }
                    });
                  }}
                >
                  Cancelar Contrato
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
