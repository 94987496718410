import React from "react";
import { createBrowserHistory } from "history";

import AddIcon from "@mui/icons-material/Add";
import { AddUserComponent } from "../../Components/UsersCrudComponents/AddUserComponent/AddUserComponent";
import { EditUserComponent } from "../../Components/UsersCrudComponents/EditUserComponent/EditUserComponent";
import { DeleteUserComponent } from "../../Components/UsersCrudComponents/DeleteUserComponent/DeleteUserComponent";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { PermisosUsuarioComponent } from "../../Components/PermisosUsuarioComponent/PermisosUsuarioComponent";

import { SinPermisoComponent } from "./../../Components/SinPermisoComponent/SinPermisoComponent";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import ConfiguracionAlertasContratoComponent from "../../Components/ConfiguracionAlertasContratoComponent/ConfiguracionAlertasContratoComponent";
import { AuthContext } from "../../auth/authContext";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { getUsers } from "../../API/ApiUsers";

const guidVista = "678e34bf-f746-45e7-acb8-61d5893faf85";

export const UsersScreenComponent = (props) => {
  const customHistory = createBrowserHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const [isloadingData, setIsLoadingData] = React.useState(true);

  const [finalData, setFinalData] = React.useState([]);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [permisoVisualizar, setPermisoVisualizar] = React.useState(false);
  const [permisoAgregar, setPermisoAgregar] = React.useState(false);
  const [permisoNuevaContrasena, setPermisoNuevaContrasena] =
    React.useState(false);
  const [permisoPermisos, setPermisoPermisos] = React.useState(false);
  const [permisoDesactivarUsuario, setPermisoDesactivarUsuario] =
    React.useState(false);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "78769e14-3486-440f-a6e1-7a2dd44e9424" &&
              permiso.allowed == 1
            ) {
              setPermisoVisualizar(true);
            }
            if (
              permiso.guidFuncion == "2806418a-870e-45d2-94a7-d48b0506d7d6" &&
              permiso.allowed == 1
            ) {
              setPermisoNuevaContrasena(true);
            }
            if (
              permiso.guidFuncion == "2bd32871-f7c7-407b-94e3-5be7c23b3f1a" &&
              permiso.allowed == 1
            ) {
              setPermisoPermisos(true);
            }
            if (
              permiso.guidFuncion == "32bd27b9-0e22-41ea-b58f-ab921664b35c" &&
              permiso.allowed == 1
            ) {
              setPermisoDesactivarUsuario(true);
            }
            if (
              permiso.guidFuncion == "5d49c4a8-e49f-4913-ad40-c05894fc730a" &&
              permiso.allowed == 1
            ) {
              setPermisoAgregar(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    let ready = false;

    getUsers(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });

    return () => {
      ready = true;
    };
  }, [crudAction]);

  return (
    <>
      {permisoVisualizar == true ? (
        <>
          {permisoAgregar == true ? (
            <div className="container-fluid text-end">
              <div className="row">
                <div className="col-12">
                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => {
                      setCrudAction({
                        action: "add",
                        data: null,
                      });
                    }}
                  >
                    <AddIcon />
                    Agregar Usuario
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          <div className="container-fluid mt-3">
            <div className="row row-cols-md-4">
              {finalData.map((usuario, index) => (
                <div key={index} className="col mb-2">
                  <div className="card">
                    <div className="card-header justify-content-md-end">
                      {permisoNuevaContrasena == true ? (
                        <button
                          className="btn btn-sm btn-warning"
                          onClick={() => {
                            setCrudAction({
                              action: "edit",
                              data: usuario,
                            });
                          }}
                        >
                          <ModeEditIcon fontSize="inherit" /> Contraseña
                        </button>
                      ) : null}

                      {permisoPermisos == true ? (
                        <button
                          className="btn btn-sm btn-info"
                          onClick={() => {
                            setCrudAction({
                              action: "permisos",
                              data: usuario,
                            });
                          }}
                        >
                          Permisos
                        </button>
                      ) : null}

                      {permisoDesactivarUsuario == true ? (
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            setCrudAction({
                              action: "delete",
                              data: usuario,
                            });
                          }}
                        >
                          Desactivar
                        </button>
                      ) : null}
                    </div>
                    <div className="card-body">
                      <h6 className="m-0 p-0">{usuario.nombre}</h6>
                      {usuario.email}
                    </div>
                    <div className="card-footer">
                      {permisoDesactivarUsuario == true ? (
                        <button
                          className="btn btn-sm btn-info"
                          style={{
                            background: "orange",
                            borderColor: "orange",
                          }}
                          onClick={() => {
                            setCrudAction({
                              action: "alertas",
                              data: usuario,
                            });
                          }}
                        >
                          <AddAlertIcon fontSize="inherit" /> Alertas
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {crudAction.action === "add" ? (
            <>
              <AddUserComponent
                crudAction={crudAction}
                setCrudAction={setCrudAction}
                nombre="Usuario de sistema"
              />
            </>
          ) : null}
          {crudAction.action === "edit" ? (
            <>
              <EditUserComponent
                crudAction={crudAction}
                setCrudAction={setCrudAction}
                nombre="Usuario de sistema"
              />
            </>
          ) : null}
          {crudAction.action === "delete" ? (
            <>
              <DeleteUserComponent
                crudAction={crudAction}
                setCrudAction={setCrudAction}
                nombre="Usuario de sistema"
              />
            </>
          ) : null}
          {crudAction.action === "permisos" ? (
            <>
              <PermisosUsuarioComponent
                crudAction={crudAction}
                setCrudAction={setCrudAction}
                nombre="Permisos de usuario"
              />
            </>
          ) : null}
          {crudAction.action === "alertas" ? (
            <>
              <ConfiguracionAlertasContratoComponent
                crudAction={crudAction}
                setCrudAction={setCrudAction}
                nombre="Configuración de alertas"
              />
            </>
          ) : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
